import React, { Component } from 'react';

import Table from "../../Table";
import { Panel } from "react-bootstrap";
import '../../../../assets/css/apiData.css';
import 'react-select/dist/react-select.css';
import Spinner from '../../../Spinner';
import { DASHBOARD_PAYOUTS_PER_MIDS } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardPayoutsPerMidComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payouts: undefined,
      loading: true,
      socket: false,

      columns: [{
        value: "merchantName",
        label: "Merchant",
        leftAligned: true,
        isNumber: false
      }, {
        value: "channelId",
        label: "PID",
        leftAligned: true,
        isNumber: true
      }, {
        value: "midId",
        label: "MID ID",
        leftAligned: true,
        isNumber: true
      }, {
        value: "midName",
        label: "MID",
        leftAligned: true,
        isNumber: false
      }, {
        value: "attempts",
        label: "Attempts",
        rightAligned: true,
        isNumber: true
      }, {
        value: "pending",
        label: "Pending",
        rightAligned: true,
        isNumber: true
      }, {
        value: "rejected",
        label: "Rejected",
        rightAligned: true,
        isNumber: true
      }, {
        value: "failed",
        label: "Failed",
        rightAligned: true,
        isNumber: true
      }, {
        value: "successful",
        label: "Successful",
        rightAligned: true,
        isNumber: true
      },{
        value: "successRate",
        label: "Success Rate",
        rightAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PAYOUTS_PER_MIDS && this.props.thisMonth && state.payoutsPerMids.socket) {
        this.setState({ payouts: state.payoutsPerMids, loading: false, socket: state.payoutsPerMids.socket });
        return;
      }

      if (state.update === DASHBOARD_PAYOUTS_PER_MIDS && !state.payoutsPerMids.socket) {
        this.setState({ payouts: state.payoutsPerMids, loading: false, socket: state.payoutsPerMids.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { payouts } = this.state;

    if (!payouts) {
      return [];
    }

    return payouts.map(elem => {
      return {
        merchantName: elem.merchantName,
        channelId: elem.channelId,
        midId: elem.midId,
        midName: elem.midName,
        attempts: elem.attempts,
        pending: elem.pending,
        rejected: elem.rejected,
        failed: elem.failed,
        successful: elem.successful,
        successRate: elem.successRate + "%"
      };
    });
  };

  render() {
    const { payouts, loading, columns } = this.state;

    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYOUTS PER MID </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && payouts) {
      sellsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYOUTS PERFORMANCE PER MID </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content payout-requests">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }

}

export default DashBoardPayoutsPerMidComponent;