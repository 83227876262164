import React, { Component } from 'react';

import { Modal, Button } from "react-bootstrap";
import { Column } from 'simple-flexbox';
import loadingIcon from '../../../assets/images/loading.png';

class PromptModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  };

  render() {
    const { showModal, handleConfirm, handleClose, isLoading, title, message } = this.props;

    return (
      <Modal
        show={ showModal }
        onHide={ handleClose }
        backdrop="static"
        keyboard={ false }
        centered="true"
        className="prompt"
      >
        <Modal.Header closeButton>
          <Modal.Title> { title } </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Column flexGrow={ 1 } style={ { padding: "25px", overflow: "auto" } }>
            <h4>{ message }</h4>
          </Column>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" className="btn-success" onClick={ handleClose }>
            No
          </Button>
          <Button
            variant="secondary"
            className="btn-secondary"
            disabled={ isLoading }
            onClick={ handleConfirm }
          >
            { isLoading ? (
              <img src={ loadingIcon } alt="loading..." style={ { width: "16px" } } />
            ) : 'Yes'
            }
          </Button>
          
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PromptModal;