import { requestUrl } from "./backendUrl";
import { frontEndUrl } from '../config';

export const crudService = {
  get,
  post,
  put,
  patch,
  remove
};

function buildUrl(endpoint) {
  return frontEndUrl + "/" + requestUrl.backendUrl + endpoint;
}

function get(endpoint, customHeaders) {
  const url = buildUrl(endpoint);
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch {
    user = null;
  }

  const headers = Object.assign({
    'Content-Type': 'application/json'
  }, customHeaders);

  if (user) {
    headers['Authorization'] = user.tokenType + ' ' + user.accessToken;
  }

  const requestOptions = {
    method: 'GET',
    headers: headers
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function post(endpoint, jsonConfig, customHeaders, isFile) {
  const url = buildUrl(endpoint);
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch {
    user = null;
  }

  const headers = Object.assign({}, customHeaders);

  if (user) {
    headers['Authorization'] = user.tokenType + ' ' + user.accessToken;
  }

  const requestOptions = {
    method: "POST",
    body: jsonConfig,
    headers: headers
  };

  if (!isFile) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(jsonConfig);
  }

  return fetch(url, requestOptions).then(handleResponse);

}

function put(endpoint, jsonConfig, customHeaders, isFile) {
  const url = buildUrl(endpoint);
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch {
    user = null;
  }

  const headers = Object.assign({
    'Content-Type': 'application/json'
  }, customHeaders);

  if (user) {
    headers['Authorization'] = user.tokenType + ' ' + user.accessToken;
  }

  const requestOptions = {
    method: "PUT",
    body: jsonConfig,
    headers: headers
  };

  if (!isFile) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(jsonConfig);
  }

  return fetch(url, requestOptions).then(handleResponse);

}

function patch(endpoint, jsonConfig, customHeaders) {
  const url = buildUrl(endpoint);
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch {
    user = null;
  }

  const headers = Object.assign({
    'Content-Type': 'application/json'
  }, customHeaders);

  if (user) {
    headers['Authorization'] = user.tokenType + ' ' + user.accessToken;
  }

  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(jsonConfig),
    headers: headers
  };

  return fetch(url, requestOptions).then(handleResponse);

}

function remove(endpoint, jsonConfig, customHeaders) {
  const url = buildUrl(endpoint);
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch {
    user = null;
  }

  const headers = Object.assign({
    'Content-Type': 'application/json'
  }, customHeaders);

  if (user) {
    headers['Authorization'] = user.tokenType + ' ' + user.accessToken;
  }

  const requestOptions = {
    method: "DELETE",
    body: JSON.stringify(jsonConfig),
    headers: headers
  };

  return fetch(url, requestOptions).then(handleResponse);

}

function handleResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('user');
      window.location.href = "/login";
    }
    return Promise.reject(response);
  }

  const responseCloned = response.clone();
  return response.json().catch(
    () => {
      return responseCloned;
    }
  );
}
