import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { Button } from 'react-bootstrap';

class Table extends Component {
  state = {
    sortBy: "",
    sortDirection: "ASC"
  };

  componentDidMount() {
    const { defaultSortBy } = this.props;
    if (defaultSortBy) {
      this.setState({
        sortBy: defaultSortBy
      });
    }
  };

  onSortClick = (field) => {
    let { sortDirection } = this.state;

    const sortBy = field;
    if (sortDirection === "DESC") {
      sortDirection = "ASC";
    } else {
      sortDirection = "DESC";
    }

    this.setState({
      sortBy,
      sortDirection
    });
  };

  sortedData = () => {
    const { data, columns } = this.props;
    const { sortBy, sortDirection } = this.state;
    if (!sortBy) {
      return data;
    }

    const filteredData = data.filter(elem => !elem.isTotal);
    const sortedByColumn = columns.find(column => column.value === sortBy);

    const getNumber = (element) => {
      const numberPattern = /\d+(\.\d+)?$/g;
      if (typeof element === "number") {
        return element;
      }
      const foundNumbers = element.replace(/[^0-9.]/g, "").match(numberPattern);
      return foundNumbers && foundNumbers.length ? parseFloat(foundNumbers[0]) : '';
    };

    filteredData.sort((elemA, elemB) => {
      const propertyA = sortedByColumn.isNumber ? getNumber(elemA[sortBy]) : elemA[sortBy];
      const propertyB = sortedByColumn.isNumber ? getNumber(elemB[sortBy]) : elemB[sortBy];

      if (typeof propertyA === "string" && typeof propertyB === "string") {
        return sortDirection === "ASC" ? propertyA.localeCompare(propertyB) : propertyB.localeCompare(propertyA);
      }

      if (propertyA > propertyB) {
        return sortDirection === "ASC" ? 1 : -1;
      } else if (propertyA < propertyB) {
        return sortDirection === "ASC" ? -1 : 1;
      }

      return 0;
    });

    return filteredData.concat(data.filter(elem => elem.isTotal));
  };

  render() {
    const { sortBy, sortDirection } = this.state;
    const {
      columns,
      data,
      editableField,
      editableFieldValue,
      editableMidId,
      onKeyDown,
      isStriped,
      headerColor,
      onDoubleClick,
      onEditableInputChange,
      useArrow
    } = this.props;

    if (!columns || !data) {
      return <Row><span/></Row>;
    }

    return (
      <div className="table-component">
        <table className={ `table ${isStriped ? "table-striped" : ""}` }>
          <thead>
            <tr style={ headerColor ? { backgroundColor: headerColor } : null }>
              {
                columns.map(column => {
                  return <th
                    key={ column.value + column.label }
                    style={ { pointerEvents: column.isAction ? "none" : "",
                      textAlign: column.rightAligned ? 'right' : column.centerAligned ? 'center' : '' } }
                    className={ sortBy === column.value && !useArrow ? 'header-active' : '' }
                    onClick={ () => this.onSortClick(column.value) }>
                    <span className="text-span">
                      { column.label }
                    </span>
                    { useArrow && sortBy === column.value && <span className="text-span" style={ { margin: "5px 0 0 5px" } }>
                      <svg width="16" height="16" viewBox="0 0 123.959 123.958" style={ { transform: sortDirection === 'DESC' ? "rotate(180deg)" : "" } }>
                        <g fill="#FFFFFF">
                          <path d="M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56
                            C125.979,34.417,123.279,28.017,117.979,28.017z"/>
                        </g>
                      </svg>
                    </span> }
                  </th>;
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              this.sortedData().map((dataElem, index) => {
                return <tr key={ index }>
                  {
                    columns.map(column => {
                      return (
                        <td
                          key={ column.value + index + column.label }
                          style={ {textAlign: column.rightAligned ? 'right' : column.centerAligned ? 'center' : ''} }
                          onDoubleClick={ () => onDoubleClick(dataElem, column) }
                        >
                          {
                            column.isAction ? (
                              <NavLink to={ `${column.route}/${dataElem[column.value]}` } className={ column.className }>
                                { column.label }
                              </NavLink>
                            ) : column.isButton ? (
                              <Button
                                disabled={ column.isButtonDisabled(dataElem) }
                                className={ column.className }
                                onClick={ () => column.triggerEvent(dataElem) }
                              >
                                { column.buttonLabel }
                              </Button>
                            ) : column.isEditable && editableMidId === dataElem.id && editableField === column.value ? (
                              <input
                                type="number"
                                value={ editableFieldValue }
                                className="editable-input"
                                onKeyDown={ (event) => onKeyDown(event) }
                                onChange={ (e) => onEditableInputChange(e.target.value) }
                              />
                            ) : dataElem[column.value]
                          }
                        </td>
                      );
                    })
                  }
                </tr>;
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
