import React, { Component } from 'react';
import Select from "react-select";
import ReactTooltip from 'react-tooltip';

class CustomSelect extends Component {
  state = {};

  sortItems = (items) => {
    items.sort((elemA, elemB) => !elemA.prevent_sorting && !elemB.prevent_sorting && elemA.label.localeCompare(elemB.label));
    return items;
  };

  getSelectedItem = () => {
    const { value, options, withId } = this.props;
    if (!value) {
      return "";
    }

    const option = options.find(elem => elem.value.toString() === value.toString());
    if (!option) {
      return "";
    }

    return withId ? `${option.label} (${option.value})` : option.label;
  };

  render() {
    const { value, required, clearable, onChange, options, className, disabled, placeholder, showTooltip, enableSort } = this.props;

    return (
      <div>
        { showTooltip && <ReactTooltip effect='solid' type={ 'light' } place={ 'top' } border={ true } getContent={ (dataTip) => <h5>{dataTip}</h5> }/> }
        <div data-tip={ showTooltip ? (this.getSelectedItem() || "No item selected.") : null }>
          <Select
            className={ className }
            value={ value }
            disabled={ disabled }
            required={ required }
            placeholder={ placeholder || "Select..." }
            clearable={ clearable }
            onChange={ (event) => onChange(event) }
            options={ enableSort || enableSort === true ? this.sortItems(options) : options }
          />
        </div>
      </div>
    );
  }
}

export default CustomSelect;
