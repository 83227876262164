import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import '../../../assets/css/login.css';
import SweetAlert from 'sweetalert2-react';
import ReCaptchaValidation from "./ReCaptchaValidation";
import appStoreIcon from '../../../assets/images/app-store.png';
import playMarketIcon from '../../../assets/images/play-market.png';

import { loginActions } from '../../../services/loginActions';

class Login extends Component {
    state = {
      controls: {
        username: {
          value: '',
          validation: {
            required: true,
            message: 'Username is required.'
          },
          valid: false,
          touched: false
        },
        password: {
          value: '',
          validation: {
            required: true,
            message: 'Password is required.'
          },
          valid: false,
          touched: false
        },
        code: {
          value: '',
          validation: {
            required: true,
            message: 'Code is required.'
          },
          valid: false,
          touched: false
        }
      },
      step: 1,
      qrCode: null,
      hash: '',
      secret: '',
      showError: false,
      errorMessage: "",
      captchaPassed: false
    }

    checkValidity(value, rules) {
      let isValid = true;

      if (rules.required) {
        isValid = value.trim() !== '' && isValid;
      }

      return isValid;
    }

    inputChangedHandler = (event, inputID) => {
      const updatedControls = {
        ...this.state.controls
      };
      const updatedElement = {
        ...updatedControls[inputID]
      };
      if (inputID === 'code') {
        if (event.target.value.length <= 6) {
          updatedElement.value = event.target.value;
        }
      } else {
        updatedElement.value = event.target.value;
      }
      updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation);
      updatedElement.touched = true;
      updatedControls[inputID] = updatedElement;
      this.setState({ controls: updatedControls }, () => {
        if (inputID === "code" && updatedElement.value.length === 6) {
          this.loginHandler();
        }
      });
    }

    onConfirmError = () => {
      this.setState({
        showError: false,
        errorMessage: ""
      });
    }

    loginHandler = async (event) => {
      event && event.preventDefault();
      const { controls, step } = this.state;
      if (step === 1) {
        const formData = {
          username: controls.username.value,
          password: controls.password.value
        };

        if (!this.state.captchaPassed) {
          this.setState({
            showError: true,
            errorMessage: "Complete ReCAPTCHA to login."
          });

          return;
        }

        if (formData.username && formData.password) {
          loginActions.login(formData.username, formData.password)
            .then((response) => {
              if (response && response.accessToken) {
                window.location.href = '/dashboard';
              } else {
                this.setState({
                  step: 2,
                  qrCode: response.qrCode || null,
                  hash: response.hash || '',
                  secret: response.secret || '',
                });
              }
            }
            ).catch(
              err => {
                this.setState({
                  showError: true,
                  errorMessage: err.message
                });
              }
            );
        }
      } else if (step === 2) {
        const { secret, hash } = this.state;
        const formData = {
          secret: secret,
          hash: hash,
          code: controls.code.value,
        };

        if (formData.code) {
          loginActions.totpLogin(formData.secret, formData.hash, formData.code)
            .then((response) => {
              if (response && response.accessToken) {
                window.location.href = '/dashboard';
              }
            }
            ).catch(
              err => {
                this.setState({
                  showError: true,
                  errorMessage: err.message
                });
              }
            );
        }
      }
    }

    onRecaptchaChange = () => {
      this.setState({
        captchaPassed: true
      });
    }

    render() {
      const { controls, step, qrCode } = this.state;

      const tooltip = (
        <Tooltip id="tooltip">
          Enter code from Two-Factor Authentication app such as Google Authenticator.
        </Tooltip>
      );                    

      return (
        <Row flexGrow={ 1 } className='login' vertical='start'>
          <Column flexGrow={ 1 }>

            <Panel className='loginPanel'>
              <Panel.Heading>
                <Panel.Title>
                  { step === 1 ? "Please sign in" : "Complete two-factor authentication" }
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <form className='loginForm' onSubmit={ this.loginHandler }>

                  {step === 1 ? (
                    <>
                      <div className='inputWrap'>
                        <input
                          id="username"
                          name="username"
                          type="username"
                          placeholder="username"
                          value={ controls.username.value }
                          className="form-control"
                          autoComplete="false"
                          touched={ controls.username.touched ? 'yes' : 'no' }
                          valid={ controls.username.valid.toString() }
                          onChange={ (event) => this.inputChangedHandler(event, "username") } />
                        <p className='errorMessage'
                          hidden={ (controls.username.valid && controls.username.touched) || (!controls.username.valid && !controls.username.touched) }>
                          {controls.username.validation.message}
                        </p>
                      </div>
                      <div className='inputWrap'>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          value={ controls.password.value }
                          className="form-control"
                          autoComplete="false"
                          touched={ controls.password.touched ? 'yes' : 'no' }
                          valid={ controls.password.valid.toString() }
                          onChange={ (event) => this.inputChangedHandler(event, "password") } />
                        <p className='errorMessage'
                          hidden={ (controls.password.valid && controls.password.touched) || (!controls.password.valid && !controls.password.touched) }>
                          {controls.password.validation.message}
                        </p>
                      </div>
                      <div className="recaptcha">
                        <ReCaptchaValidation
                          handleChange={ this.onRecaptchaChange }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {qrCode && (
                        <div className="two-fa-form text-center">
                          <h4> Open Google Authenticator app on your phone </h4>
                          <h4> and scan the QR code below </h4>
                          <img src={ qrCode } alt="qrCode" className="qr-code" />
                          <p> If you don&apos;t have Google Authenticator app installed </p>
                          <p> you can download it first for your smartphone. </p>
                          
                          <div className="buttons">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_us"
                              className="btn btn-secondary download-link"
                            >
                              <img src={ playMarketIcon } alt="Google Play Market" />
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                              className="btn btn-secondary download-link"
                            >
                              <img src={ appStoreIcon } alt="Apple Store" />
                            </a>
                          </div>
                        </div>
                      )}
                      <div className='inputWrap codeWrap'>
                        <label htmlFor="code">
                          Verification code

                          <OverlayTrigger placement="top" overlay={ tooltip }>
                            <i className="glyphicon glyphicon-info-sign"></i>
                          </OverlayTrigger>
                          
                          <input
                            id="code"
                            name="code"
                            type="code"
                            placeholder=""
                            value={ controls.code.value }
                            className="form-control"
                            autoFocus
                            autoComplete="off"
                            touched={ controls.code.touched ? 'yes' : 'no' }
                            valid={ controls.code.valid.toString() }
                            onChange={ (event) => this.inputChangedHandler(event, "code") } />
                          <p className='errorMessage'
                            hidden={ (controls.code.valid && controls.code.touched) || (!controls.code.valid && !controls.code.touched) }>
                            {controls.code.validation.message}
                          </p>
                        </label>
                      </div>
                    </>
                  )}

                  { step === 1 && (
                    <Button type="submit" className="btn defaultBtn" onClick={ this.loginHandler }>
                      Sign in
                    </Button>
                  )} 

                </form>
              </Panel.Body>
            </Panel>

          </Column>
          <SweetAlert
            show={ this.state.showError }
            title="Error"
            type="error"
            confirmButtonColor={ "#DD6B55" }
            text={ this.state.errorMessage }
            onConfirm={ this.onConfirmError }
          />
        </Row>
      );
    }
}

export default Login;
