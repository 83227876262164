import React, {Component} from 'react';
import { Route } from 'react-router-dom';
import APIData from './modules/apiData/APIData';
import GenerateReport from './modules/generateReport/GenerateReport';
import { crudActions } from './../services/crudActions';

import Clients from './modules/clients/Clients';
import AdminPanel from './modules/adminPanel/AdminPanel';
import ManageClient from './modules/clients/ManageClient';
import ViewClient from './modules/clients/ViewClient';
import EditClient from './modules/clients/EditClient';
import Mids from './modules/mids/Mids';
import Onboarding from './modules/mids/Onboarding';
import ManageMid from './modules/mids/ManageMid';
import ViewMid from './modules/mids/ViewMid';
import EditMid from './modules/mids/EditMid';
import EmptyScreen from './EmptyScreen';
import UserManagement from './modules/userManagement/UserManagement';
import ManageUser from './modules/userManagement/ManageUser';
import ViewUser from './modules/userManagement/ViewUser';
import EditUser from './modules/userManagement/EditUser';
import MyAccount from './modules/myAccount/MyAccount';
import PspCascading from './modules/pspCascading/PspCascading';
import API from './modules/softwareIntegration/API';
import AgentManagement from './modules/userManagement/AgentManagement';
import Blacklist from './modules/blacklist/Blacklist';
import AddBlacklist from './modules/blacklist/AddBlacklist';
import UploadBlacklist from './modules/blacklist/UploadBlacklist';

import PSPs from './modules/psps/PSPs';
import ManagePSP from './modules/psps/ManagePSP';
import ViewPSP from './modules/psps/ViewPSP';
import EditPSP from './modules/psps/EditPSP';
import AddPspTable from './modules/psps/psp-tables/AddTable';

import PendingReferrals from './modules/referrals/PendingReferrals';
import PendingAgents from './modules/referrals/PendingAgents';

import ImportPayouts from './modules/payouts/ImportPayouts';

import GenerateOrder from './modules/generateOrder/GenerateOrder';
import BankCodes from './modules/bankCodes/BankCodes';
import ManageBankCodes from './modules/bankCodes/ManageBankCodes';
import CurrencyTable from './modules/currencyTable/CurrencyTable';

import { Row } from 'simple-flexbox';
import { FETCH_PERMISSIONS } from '../actions/types';
const combineReducers =  require('../reducers/index');

class Module extends Component {
    state = {
      permissions: [],
      roleId: ""
    };

    componentDidMount() {
      crudActions.get(`v1/myaccount/me`).then(
        (user) => {
          if (user) {
            combineReducers.default.dispatch({ type: FETCH_PERMISSIONS, payload: user.permissions, roleId: user.roleId });
            this.setState({
              permissions: user.permissions,
              roleId: user.roleId
            });
          }
        }
      );
    };

    checkPageAccess = (permissionName) => {
      const { permissions } = this.state;
      const foundPermission = permissions.find(elem => elem.permission === permissionName);
      if (!foundPermission) {
        return false;
      }

      return foundPermission.state;
    };

    checkPageAccessCategory = (categoryName) => {
      const { permissions } = this.state;
      const foundPermissions = permissions.filter(elem => elem.category === categoryName);
      return foundPermissions.some(elem => elem.state);
    };

    checkRole = (roleName) => {
      const { roleId } = this.state;
      return roleId === roleName;
    }

    render() {
      return (
        <Row flexGrow={ 1 }>
          <Route exact path="/" component={ EmptyScreen }/>
          <Route exact path="/my-account" component={ MyAccount }/>
          <Route exact path="/docs" component={ API }/>
          { this.checkPageAccessCategory("GENERATE_ORDER") && <Route exact path="/generate-order" component={ GenerateOrder }/> }

          { this.checkPageAccess("PAYOUT_EXCEL_UPLOAD") && <Route exact path="/import-payouts" component={ ImportPayouts }/> }

          { this.checkPageAccessCategory("REFERRALS") && <Route exact path="/pending-referrals" component={ PendingReferrals }/> }
          { this.checkPageAccessCategory("REFERRALS") && <Route exact path="/pending-agents" component={ PendingAgents }/> }

          { this.checkPageAccessCategory("DASHBOARD") && <Route exact path="/dashboard" component={ APIData }/> }

          { this.checkPageAccessCategory("REPORTS") && <Route exact path="/generate-reports" component={ GenerateReport }/> }
          { this.checkPageAccessCategory("REPORTS") && <Route exact path="/transactions" component={ GenerateReport }/> }

          { this.checkPageAccessCategory("ADMIN_PANEL") && <Route exact path="/admin-panel" component={ AdminPanel }/> }

          { this.checkPageAccessCategory("CURRENCY_TABLE") && <Route exact path="/currency-table" component={ CurrencyTable }/> }

          { this.checkPageAccessCategory("CASCADING") && <Route exact path="/provider-priority" component={ PspCascading }/> }

          { this.checkPageAccessCategory("USER_MANAGEMENT") && <Route exact path="/user-management" component={ UserManagement }/> }
          { this.checkPageAccessCategory("USER_MANAGEMENT") && <Route exact path="/agent-management" component={ AgentManagement }/> }
          { this.checkPageAccess("USER_EDIT") && <Route exact path="/add-user" component={ ManageUser }/> }
          { this.checkPageAccess("USER_VIEW") && <Route exact path="/view-user/:userId" component={ ViewUser }/> }
          { this.checkPageAccess("USER_EDIT") && <Route exact path="/edit-user/:userId" component={ EditUser }/> }

          { this.checkPageAccessCategory("MERCHANTS") && <Route exact path="/merchants" component={ Clients }/> }
          { this.checkPageAccess("MERCHANTS_EDIT") && <Route exact path="/add-merchant" component={ ManageClient }/> }
          { this.checkPageAccess("MERCHANTS_VIEW") && <Route path="/view-merchant/:clientId" component={ ViewClient }/> }
          { this.checkPageAccess("MERCHANTS_EDIT") && <Route path="/edit-merchant/:clientId" component={ EditClient }/> }

          { this.checkPageAccessCategory("MIDS") && <Route exact path="/mids" component={ Mids }/> }
          { this.checkPageAccess("MIDS_VIEW") && <Route path="/view-mid/:midId" component={ ViewMid }/> }
          { this.checkPageAccess("MIDS_VIEW") && <Route path="/onboarding-process" component={ Onboarding }/> }
          { this.checkPageAccess("MIDS_EDIT") && <Route path="/edit-mid/:midId" component={ EditMid }/> }
          { this.checkPageAccess("MIDS_EDIT") && <Route exact path="/add-mid" component={ ManageMid }/> }

          { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BLACKLIST")) && <Route exact path="/blacklist" component={ Blacklist }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BLACKLIST")) && <Route exact path="/add-blacklist" component={ AddBlacklist }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BLACKLIST")) && <Route exact path="/upload-blacklist" component={ UploadBlacklist }/> }

          { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BANKLIST")) && <Route exact path="/bank-codes" component={ BankCodes }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("BANKLIST")) && <Route exact path="/add-bank-code" component={ ManageBankCodes }/> }

          { (this.checkRole("MASTER_TECH") || this.checkPageAccessCategory("PROVIDERS")) && <Route exact path="/providers" component={ PSPs }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_EDIT")) && <Route exact path="/add-provider" component={ ManagePSP }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_VIEW")) && <Route path="/view-provider/:pspId" component={ ViewPSP }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_EDIT")) && <Route path="/edit-provider/:pspId" component={ EditPSP }/> }
          { (this.checkRole("MASTER_TECH") || this.checkPageAccess("PSP_EDIT")) && <Route path="/providers/add-table/:type" component={ AddPspTable }/> }
        </Row>
      );
    }
}

export default Module;