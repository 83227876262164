import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';

import Form from "../../../core/Form";
import Select from "../Select";
import Spinner from '../../Spinner';

import '../../../assets/css/userManagement.css';
import GenerateIcon from '../../../assets/images/generateIcon.png';
import VisibilityIcon from '../../../assets/images/visibilityIcon.png';
import VisibilityOffIcon from '../../../assets/images/visibilityOffIcon.png';
import UserManagementIcon from '../../../assets/images/user-management.png';

import localization from '../../../assets/lang/language';
import { PASSWORD_REGEX, TELEGRAM_USERNAME_REGEX } from "../../../constants";
import { crudActions } from "../../../services/crudActions";
import { FETCH_PERMISSIONS } from '../../../actions/types';

const store =  require('../../../reducers/index');
const generator = require('generate-password');

class ManageUser extends Component {
  state = {
    userStatus: [],
    merchants: [],
    userLevel: [],
    agents: [],
    merchantsMids: {},
    merchantsPayoutMids: {},
    //temporary
    currentLanguage: "en",

    permissionsList: {},
    permissionsData: [],
    accessData: {},

    rolesPermissions: {},
    permissions: {},

    userForm: new Form({
      clientsIds: [],
      name: "",
      userName: "",
      password: "",
      roleId: null,
      referredAgentId: null,
      telegram: "",
      phone: "",
      skype: "",
      midsIds :[],
      payoutMidsIds :[]
    }, [{
      name: "clientsIds",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "userName",
      type: "isEmail",
      rules: {
        max: 50,
        required: true
      }
    }, {
      name: "password",
      type: "isPattern",
      rules: {
        required: true,
        pattern: PASSWORD_REGEX,
        customErrorMessage: "Password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character and must be 8 characters or longer"
      }
    }, {
      name: "roleId",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "referredAgentId",
      type: "isNumber",
      rules: {
        required: false,
      }
    }, {
      name: "telegram",
      type: "isPattern",
      rules: {
        pattern: TELEGRAM_USERNAME_REGEX,
        customErrorMessage: "You can use letters, numbers and underscores. Minimum length is 5 characters.",
        required: false
      }
    }, {
      name: "skype",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "phone",
      type: "isString",
      rules: {
        required: false
      } 
    },{   
      name: "midsIds",
      type: "isArray",
      rules: {
        required: false,
        min: 0
      }
    },{
      
      name: "payoutMidsIds",
      type: "isArray",
      rules: {
        required: false,
        min: 0
      }
    }]),

    agentForm: new Form({
      merchantCommission: null,
      agentCommission: null,
      approvalRequestId: null
    }, [{
      name: "merchantCommission",
      type: "isNumber",
      rules: {
        min: 0,
        max: 50,
        customErrorMessage: "Maximum commission allowed is 50%",
        required: false
      }
    }, {
      name: "agentCommission",
      type: "isNumber",
      rules: {
        min: 0,
        max: 20,
        customErrorMessage: "Maximum commission allowed is 20%",
        required: false
      }
    }, {
      name: "approvalRequestId",
      type: "isNumber",
      rules: {
        required: false
      }
    }]),

    roleId: "",
    isVisiblePassword: false,

    showError: false,
    showSuccess: false,
    errorMessage: "",
    redirectToUserManagement: false,
    confirmPassword: "",
    confirmPasswordTouched: false,
    loadingUserSettings: true,
    loadingAccessSettings: true,
    submitTouched: false
  };

  componentDidMount() {
    const { userForm } = this.state;
    let { agentForm } = this.state;
    const userId = this.props.userId;
    const routeState = this.props.location && this.props.location.state;
    let isAgent = false;
    if (routeState) {
      isAgent = routeState.params.isAgent;
    }

    const storeState = store.default.getState().authReducer;
    if (storeState.roleId) {
      this.setState({
        roleId: storeState.roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          roleId: state.roleId
        });
      }
    });

    (crudActions.get('v1/users/management/lookups')).then(
      (lookups) => {
        if (lookups) {
          const userLevel = lookups.userLevel;
          const agent = userLevel.find(elem => elem.value === "15");
          const roleId = parseInt(isAgent && agent ? agent.value : userLevel[0].value, 10);
          this.setState({
            userStatus: lookups.userStatus,
            userLevel: userLevel,
            userForm: Object.assign(userForm, {
              roleId: roleId
            })
          });

          return roleId;
        }
      }
    ).then(
      (roleId) => {
        crudActions.get(`v1/clients/all`).then(
          (data) => {
            if (data) {
              const merchantsMids = data.clients.reduce((accumulator, currentElem) => {
                accumulator[currentElem.client.value] = currentElem.mids.map(elem => elem);
                return accumulator;
              }, {});
              const merchantsPayoutMids = data.clients.reduce((accumulator, currentElem) => {
                accumulator[currentElem.client.value] = currentElem.payoutMids.map(elem => elem);
                return accumulator;
              }, {});
               
              const merchants = data.clients.map(currElem => {
                return currElem.client;
              });

              merchants.unshift({
                value: "0",
                label: "All"
              });

              const updateCompObject = {
                merchants,
                merchantsMids,
                merchantsPayoutMids
              };

              if (!userId && !isAgent) {
                updateCompObject.userForm = Object.assign(userForm, {
                  clientsIds: ["0"]
                });
              }

              this.setState(updateCompObject);
            }
          }
        );

        crudActions.get('v1/agent/all').then(
          (agents) => {
            if (agents) {
              this.setState({
                agents
              });
            }
          }
        );

        Promise.all([
          crudActions.get('v1/users/management/permissions/lookups'),
          crudActions.get('v1/users/management/roles/permissions/lookups'),
          crudActions.get('v1/users/management/roles/level/lookups')
        ]).then(
          (data) => {
            const permissionsList = data[0];
            const permissionsData = data[1];
            const accessData = data[2];
            if (permissionsList && permissionsData && accessData) {
              const updateObject = {
                permissionsList: permissionsList,
                permissionsData: permissionsData,
                permissions: permissionsData.find(elem => elem.roleId === roleId).permissionsSettings.reduce((accumulator, currentElem) => {
                  accumulator[currentElem.permissionId] = currentElem.byDefault;
                  return accumulator;
                }, {}),
                accessData: accessData
              };

              if (userId) {
                (crudActions.get(`v1/users/management/user/${userId}`)).then(
                  (userData) => {
                    if (userData.permissions && userData.clientsIds) {
                      const newPermissions = {
                        ...userData.permissions
                      };

                      const passwordFieldRule = userForm.fieldRules.find(rule => rule.name === "password");
                      passwordFieldRule.rules.required = false;
                      const userRoleId = userData.roleIds[0];

                      const mappedData = {
                        clientsIds: userData.clientsIds.map(id => id.toString()),
                        roleId: userRoleId
                      };

                      if (userRoleId === 15) { // AGENT
                        agentForm = Object.assign(agentForm, userData.agentInfo);
                        const clientIdField = userForm.fieldRules.find(rule => rule.name === "clientsIds");
                        clientIdField.rules.min = 0;
                        clientIdField.rules.required = false;
                      }

                      delete userData.clientsIds;
                      delete userData.roleIds;
                      delete userData.permissions;
                      this.setState({
                        userForm: Object.assign(userForm, userData, mappedData),
                        permissions: newPermissions,
                        loadingUserSettings: false,
                        loadingAccessSettings: false,
                        agentForm: agentForm
                      });
                    }
                  }
                );
              } else {
                updateObject.loadingUserSettings = false;
                updateObject.loadingAccessSettings = false;
                updateObject.isVisiblePassword = true;
                this.generateUserPassword();
              }

              this.setState(updateObject);
            }
          }
        );
      }
    );
  };

  generateUserPassword = () => {
    const password = generator.generate({
      length: 12,
      numbers: true,
      symbols: true,
      strict: true,
      exclude: `[]()"'+_-~=?:;,.|{}<>/#${"`"}^`
    });

    this.setState({
      userForm: Object.assign(this.state.userForm, {
        password: password
      }),
      confirmPassword: password
    });
  };

  mapSelectedItems = (fieldName, lookupName) => {
    const field = this.state.userForm[fieldName];
    const lookup = this.state[lookupName];
  
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };
  
  mapSelectedMids = (type) => {
    const { userForm } = this.state;
     
    const depositMids = userForm.midsIds;
    const allDepositMids = this.getMidCurrencies();

    if (!type || !depositMids || !allDepositMids ) {
      return [];
    }

    const result = depositMids.map(
      // eslint-disable-next-line eqeqeq
      id =>  allDepositMids.find(elem => elem && elem.value == id) || { value: "", label: "" }
    );
    const selectedMids = result.filter(elem => elem.value !== "");
    
    return selectedMids;

  };

  mapSelectedPayoutMids = (type) => {
    const { userForm } = this.state;
    const payoutMids = userForm.payoutMidsIds;
    const allPayoutMids = this.getPayoutMids();

    if (!type || !payoutMids || !allPayoutMids ) {
      return [];
    }

    const result = payoutMids.map(
      // eslint-disable-next-line eqeqeq
      id => allPayoutMids.find(elem => elem && elem.value == id) || { value: "", label: "" }
    );

    const selectedMids = result.filter(elem => elem.value !== "");
    return selectedMids;
  };
  
  getMidCurrencies = () => {
    const { userForm, merchantsMids } = this.state;
    
    if (!merchantsMids || !userForm.clientsIds) {
      return [];
    }
    const merchants = userForm.clientsIds;
    let mids = [];
    merchants.forEach(element => {
      mids = mids.concat(merchantsMids[element]);
    });
   
    if (!mids || mids.includes(undefined)) {
      return [];
    }
    const withoutDuplicate = [...new Map(mids.map(o => [o.value, o])).values()];
    return withoutDuplicate;
   
  };

  getPayoutMids = () => {
    const { userForm, merchantsPayoutMids } = this.state;
    if (!merchantsPayoutMids || !userForm.clientsIds) {
      return [];
    }
    const merchants = userForm.clientsIds;
    let mids = [];
    merchants.forEach(element => {
      mids = mids.concat(merchantsPayoutMids[element]);
    });
    
    if (!mids || mids.includes(undefined)) {
      return [];
    }
    const withoutDuplicate = [...new Map(mids.map(o => [o.value, o])).values()];
    return withoutDuplicate;
  };

  changeUserLevel = (userLevel) => {
    const { permissionsData, accessData, submitTouched } = this.state;
    let { userForm } = this.state;
    const roleId = parseInt(userLevel, 10);
    const permissions = permissionsData.find(elem => elem.roleId === roleId).permissionsSettings.reduce((accumulator, currentElem) => {
      accumulator[currentElem.permissionId] = currentElem.byDefault;
      return accumulator;
    }, {});
    const roleLevel = accessData.find(elem => elem.roleId === roleId).roleLevel;

    const isAgent = roleId === 15;
    const clientIdField = userForm.fieldRules.find(rule => rule.name === "clientsIds");
    if (isAgent) {
      clientIdField.rules.min = 0;
    }
    
    clientIdField.rules.required = !isAgent;

    userForm = Object.assign(userForm, {
      roleId: roleId,
      clientsIds: roleLevel.merchants === "ALL" ? ["0"] : []
    });

    if (submitTouched) {
      userForm.isFormValid();
    }

    this.setState({
      userForm: userForm,
      permissions: permissions
    });
  };

  changePermissionStatus = (permission) => {
    const { permissions, userForm, permissionsData } = this.state;
    permissions[permission] = !permissions[permission];

    const permissionId = parseInt(permission, 10);

    const foundPermission = permissionsData.find(elem => elem.roleId === userForm.roleId).permissionsSettings.find(elem => elem.permissionId === permissionId);
    if (foundPermission.requiredPermissions.length !== 0) {
      foundPermission.requiredPermissions.forEach(elem => {
        const requiredPermission = permissionsData.find(pd => pd.roleId === userForm.roleId).permissionsSettings.find(ps => ps.permissionId === elem);
        if (requiredPermission.editable && !this.state.permissions[elem]) {
          permissions[elem] = permissions[permission];
        }
      });
    }

    this.setState({
      permissions
    });
  };

  submitUserSettings = () => {
    const { permissions, agentForm } = this.state;
    const { userForm } = this.state;

    let agentFormValid = true;
    if (userForm.roleId === 15) { // AGENT
      const clientIdField = userForm.fieldRules.find(rule => rule.name === "clientsIds");
      clientIdField.rules.min = 0;
      clientIdField.rules.required = false;
      agentFormValid = agentForm.isFormValid();
    }

    const isFormValid = userForm.isFormValid();

    const userId = this.props.userId;
    this.setState({
      userForm,
      agentForm,
      confirmPasswordTouched: true,
      submitTouched: true
    });

    if (isFormValid && agentFormValid && this.arePasswordsIdentical()) {
      const userFormData = userForm.data();
      userFormData.roleIds = [userFormData.roleId];
      delete userFormData.roleId;

      const userData = {
        ...userFormData,
        permissions: permissions
      };

      if (userForm.roleId === 15) { // AGENT
        userData.agentInfo = agentForm.data();
      }
    
      const emptyMids = this.mapSelectedMids("midsIds");
      const emptyPayoutMids = this.mapSelectedPayoutMids("payoutMidsIds");
      if(!emptyMids.length){
        delete userData.midsIds;
      }
      // else {return emptyMids};
      
      if(!emptyPayoutMids.length){
        delete userData.payoutMidsIds;
      }

      (userId ? crudActions.put(`v1/users/management/user/${userId}`, userData)
        : crudActions.post('v1/users/management/user', userData)).then(
        () => {
          this.setState({
            showSuccess: true
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true
            });
          }
        }
      );
    } else {
      this.setState({
        errorMessage: "Some fields have errors. Please, fix them.",
        showError: true
      });
    }
  };

  isFieldDisabled = () => {
    const viewOrEdit = this.props.viewOrEdit;

    if (!viewOrEdit) {
      return false;
    }

    return viewOrEdit === 1;
  }

  arePasswordsIdentical = () => {
    const { userForm, confirmPassword } = this.state;
    const { viewOrEdit } = this.props;
    const password = userForm.password;

    if (viewOrEdit === 2 && !password) {
      return true;
    }

    if (!confirmPassword) {
      return false;
    }

    return password === confirmPassword;
  };

  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordTouched: true
    });
  };

  onValueChange = (newValue, fieldName) => {
    let { userForm } = this.state;
    const { submitTouched } = this.state;
    const isNumber = userForm.fieldRules.find(rule => rule.name === fieldName).type === "isNumber";

    if (newValue.value) {
      userForm = Object.assign(userForm, {
        [fieldName]: isNumber ? parseFloat(newValue.value) : fieldName === "clientsIds" ? [newValue.value] : newValue.value
      });
    } else if (newValue.target) {
      userForm = Object.assign(userForm, {
        [fieldName]: isNumber ? parseFloat(newValue.target.value) : newValue.target.value
      });
    } else {
      userForm = Object.assign(userForm, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    if (submitTouched) {
      userForm.isFormValid();
    }

    this.setState({ userForm });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      redirectToUserManagement: this.state.showSuccess
    });
  };

  formatList = (listName) => {
    const list = this.state[listName];
    const { currentLanguage } = this.state;
    const roles = localization[currentLanguage].roles;

    return list.map(elem => {
      return {
        value: elem.value,
        label: roles[elem.label]
      };
    });
  };

  isPermissionDisabled = (value) => {
    const { userForm, permissionsData, permissions } = this.state;
    const permissionId = parseInt(value, 10);
    const permissionsSettings = permissionsData.find(elem => elem.roleId === userForm.roleId).permissionsSettings;
    const permission = permissionsSettings.find(elem => elem.permissionId === permissionId);
    const requiredPermissions = permissionsSettings.filter(elem => elem.requiredPermissions.some(permissionElem => permissionElem === permissionId));

    if (requiredPermissions.some(elem => permissions[elem.permissionId])) {
      return true;
    }

    return !permission.editable;
  };

  getLevelAccess = (roleId, fieldName) => {
    const { accessData } = this.state;
    const roleLevel = accessData.find(elem => elem.roleId === roleId).roleLevel;

    return roleLevel[fieldName];
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  userHasAccess = () => {
    const { roleId } = this.state;
    return roleId === "MASTER_TECH" ||
          roleId === "MASTER_ADMIN" ||
          roleId === "MASTER_USER";
  };

  onCommissionChange = (event, commissionName) => {
    const value = parseFloat(event.target.value);
    this.setState({
      agentForm: Object.assign(this.state.agentForm, {
        [commissionName]: value >= 0 ? value : ""
      })
    });
  };

  mapMerchants = () => {
    const { userForm, merchants } = this.state;

    const mappedMerchants = userForm.clientsIds.map(
      id => (merchants || []).find(elem => elem && elem.value === id) || { value: "", label: "" }
    );
    return mappedMerchants;
  }

  filterAgents = () => {
    const { agents } = this.state;
    const { userId } = this.props;
    if (!userId) {
      return agents;
    }

    return agents.filter(agent => agent.value !== userId);
  };

  togglePasswordView = (e) => {
    e.preventDefault();
    this.setState({
      isVisiblePassword: !this.state.isVisiblePassword
    });
  }

  render() {
    const { permissionsList, currentLanguage, merchants, permissions, userForm,
      showSuccess, showError, errorMessage, redirectToUserManagement,confirmPassword, agentForm,
      confirmPasswordTouched, loadingUserSettings, loadingAccessSettings, isVisiblePassword } = this.state;
    const { viewOrEdit } = this.props;
    const language = localization[currentLanguage];
    if (redirectToUserManagement) {
      return <Redirect to={ `/${userForm.roleId === 15 ? 'agent' : 'user'}-management` }/>;
    }
    const areFieldsDisabled = this.isFieldDisabled();

    return (
      <Row flexGrow={ 1 } className="t365 module user-management" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } }/>
                User Management
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                <Column horizontal='end' style={ {paddingRight: 15} }>
                  <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                    <NavLink to={ `/add-user` } className="btn add-deposit-button">
                      CREATE NEW USER
                    </NavLink>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    {this.props.viewOrEdit === 1 ? "VIEW USER" : this.props.viewOrEdit === 2 ? "EDIT USER" : "CREATE NEW USER"}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title>
                          USER INFO
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        { loadingUserSettings ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                          <Spinner smallContainer={ true } />
                        </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                          <form autoComplete="off">
                            <Row wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                              <input type="password" autoComplete="new-password" style={ { display: "none" } }/>
                              <Column vertical='start' alignSelf='start' className="input-column">
                                <label> Name </label>
                                <input
                                  className={ "form-control " + (userForm.errors.has('name') ? 'error-field' : "") }
                                  value={ userForm.name }
                                  disabled={ areFieldsDisabled }
                                  onChange={ (value) => this.onValueChange(value, 'name') }/>
                                { userForm.errors.has('userName') && <p className="error-message"> { userForm.errors.get('name')} </p>}
                              </Column>
                              <Column vertical='start' alignSelf='start' className="input-column">
                                <label> Email/Username </label>
                                <input
                                  className={ "form-control " + (userForm.errors.has('userName') ? 'error-field' : "") }
                                  value={ userForm.userName || '' }
                                  disabled={ areFieldsDisabled }
                                  onChange={ (value) => this.onValueChange(value, 'userName') }
                                />
                                { userForm.errors.has('userName') && <p className="error-message"> { userForm.errors.get('userName')} </p>}
                              </Column>
                              <Column vertical='start' alignSelf='start' className="input-column">
                                <label> Password </label>
                                <input
                                  type={ isVisiblePassword ? "text" : "password" }
                                  autoComplete="off"
                                  className={ "form-control " + (userForm.errors.has('password') ? 'error-field' : "") }
                                  value={ userForm.password || '' }
                                  disabled={ areFieldsDisabled }
                                  onChange={ (value) => this.onValueChange(value, 'password') }
                                />
                                { userForm.errors.has('password') && <p className="error-message"> { userForm.errors.get('password')} </p>}
                              </Column>
                              <Column vertical='start' alignSelf='start' className="input-column">
                                <label> Confirm Password </label>
                                <input
                                  type={ isVisiblePassword ? "text" : "password" }
                                  autoComplete="off"
                                  className={ "form-control " + (!this.arePasswordsIdentical() && confirmPasswordTouched ? 'error-field' : "") }
                                  value={ confirmPassword || '' }
                                  disabled={ areFieldsDisabled }
                                  onChange={ (value) => this.onConfirmPasswordChange(value) }
                                />
                                { !this.arePasswordsIdentical() && confirmPasswordTouched && <p className="error-message">
                                  { !confirmPassword ? "Confirmation is required."  : "Passwords are not identical." } </p>}
                              </Column>
                              <Column vertical='end' alignSelf='center' className="actions-column">
                                <div>
                                  <button
                                    type="button"
                                    className="toggle"
                                    onClick={ (e) => this.togglePasswordView(e) }
                                  >
                                    <img
                                      src={ isVisiblePassword ? VisibilityOffIcon : VisibilityIcon }
                                      alt={ isVisiblePassword ? "hide" : "show" }
                                      className="visibilityIcon"
                                    />
                                  </button>
                                  <button
                                    type="button"
                                    className="toggle"
                                    onClick={ () => this.generateUserPassword() }
                                  >
                                    <img src={ GenerateIcon } alt="generate" />
                                  </button>
                                </div>
                              </Column>
                            </Row>
                            { this.userHasAccess() && userForm.roleId === 15 && <Column>
                              <Row wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <label> Agent </label>
                                  <Select id="referredAgentId"
                                    name="referredAgentId"
                                    value={ userForm.referredAgentId || '' }
                                    required={ true }
                                    disabled={ areFieldsDisabled }
                                    clearable={ false }
                                    onChange={ (event) => this.onValueChange(event, "referredAgentId") }
                                    options={ this.filterAgents() }
                                  />
                                </Column>
                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <label> Telegram Username </label>
                                  <input
                                    className={ "form-control " + (userForm.errors.has('telegram') ? 'error-field' : "") }
                                    value={ userForm.telegram || '' }
                                    disabled={ areFieldsDisabled }
                                    onChange={ (value) => this.onValueChange(value, 'telegram') }
                                  />
                                  { userForm.errors.has('telegram') && <p className="error-message">
                                    { userForm.errors.get('telegram') }
                                  </p> }
                                </Column>
                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <label> Skype </label>
                                  <input
                                    className={ "form-control " + (userForm.errors.has('skype') ? 'error-field' : "") }
                                    value={ userForm.skype || '' }
                                    disabled={ areFieldsDisabled }
                                    onChange={ (value) => this.onValueChange(value, 'skype') }
                                  />
                                </Column>
                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <label> Phone </label>
                                  <input
                                    className={ "form-control " + (userForm.errors.has('phone') ? 'error-field' : "") }
                                    value={ userForm.phone || '' }
                                    disabled={ areFieldsDisabled }
                                    onChange={ (value) => this.onValueChange(value, 'phone') }
                                  />
                                </Column>
                              </Row>
                              <Row wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <label> Agent Commission </label>
                                  <input
                                    type="number"
                                    disabled={ areFieldsDisabled }
                                    className={ "form-control " + (agentForm.errors.has("agentCommission") ? 'error-field' : '') }
                                    value={ this.getValue(agentForm.agentCommission) }
                                    onChange={ (event) => this.onCommissionChange(event, "agentCommission") }/>
                                  <span className="percent-sign-complex"> % </span>
                                  { agentForm.errors.has('agentCommission') && <p className="error-message"> { agentForm.errors.get('agentCommission')} </p>}
                                </Column>

                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <label> Merchant Commission </label>
                                  <input
                                    type="number"
                                    disabled={ areFieldsDisabled }
                                    className={ "form-control " + (agentForm.errors.has("merchantCommission") ? 'error-field' : '') }
                                    value={ this.getValue(agentForm.merchantCommission) }
                                    onChange={ (event) => this.onCommissionChange(event, "merchantCommission") }/>
                                  <span className="percent-sign-complex"> % </span>
                                  { agentForm.errors.has('merchantCommission') && <p className="error-message"> { agentForm.errors.get('merchantCommission')} </p>}
                                </Column>
                                <Column vertical='start' alignSelf='start' className="input-column">
                                  <span/>
                                </Column>
                              </Row>
                            </Column> }
                          </form>
                        </div> }
                      </Panel.Body>
                    </Panel>
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title>
                          USER ACCESS
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        { loadingAccessSettings ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                          <Spinner smallContainer={ true } />
                        </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                          <Row wrap={ true } horizontal='start' vertical='end' style={ { width: '100%'} }>
                            <Column vertical='start' alignSelf='start' className="input-column">
                              <label> User Level </label>
                              <Select id="level"
                                name="level"
                                value={ userForm.roleId || '' }
                                required={ true }
                                disabled={ areFieldsDisabled }
                                clearable={ false }
                                onChange={ (level) => this.changeUserLevel(level.value) }
                                options={ this.formatList("userLevel") }
                              />
                              { userForm.errors.has('roleId') && <p className="error-message"> { userForm.errors.get('roleId')} </p>}
                            </Column>
                            <Column vertical='start' alignSelf='start' className="input-column">
                              <label> Master BO </label>
                              <input
                                className="form-control"
                                disabled
                                value={ this.getLevelAccess(userForm.roleId, "masterBO") ? "Yes" : "No" }
                              />
                            </Column>
                            <Column vertical='start' alignSelf='start' className="input-column">
                              <label> Merchant </label>
                              <Select
                                className={ userForm.errors.has('clientsIds') ? 'error-field' : "" }
                                value={ userForm.clientsIds[0] || '' }
                                options={ this.getLevelAccess(userForm.roleId, "merchants") === "ALL" ? 
                                  [{ value: "0", label: "All" }] 
                                  : this.getLevelAccess(userForm.roleId, "merchants") === "ONE" ? 
                                    (merchants || []).filter(elem => elem && elem.value !== "0")
                                    : this.getLevelAccess(userForm.roleId, "merchants") === "n/a" ? [] : (merchants || []) }
                                required={ true }
                                clearable={ false }
                                disabled={ areFieldsDisabled || this.getLevelAccess(userForm.roleId, "merchants") === "ALL" ||
                                          this.getLevelAccess(userForm.roleId, "merchants") === "n/a" }
                                placeholder={ this.getLevelAccess(userForm.roleId, "merchants") === "n/a" ? "N/A" : "Select..." }
                                type={ "clientsIds" }
                                onChange={ (e) => this.onValueChange(e, "clientsIds") }
                              />
                              { userForm.errors.has('clientsIds') && <p className="error-message"> { userForm.errors.get('clientsIds')} </p>}
                            </Column>
                            <Column  vertical='start' alignSelf='start' className="input-column empty-column">
                              <span/>
                            </Column>
                            <Column  vertical='start' alignSelf='start' className="input-column empty-column">
                              <span/>
                            </Column>
                          </Row>
                          { Object.keys(permissionsList).sort().map(type => {
                            return <Row key={ type } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start'>
                                <span className="permission-header">
                                  {type.replace(/_/g, " ")}
                                </span>
                                <hr className="permission-separator"/>
                                <Row className="styled-input--square" wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                  { permissionsList[type].map(permission => {
                                    return permission.label !== "REPORTS_ACTIVITY" && (
                                      <div
                                        key={ permission.value }
                                        className="styled-input-single"
                                      >
                                        <input
                                          type="checkbox"
                                          id={ permission.label }
                                          checked={ userForm.roleId ? permissions[permission.value] : false }
                                          onChange={ () => this.changePermissionStatus(permission.value) }
                                          disabled={ areFieldsDisabled || this.isPermissionDisabled(permission.value) }
                                        />
                                        <label
                                          htmlFor={ permission.label }
                                          className={ areFieldsDisabled || this.isPermissionDisabled(permission.value) ? "disabled" : "" }
                                        >
                                          {language.permissions[permission.label]}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </Row>
                              </Column>
                            </Row>;
                          }) }
                        </div> }
                      </Panel.Body>
                    </Panel>
                    { viewOrEdit !== 1 && !loadingAccessSettings && !loadingUserSettings && (
                      <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                        <Column flexGrow={ 0 } vertical='end' className="input-column">
                          <Button
                            type="submit"
                            className="btn defaultBtn"
                            onClick={ this.submitUserSettings }>
                            Save
                          </Button>
                        </Column>
                      </Row>
                    )}
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ `User successfully ${viewOrEdit === 2 ? 'updated' : 'saved'}.` }
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#25282a"
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default ManageUser;
