import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Column, Row } from 'simple-flexbox';

import Header from './Header';
import Login from './modules/login/Login';
import Module from './Module';
import SideBar from './SideBar';
import ScrollToTop from './functional-components/ScrollToTop';

import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/main.css';

class App extends Component {
  state = {
    userLoggedIn: false
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
      this.setState({ userLoggedIn: true });
    }
  }

  render() {
    const { userLoggedIn } = this.state;

    return (
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <Route>
              <Column
                className={ `t365 content ${userLoggedIn ? 'logged-in' : 'non-logged-in'}` }
                style={ { zoom: userLoggedIn ? 0.9 : 1} }
              >
                <Header />
                <Row flexGrow={ 1 }>
                  { userLoggedIn &&
                  	<Column className="t365 sidebarColumn">
                  	  <SideBar />
                  	</Column>
                  }
                  <Column className="t365 contentColumn" style={ {width: '100%'} }>
                    { userLoggedIn ?  <Module /> : <Login /> }
                  </Column>
                </Row>
              </Column>
            </Route>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;