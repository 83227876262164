import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';

import Modal from '../Modal';
import Multiselect from "../customMultiselect";
import PromptModal from '../generateReport/PromptModal';
import Select from "../Select";
import Spinner from '../../Spinner';
import Table from "../Table";

import '../../../assets/css/mids.css';
import MidsIcon from '../../../assets/images/mids.png';

import { FETCH_PERMISSIONS } from '../../../actions/types';
import {crudActions} from "../../../services/crudActions";
const store =  require('../../../reducers/index');

class Mids extends Component {
  state = {
    merchants: [],
    providers: [],
    countries: [],
    currency: [],
    paymentMethod: [],
    sellMethod: [],
    payoutMethod: [],
    midCategories: [],
    filterOn: false,

    controls: {
      psp: "",
      name: "",
      country: "",
      type: "",
      merchant: "",
      midCategories: []
    },

    mids: [],

    midsLinks: [{
      name: "ADD MIDs",
      url: "/add-mid",
    }],

    showPromptModal: false,
    changeValueMessage: "",
    editableField: "",
    editableMidId: "",
    editableFieldValue: "",
    originalEditableFieldValue: "",

    showModal: false,
    modalData: "",

    filteredMids: [],
    access: [],
    isLoading: true,
    isBurgerOpen: false,
    showError: false,
    errorMessage: ""
  };

  node = null;
  subscribeFunction = null;
  burgerNode = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener("keydown", this.handleEscapeClick, false);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get(`v1/clients/all`),
      crudActions.get("v1/psp"),
      crudActions.get('v1/mids'),
      crudActions.get(`v1/adminpanel`)
    ]).then(
      data => {
        const dropdowns = data[0];
        const merchants = data[1].clients.map(currElem => {
          return currElem.client;
        });
        const providers = data[2];
        const mids = data[3];
        const categories = data[4];

        if (dropdowns && merchants && providers && mids) {
          this.setState({
            countries: dropdowns.country,
            currency: dropdowns.currency,
            paymentMethod: dropdowns.buyMethods,
            sellMethod: dropdowns.sellMethods,
            payoutMethod: dropdowns.payoutMethods,
            merchants,
            providers: providers.map(elem => {
              return {
                value: elem.id,
                label: elem.name
              };
            }),
            mids: mids.map(elem => {
              return {
                ...elem,
                midName: `${elem.midName} (${elem.id})`
              };
            }),
            midCategories: categories.storedLookups.midCategories.map(elem => {
              return {
                value: elem,
                label: elem
              };
            }),
            isLoading: false
          });
        }
      }
    );
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  }

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener("keydown", this.handleEscapeClick, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  handleEscapeClick = (event) => {
    if (event.keyCode === 27 && this.state.editableMidId !== "") {
      this.setState({
        editableField: "",
        editableMidId: "",
        editableFieldValue: "",
        originalEditableFieldValue: ""
      });
    }
  };

  onValueChange = (event, field) => {
    const { controls } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    this.setState({
      controls
    });
  };

  mapSelectedItems = (type) => {
    const field = this.state.controls[type];
    const lookup = this.state[type];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  onChangeFilter = () => {
    this.filterMids();
  };

  filterMids = () => {
    crudActions.get('v1/mids')
      .then(response => {
        if (response) {
          this.setState({
            mids: response.map(elem => {
              return {
                ...elem,
                midName: `${elem.midName} (${elem.id})`
              };
            })
          }, () => {
            this.getFilteredMids();
          });
        }
      });
  };

  getFilteredMids = () => {
    const { mids, controls } = this.state;
    const filteredMids = mids.filter(elem => {
      let startCondition = elem;
      if (controls.psp) {
        startCondition = startCondition && ((elem.pspId === controls.psp) || (elem.payoutPspId && elem.payoutPspId === controls.psp));
      }

      if (controls.name) {
        startCondition = startCondition && elem.midName.toLowerCase().includes(controls.name.toLowerCase());
      }

      if (controls.country) {
        startCondition = startCondition && elem.countries.some(country => country === controls.country);
      }

      if (controls.type) {
        startCondition = startCondition && elem.paymentMethodType === controls.type;
      }

      const merchantOption = controls.merchant;
      if (merchantOption) {
        if (merchantOption === "multi") {
          startCondition = startCondition && elem.merchants.length > 1;
        } else if (merchantOption === "no_merchant") {
          startCondition = startCondition && elem.merchants.length === 0;
        } else {
          startCondition = startCondition && elem.merchants.find(merchant => merchant.toString() === merchantOption.toString());
        }
      }

      if (controls.midCategories.length > 0) {
        startCondition = startCondition && controls.midCategories.some(midCategory => midCategory === elem.category);
      }

      return startCondition;
    });

    this.setState({
      filteredMids,
      filterOn: true
    });
  };

  getMidInfo = (midData, infoType) => {
    const midInfo = this.state[infoType].find(elem => elem.value === midData);
    if (!midInfo) {
      return "";
    }

    return midInfo.label;
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  tableColumns = () => {
    const columns = [{
      value: "midName",
      label: "MID name"
    }, {
      value: "merchants",
      label: "Merchant"
    }, {
      value: "pspId",
      label: "Providers"
    }, {
      value: "paymentMethod",
      label: "Payment Method"
    }, {
      value: "payoutPaymentMethod",
      label: "Payout Method"
    }, {
      value: "countries",
      label: "Countries"
    }, {
      value: "minDeposit",
      label: "Min Deposit",
      isEditable: true
    }, {
      value: "maxDeposit",
      label: "Max Deposit",
      isEditable: true
    }, {
      value: "minPayout",
      label: "Min Payout",
      isEditable: true
    }, {
      value: "maxPayout",
      label: "Max Payout",
      isEditable: true
    }, {
      value: "currency",
      label: "Currency"
    }, {
      value: "enabled",
      label: "Status"
    }, {
      value: "callbackUrl",
      label: "Callback URL",
      centerAligned: true,
      className: "open-modal-btn",
      buttonLabel: "View",
      isButton: true,
      triggerEvent: (data) => this.onOpenModalClick(data),
      isButtonDisabled: () => { return false; }
    }, {
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-mid",
      route: "/view-mid",
      isAction: true
    }];

    if (this.checkPageAccess("MIDS_EDIT")) {
      columns.push({
        value: "id",
        label: "Edit",
        centerAligned: true,
        className: "btn edit-mid",
        route: "/edit-mid",
        isAction: true
      });
    }

    return columns;
  };

  tableData = () => {
    const { filteredMids, providers, merchants } = this.state;

    return filteredMids.map(mid => {
      const foundPsp = providers.find(elem => elem.value === mid.pspId);
      const foundPayoutPsp = mid.payoutPspId ? providers.find(elem => elem.value === mid.payoutPspId) : "";
      const pspsLabels = `${foundPsp ? foundPsp.label : ""}${foundPayoutPsp && foundPsp.label !== foundPayoutPsp.label ? ', ' + foundPayoutPsp.label : ""}`;
      const countriesLabel = mid.countries.length === 0 ? "No countries." :
        mid.countries.length === 1 ? this.getMidInfo(mid.countries[0], "countries") : "Multiple Countries";
      const midMerchants = mid.merchants || [];

      return Object.assign({}, mid, {
        pspId: pspsLabels,
        merchants: midMerchants.length === 0 ? "No merchants" :
          midMerchants.length === 1 ? merchants.find(merchant => merchant.value.toString() === midMerchants[0].toString()).label :
            "Multi",
        paymentMethod: this.getMidInfo(mid.paymentMethod, "paymentMethod"),
        payoutPaymentMethod: mid.payoutPaymentMethod ? this.getMidInfo(mid.payoutPaymentMethod, "payoutMethod") : "",
        countries: countriesLabel,
        currency: this.getMidInfo(mid.currency, "currency"),
        enabled: mid.enabled ? "On" : "Off"
      });
    });
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  onDoubleClick = (row, column) => {
    if (column.isEditable && this.checkPageAccess("MIDS_EDIT")) {
      if (((column.value === "minPayout" || column.value === "maxPayout") && row.payoutPspId && row.payoutPaymentMethod) ||
        column.value === "minDeposit" || column.value === "maxDeposit") {
        this.setState({
          editableField: column.value,
          editableMidId: row.id,
          editableFieldValue: row[column.value],
          originalEditableFieldValue: row[column.value]
        });
      }
    }
  };

  onEditableInputChange = (value) => {
    this.setState({
      editableFieldValue: value
    });
  };

  handleEnterPress = (event) => {
    const { editableField, editableFieldValue, originalEditableFieldValue } = this.state;
    if ((event.key === "Enter" && editableFieldValue === originalEditableFieldValue) || event.key === "Escape") {
      this.setState({
        editableField: "",
        editableMidId: "",
        editableFieldValue: "",
        originalEditableFieldValue: ""
      });
      return;
    } else if (event.key === "Enter" && editableFieldValue !== originalEditableFieldValue) {
      this.setState({
        showPromptModal: true,
        changeValueMessage: `Do you want to change the ${editableField}?`
      });
    }
  };

  handleConfirmPromptModal = () => {
    const { editableField, editableFieldValue, editableMidId, filteredMids } = this.state;

    const editableMid = filteredMids.find(mid => mid.id === editableMidId);

    if (editableMid) {
      crudActions.patch(`v1/mids/${editableMidId}`, {
        [editableField]: editableFieldValue
      }).then(() => {
        editableMid[editableField] = editableFieldValue;
        this.setState({
          filteredMids,
          showPromptModal: false,
          editableField: "",
          editableMidId: "",
          editableFieldValue: "",
          originalEditableFieldValue: "",
          showSuccess: true,
          successMessage: `Mid ${editableField} value has been updated!`,
        });
      }).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true
            });
          }
        }
      );
    }
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      successMessage: ""
    });
  };

  onOpenModalClick = (data) => {
    this.setState({
      showModal: true,
      modalData: data.callbackUrl || ""
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      modalData: ""
    });
  };

  render() {
    const {
      controls,
      countries,
      editableField,
      editableFieldValue,
      editableMidId,
      errorMessage,
      filterOn,
      isBurgerOpen,
      isLoading,
      merchants,
      midCategories,
      midsLinks,
      modalData,
      providers,
      showError,
      showModal
    } = this.state;
    const columns = this.tableColumns();

    return (
      <Row flexGrow={ 1 } className="t365 module mids" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MidsIcon } alt="" style={ { marginRight: 10 } }/>
                MIDs
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("MIDS_EDIT") && <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink to={ `/add-mid` } className="btn add-deposit-button">
                      ADD MIDs
                    </NavLink>
                    <div ref={ node => this.burgerNode = node } className={ "burger-container " + (isBurgerOpen ? "change" : "") } style={ { float: "right" } }  onClick={ this.onBurgerClick }>
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    { isBurgerOpen && <div ref={ node => this.node = node } className="burger-content">
                      { midsLinks.map((link, i) => {
                        return <div key={ i } className="link-item-container">
                          <NavLink to={ link.url } className="link-item" onClick={ () => this.handleClickOutside() }> {link.name} </NavLink>
                        </div>;
                      }) }
                    </div>
                    }
                  </Row>
                </Column> }
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH MIDs
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Merchant </label>
                          <Select id="merchant"
                            name="merchant"
                            value={ controls.merchant || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'merchant') }
                            options={ merchants }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <Select id="psp"
                            name="psp"
                            value={ controls.psp || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'psp') }
                            options={ providers }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Country </label>
                          <Select id="country"
                            name="country"
                            value={ controls.country || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'country') }
                            options={ countries }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Category </label>
                          <Multiselect
                            selectedItems={ this.mapSelectedItems("midCategories") }
                            disabled={ false }
                            items={ midCategories }
                            type={ "midCategories" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> MID Names </label>
                          <input
                            className="form-control"
                            value={ controls.name || '' }
                            onChange={ (event) => this.onValueChange(event, 'name') }/>
                        </Column>
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <Button type="submit" className="btn defaultBtn" onClick={ () => this.onChangeFilter() }> SEARCH </Button>
                        </Column>
                      </Row>
                      { filterOn && (
                        <div className="mids-table">
                          <Table
                            columns={ columns }
                            data={ this.tableData() }
                            isStriped={ true }
                            defaultSortBy={ columns[0].value }
                            useArrow={ true }
                            editableField={ editableField }
                            editableMidId={ editableMidId }
                            editableFieldValue={ editableFieldValue }
                            onKeyDown={ (event) => this.handleEnterPress(event) }
                            onEditableInputChange={ (value) => this.onEditableInputChange(value) }
                            onDoubleClick={ (row, column) => this.onDoubleClick(row, column) }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        <Modal
          title="Callback URL"
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType="text"
          data={ modalData }
        />

        <PromptModal
          title="Change value"
          message={ this.state.changeValueMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ false }
          showModal={ this.state.showPromptModal }
        />

        <SweetAlert
          show={ this.state.showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ this.state.successMessage || "Report successfully generated." }
          onConfirm={ this.onConfirm }
        />

        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#25282a"
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default Mids;
