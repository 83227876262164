import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Table from "../Table";

import '../../../assets/css/clients.css';
import MerchantsIcon from '../../../assets/images/merchants.png';

import { crudActions } from "../../../services/crudActions";
import { FETCH_PERMISSIONS } from '../../../actions/types';
const store =  require('../../../reducers/index');

class Clients extends Component {
  state = {
    clients: [],
    searchPattern: "",
    isSuggestionBoxOpen: false,
    access: []
  }

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get("v1/clients").then(clients => {
      if (clients) {
        this.setState({ clients: clients });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onSuggestionClick(clientName) {
    this.setState({
      searchPattern: clientName,
      isSuggestionBoxOpen: false
    });
  }

  tableColumns = () => {
    const columns = [{
      value: "name",
      label: "Merchant Name"
    }, {
      value: "shortName",
      label: "Company Name"
    }, {
      value: "email",
      label: "Email"
    }, {
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-client",
      route: "/view-merchant",
      isAction: true
    }];

    if (this.checkPageAccess("MERCHANTS_EDIT")) {
      columns.push({
        value: "id",
        label: "Edit",
        centerAligned: true,
        className: "btn edit-client",
        route: "/edit-merchant",
        isAction: true
      });
    }

    return columns;
  };

  searchSuggests(isSuggestion) {
    const { clients, searchPattern } = this.state;
    const searchValue = searchPattern.toLowerCase();

    let sortedClients = clients;

    if (!searchValue) {
      return isSuggestion ? [] : sortedClients;
    }

    sortedClients = sortedClients.filter(client => {
      return client.name.toLowerCase().includes(searchValue);
    });

    return sortedClients;
  }

  isBoxOpen() {
    if (this.state.isSuggestionBoxOpen) {
      return <div
        className='search-suggests'>
        {
          this.searchSuggests(true).map((client, index) => {
            return <div className='suggestion' key={ client.shortName + index } onClick={ () => this.onSuggestionClick(client.name) }>
              {client.name}
            </div>;
          })
        }
      </div>;
    }

    return <div></div>;
  }

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchPattern: value,
      isSuggestionBoxOpen: false
    });
  }

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  render() {
    const { searchPattern } = this.state;
    const columns = this.tableColumns();

    return (
      <Row flexGrow={ 1 } className="t365 module apidata clients" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MerchantsIcon } alt="" style={ { marginRight: 10 } }/>
                  Merchants
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("MERCHANTS_EDIT") && (
                  <Column horizontal='end' style={ { paddingRight: 15 } }>
                    <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                      <NavLink to={ `/add-merchant` } className="btn add-merchant-button">
                          ADD MERCHANT
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>

          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                      MERCHANT INFORMATION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ { overflow: 'unset' } }>

                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='search-bar'>
                      <input
                        className='form-control'
                        type='text'
                        value={ searchPattern }
                        placeholder='Search Merchant Name'
                        onChange={ this.handleSearchChange }
                      />
                      { this.isBoxOpen() }
                    </Row>

                    <div className="clients-wrapper">
                      <Table
                        columns={ columns }
                        data={ this.searchSuggests() }
                        isStriped={ true }
                        defaultSortBy={ columns[0].value }
                        useArrow={ true }
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
              
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default Clients;
