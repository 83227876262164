import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Multiselect from "../customMultiselect";
import Select from "../Select";
import Spinner from '../../Spinner';
import Table from "../Table";

import '../../../assets/css/mids.css';
import AdminPanelIcon from '../../../assets/images/adminpanel.png';

import { FETCH_PERMISSIONS } from '../../../actions/types';
import { crudActions } from "../../../services/crudActions";
const store =  require('../../../reducers/index');

class Mids extends Component {
  state = {
    psp: [],
    merchants: [],
    filterOn: false,
    mids: [],
    paymentMethods: [],

    controls: {
      psp: [],
      type: "",
      merchant: [],
      midTypes: "",
      midStatuses: "",
      integrationsStatuses: [],
      paymentMethod: ""
    },

    midStatuses: [{
      value: "On",
      label: "On"
    }, {
      value: "Off",
      label: "Off"
    }],

    integrationsStatuses: [{
      value: "DONE",
      label: "Done"
    }, {
      value: "INCOMPLETE",
      label: "Incomplete"
    }],

    midTypes: [{
      value: "LIVE",
      label: "Live"
    }, {
      value: "DEMO",
      label: "Test"
    }],

    access: [],
    isLoading: false,
    isLookupsLoading: true,
    isBurgerOpen: false,
    merchantError: false,
    midTypesError: false,
  };

  node = null;
  subscribeFunction = null;
  burgerNode = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get(`v1/clients/all`),
      crudActions.get("v1/psp")
    ]).then(
      data => {
        const dropdowns = data[0];
        const clientsData = data[1];
        const psps = data[2];

        if (dropdowns && clientsData && psps) {
          this.setState({
            merchants: clientsData.clients.map(currElem => {
              return currElem.client;
            }),
            paymentMethods: dropdowns['buyMethods'],
            psp: psps.map(elem => {
              return {
                value: elem.id,
                label: elem.name
              };
            }),
            isLookupsLoading: false
          }, () => {
          });
        }
      }
    );
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  }

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls, merchantError, midTypesError } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (controls.merchant.length > 0 && merchantError === true) {
      this.setState({
        merchantError: false
      });
    } 

    if (controls.midTypes !== null && midTypesError === true) {
      this.setState({
        midTypesError: false
      });
    } 

    this.setState({
      controls
    });
  };

  onChangeFilter = () => {
    this.filterMids();
  };

  mapMerchants = () => {
    const { controls, merchants } = this.state;

    const field = controls.merchant;
    return field.map(elem => {
      return merchants.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapSelectedItems = (type) => {
    const field = this.state.controls[type];
    let lookup = this.state[type];
    if (!lookup) {
      lookup = this.state[type];
    }
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  filterMids = () => {
    let valid = true;
    const { controls } = this.state;

    if (controls.merchant.length === 0) {
      valid = false;
      this.setState({
        merchantError: true
      });
    } else {
      this.setState({
        merchantError: false
      });
    }

    if (controls.midTypes === null || controls.midTypes === "") {
      valid = false;
      this.setState({
        midTypesError: true
      });
    } else {
      this.setState({
        midTypesError: false
      });
    }
 
    const data = {
      merchantsIds: controls.merchant,
      pspIds: controls.psp,
      integrationsStatuses: controls.integrationsStatuses,
      midType: controls.midTypes
    };

    if (controls.midStatuses !== null && controls.midStatuses !== "") {
      data.midStatus = controls.midStatuses === "On";
    }
    if (valid) {
      this.setState({
        isLoading: true,
        filterOn: true,
        midTypesError: false,
        merchantError: false
      });
      (crudActions.post('v1/mids/merchants-onboarding', data)).then(
        (response) => {
          if (response) {
            this.setState({
              mids: response,
              isLoading: false
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              isLoading: false
            });
          }
        }
      );
    }
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  tableColumns = () => {
    const columns = [{
      value: "merchantName",
      label: "Merchant"
    }, {
      value: "midName",
      label: "MID name"
    }, {
      value: "pspName",
      label: "Provider"
    }, {
      value: "paymentMethod",
      label: "Method"
    }, {
      value: "currency",
      label: "Currency"
    }, {
      value: "minMaxAmount",
      label: "Min/Max amount"
    }, {
      value: "cascadingPriority",
      label: "Cascading Priority"
    }, {
      value: "firstSuccess",
      label: "First Success"
    }, {
      value: "integrationStatus",
      label: "Integration status"
    }];

    return columns;
  };

  tableData = () => {
    const { mids, paymentMethods } = this.state;

    return mids.map(mid => {
      return Object.assign({}, mid, {
        midName: `${mid.midName} (${mid.midId})`,
        minMaxAmount: mid.minAmount + "/" + mid.maxAmount,
        paymentMethod: paymentMethods.find(el=>el.value === mid.paymentMethod).label
      });
    });
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  render() {
    const { controls, midTypes, midStatuses, integrationsStatuses, filterOn, psp,
      isLoading, isLookupsLoading, merchants, isBurgerOpen, midsLinks, merchantError, midTypesError } = this.state;
    const columns = this.tableColumns();

    return (
      <Row flexGrow={ 1 } className="t365 module mids" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ AdminPanelIcon } alt="" style={ { marginRight: 10 } }/>
                Onboarding Process
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("MIDS_VIEW") && <Column horizontal='end' style={ {paddingRight: 15} }>
                  <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                    <div ref={ node => this.burgerNode = node } className={ "burger-container " + (isBurgerOpen ? "change" : "") } style={ { float: "right" } }  onClick={ this.onBurgerClick }>
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    { isBurgerOpen && <div ref={ node => this.node = node } className="burger-content">
                      { midsLinks.map((link, i) => {
                        return <div key={ i } className="link-item-container">
                          <NavLink to={ link.url } className="link-item" onClick={ () => this.handleClickOutside() }> {link.name} </NavLink>
                        </div>;
                      }) }
                    </div>
                    }
                  </Row>
                </Column> }
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH MIDs
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLookupsLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Merchant </label>
                        <Multiselect
                          isError={ merchantError }
                          selectedItems={ this.mapMerchants() }
                          items={ merchants }
                          required={ true }
                          type={ "merchant" }
                          showTooltip={ true }
                          onChange={ (value) => this.onValueChange(value, 'merchant') }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> MID Type </label>
                        <Select id="midTypes"
                          name="midTypes"
                          value={ controls.midTypes }
                          className={ midTypesError ? 'error-field' : "" }
                          required={ true }
                          clearable={ false }
                          onChange={ (value) => this.onValueChange(value, 'midTypes') }
                          options={ midTypes }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Provider </label>
                        <Multiselect
                          selectedItems={ this.mapSelectedItems("psp", true) }
                          items={ psp }
                          type={ "psp" }
                          showTooltip={ true }
                          onChange={ (value) => this.onValueChange(value, 'psp') }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span />
                      </Column>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='center' vertical='center' style={ { width: '100%' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> MID Statuses </label>
                          <Select id="midStatuses"
                            name="midStatuses"
                            value={ controls.midStatuses }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'midStatuses') }
                            options={ midStatuses }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <label> Integration Statuses </label>
                          <Multiselect
                            selectedItems={ this.mapSelectedItems("integrationsStatuses", true) }
                            items={ integrationsStatuses }
                            type={ "integrationsStatuses" }
                            showTooltip={ true }
                            onChange={ (value) => this.onValueChange(value, 'integrationsStatuses') }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='center' className="input-column fullwidth-button">
                          <Button type="submit" className="btn defaultBtn" onClick={ () => this.onChangeFilter() }> SEARCH </Button>
                        </Column>
                      </Row>
                    </Row>
                    { isLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                      { filterOn && <div className="mids-table">
                        <Table
                          columns={ columns }
                          data={ this.tableData() }
                          isStriped={ true }
                          defaultSortBy={ columns[0].value }
                          useArrow={ true }/>
                      </div> }
                    </div> }
                  </div> }
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>

    );
  }
}

export default Mids;
