import { DASHBOARD_SELLS_EVENT, DASHBOARD_PURCHASES_EVENT, DASHBOARD_FTD_EVENT,
  DASHBOARD_TRANSFER_EVENT, DASHBOARD_REVENUE_EVENT, DASHBOARD_USERS_EVENT,
  DASHBOARD_PURCHASE_COUNTRIES_EVENT, DASHBOARD_CUSTOMER_BALANCES_EVENT, DASHBOARD_PIE_EVENT,
  DASHBOARD_HISTOGRAM_EVENT, DASHBOARD_WITHDRAWALS_EVENT, DASHBOARD_PAYOUT_COUNTRIES_EVENT,
  DASHBOARD_PAYOUT_EVENT, DASHBOARD_DEPOSITS_PER_MIDS, DASHBOARD_PAYOUTS_PER_MIDS,
  DASHBOARD_AMOUNTS_PER_MID } from '../actions/types';

export default function(state = {}, action) {
  state.update = action.type;
  switch(action.type) {
  case DASHBOARD_SELLS_EVENT:
    state.sells = action.payload;
    state.sells.socket = action.socket;
    return state;
  case DASHBOARD_PURCHASES_EVENT:
    state.purchases = action.payload;
    state.purchases.socket = action.socket;
    return state;
  case DASHBOARD_FTD_EVENT:
    state.ftds = action.payload;
    state.ftds.socket = action.socket;
    return state;
  case DASHBOARD_TRANSFER_EVENT:
    state.transfers = action.payload;
    state.transfers.socket = action.socket;
    return state;
  case DASHBOARD_REVENUE_EVENT:
    state.revenue = action.payload;
    state.revenue.socket = action.socket;
    return state;
  case DASHBOARD_PURCHASE_COUNTRIES_EVENT:
    state.purchaseMethods = action.payload;
    state.purchaseMethods.socket = action.socket;
    return state;
  case DASHBOARD_USERS_EVENT:
    state.users = action.payload;
    state.users.socket = action.socket;
    return state;
  case DASHBOARD_CUSTOMER_BALANCES_EVENT:
    state.general = action.payload;
    state.general.socket = action.socket;
    return state;
  case DASHBOARD_PIE_EVENT:
    state.pieData = action.payload;
    state.pieData.socket = action.socket;
    return state;
  case DASHBOARD_HISTOGRAM_EVENT:
    state.histogramData = action.payload;
    state.histogramData.socket = action.socket;
    return state;
  case DASHBOARD_DEPOSITS_PER_MIDS:
    state.depositsPerMids = action.payload;
    state.depositsPerMids.socket = action.socket;
    return state;
  case DASHBOARD_PAYOUTS_PER_MIDS:
    state.payoutsPerMids = action.payload;
    state.payoutsPerMids.socket = action.socket;
    return state;
  case DASHBOARD_WITHDRAWALS_EVENT:
    state.withdrawals = action.payload;
    state.withdrawals.socket = action.socket;
    return state;
  case DASHBOARD_PAYOUT_EVENT:
    state.payout = action.payload;
    state.payout.socket = action.socket;
    return state;
  case DASHBOARD_PAYOUT_COUNTRIES_EVENT:
    state.payoutcountry = action.payload;
    state.payoutcountry.socket = action.socket;
    return state;
  case DASHBOARD_AMOUNTS_PER_MID:
    state.amountsPerMid = action.payload;
    state.amountsPerMid.socket = action.socket;
    return state;
  default:
    return state;
  }
}