import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';

import ComponentHeader from "../../componentHeader";
import Multiselect from '../customMultiselect';
import Form from "../../../core/Form";
import Select from "../Select";
import Spinner from '../../Spinner';
import Toggler from '../Toggler';

import '../../../assets/css/pspCascading.css';
import loadingIcon from '../../../assets/images/loading.png';
import pspCascadingIcon from '../../../assets/images/psp-cascading.png';

import icons from '../../icons/methods';
import { FETCH_PERMISSIONS } from '../../../actions/types';
import { crudActions } from "../../../services/crudActions";
const store =  require('../../../reducers/index');

class PspCascading extends Component {
  state = {
    depositMethods: [],
    countries: [],
    currencies: [],
    lookups: {},
    midsPriorities: {},
    errorsMap: {},

    controls: new Form({
      depositMethods: [],
      country: "",
      merchantId: "",
      currency: ""
    }, [{
      name: "depositMethods",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "country",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "currency",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "merchantId",
      type: "isString",
      rules: {
        required: true
      }
    }]),

    merchants: [],
    accounts: [],

    prioritize: "manual",

    loadingLookups: true,
    submitTouched: false,
    saveTouched: false,
    loadingSettings: false,

    access: [],
    toggleLoader: []
  };

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get("v1/clients/all")
    ]).then(
      (data) => {
        const lookups = data[0];
        const clientsData = data[1];
        if (lookups && clientsData) {
          this.setState({
            loadingLookups: false,
            lookups: lookups,
            merchants: clientsData.clients.map(currElem => {
              return {
                label: currElem.client.label,
                value: currElem.client.value,
                mids: currElem.mids,
                payoutMids: currElem.payoutMids
              };
            }),
            accounts: clientsData.accounts || [],
            controls: Object.assign(this.state.controls, {
              merchantId: clientsData.clients ? clientsData.clients[0].client.value : "",
              currency: "",
              country: "default"
            })
          }, () => {
            this.loadData();
          });
        }
      }
    );
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  mapMethods = () => {
    const { controls, depositMethods } = this.state;
    const field = controls["depositMethods"];
    return field.map(elem => {
      return depositMethods.find(lookupElem => elem === lookupElem.value);
    });
  };

  onValueChange = (newValue, fieldName) => {
    let { controls } = this.state;
    const { submitTouched } = this.state;
    if (newValue.value) {
      controls = Object.assign(controls, {
        [fieldName]: newValue.value
      });
    } else if (newValue.target) {
      controls = Object.assign(controls, {
        [fieldName]: newValue.target.value
      });
    } else {
      controls = Object.assign(controls, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    if (fieldName === "merchantId") {
      const merchantCurrencies = this.getUniqueCurrencies(controls.merchantId);
      if (merchantCurrencies.length === 1) {
        controls.currency = merchantCurrencies[0].value;
      } else {
        controls.currency = "";
      }
    }

    if (submitTouched) {
      controls.isFormValid();
    }

    this.setState({ controls });
  };

  mapItems = (fieldValue, arrayName) => {
    if (!arrayName) {
      return {
        label: fieldValue
      };
    }

    const { lookups } = this.state;
    const foundArray = lookups[arrayName];

    if (!foundArray) {
      return {
        label: ""
      };
    }

    const item = foundArray.find(elem => elem.value === fieldValue);

    return item;
  };

  getUniqueCurrencies = (merchantId) => {
    const { merchants, currencies } = this.state;
    const selectedMerchant = merchants.find(elem => elem.value === merchantId);
    if (selectedMerchant) {
      const selectedMerchantMids = selectedMerchant.mids;
      if (selectedMerchantMids.length) {
        const midsCurrencies = selectedMerchantMids.map(elem => elem.currency);
        const uniqueCurrencies = [...new Set(midsCurrencies)];
        return currencies.filter(elem => uniqueCurrencies.indexOf(elem.value) > -1);
      }
    }
    return currencies;
  }

  loadData = () => {
    const { controls } = this.state;
    crudActions.get(`v1/adminpanel`).then(
      (data) => {
        if (data) {
          const countries = (data.storedLookups.country || []).map(elem => this.mapItems(elem, "country"));
          countries.unshift({
            label: "Default",
            value: "default",
            prevent_sorting: true
          });
          const depositMethods = (data.storedLookups.paymentMethods || []).map(elem => this.mapItems(elem, "buyMethods"));
          const currencies = (data.storedLookups.currency || []).map(elem => this.mapItems(elem, "currency"));

          this.setState({
            countries,
            currencies,
            depositMethods,
            controls: Object.assign(this.state.controls, {
              depositMethods: depositMethods.map(elem => elem.value),
              country: "default"
            })
          }, () => {
            const merchantCurrencies = this.getUniqueCurrencies(controls.merchantId);
            if (merchantCurrencies.length === 1) {
              this.setState({
                controls: Object.assign(this.state.controls, {
                  currency: merchantCurrencies[0].value
                })
              });
            }
          });
        }
      }
    );
  };

  onUpdateClick = () => {
    const { controls, prioritize } = this.state;
    const areControlsValid = controls.isFormValid();
    this.setState({
      controls,
      submitTouched: true,
      showPanels: areControlsValid,
      loadingSettings: areControlsValid
    });

    if (areControlsValid) {
      crudActions.get("v1/clients/all")
        .then(response => {
          if (response) {
            this.setState({
              merchants: response.clients.map(currElem => {
                return {
                  label: currElem.client.label,
                  value: currElem.client.value,
                  mids: currElem.mids,
                  payoutMids: currElem.payoutMids
                };
              }),
              accounts: response.accounts || [],
            });
          }
        })
        .catch(
          err => {
            if (err && err.message) {
              this.setState({
                errorMessage: err.message,
                showError: true
              });
            }
          }
        );
      crudActions.post(`v1/psp/priorities`, controls.data()).then(
        (data) => {
          if (data) {
            const midsPriorities = data.midsPriorities;
            Object.keys(midsPriorities).forEach((method) => {
              midsPriorities[method] = midsPriorities[method].map((elem, index) => {
                return Object.assign(elem, {
                  priority: elem.priority || ""
                });
              });
            });

            this.setState({
              midsPriorities,
              showPanels: true,
              loadingSettings: false
            }, () => this.handlePrioritizeChange(prioritize));
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true,
              showPanels: false,
              loadingSettings: false
            });
          }
        }
      );
    }
  };

  handlePrioritizeChange = (value) => {
    const { midsPriorities } = this.state;

    const sortMids = (property, toReverse) => {
      Object.keys(midsPriorities).forEach(method => {
        midsPriorities[method].sort((elemA, elemB) => {
          const firstElemProp = elemA[property];
          const secondElemProp= elemB[property];
          if (firstElemProp < secondElemProp) {
            return -1;
          } else if (firstElemProp > secondElemProp || !firstElemProp || !secondElemProp) {
            return 1;
          }

          return 0;
        });

        if (toReverse) {
          midsPriorities[method].reverse();
        }

        if (property !== "priority") {
          midsPriorities[method].forEach((elem, index) => {
            if (elem.totalLoad !== elem.maxDailyLoad) {
              elem.priority = index + 1;
            }
          });
        }
      });
    };

    if (value === "manual") {
      sortMids("priority");
    } else if (value === "success") {
      sortMids("successRate", true);
    } else if (value === "response") {
      sortMids("responseTime");
    }

    this.setState({
      midsPriorities,
      prioritize: value
    }, () => this.errorsCheck());
  };

  prioritiesList = (length) => {
    const priorities = [];
    for (let i = 1; i <= length; i++) {
      priorities.push({
        value: i,
        label: i.toString()
      });
    }

    return priorities;
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: ""
    });
  };

  onPriorityValueChange = (value, settingName, midId) => {
    const { midsPriorities, prioritize } = this.state;
    const setting = midsPriorities[settingName];
    const mid = setting.find(elem => elem.midId === midId);
    mid.priority = value.value;

    this.setState({
      midsPriorities
    }, () => {
      this.handlePrioritizeChange(prioritize);
    });
  };

  onTogglerClick = (value, settingName, midId) => {
    const { toggleLoader } = this.state;
    if (midId) {
      toggleLoader.push(midId);
      this.setState({
        toggleLoader
      });
      crudActions.patch(`v1/mids/update-availability`, {
        enabled: value,
        type: "deposit",
        id: midId
      }).then(() => {
        const { midsPriorities } = this.state;
        const setting = midsPriorities[settingName];
        const mid = setting.find(elem => elem.midId === midId);
        mid.midEnabled = value;

        this.setState({
          midsPriorities,
          toggleLoader: this.state.toggleLoader.filter(i => i !== midId)
        });
      }).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true,
              toggleLoader: this.state.toggleLoader.filter(i => i !== midId)
            });
          }
        }
      );
    }
  }

  getLoadStatus = (totalLoad, maxDailyLoad) => {
    if (!totalLoad || !maxDailyLoad) {
      return 0;
    } else if (totalLoad > maxDailyLoad) {
      return 100;
    }

    return Math.round((totalLoad / maxDailyLoad) * 100);
  };

  errorsCheck = () => {
    const { midsPriorities, errorsMap } = this.state;

    let isErrors = false;
    Object.keys(midsPriorities).forEach(method => {
      const mids = midsPriorities[method];
      mids.forEach(mid => {
        const error = !mid.priority;
        isErrors = error;
        errorsMap[mid.midId] = error;
      });
    });

    this.setState({
      errorsMap
    });

    return isErrors;
  }

  onSavePriorities = () => {
    const { midsPriorities } = this.state;

    const isErrors = this.errorsCheck();
    this.setState({
      saveTouched: true
    });

    if (!isErrors) {
      const combinedMids = Object.keys(midsPriorities).reduce((accumulator, currentElem) => {
        return accumulator.concat(midsPriorities[currentElem]);
      }, []);

      crudActions.post(`v1/psp/priorities/set`, combinedMids).then(
        () => {
          this.setState({
            showSuccess: true
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true
            });
          }
        }
      );
    } else {
      this.setState({
        showError: true,
        errorMessage: "Some priorities are invalid, please, fix them."
      });
    }
  };

  sortMidsPriorities = () => {
    const {  midsPriorities } = this.state;
    const methods = Object.keys(midsPriorities);

    methods.sort((elemA, elemB) => {
      const modifiedA = elemA.replace(/_/g, " ");
      const modifiedB = elemB.replace(/_/g, " ");

      return modifiedA.localeCompare(modifiedB);
    });
    return methods;
  };

  isDisabledAccount = (accountId) => {
    const { accounts } = this.state;
    if (accounts) {
      const account = accounts.find(acc => acc.id === accountId);
      if (account) {
        return !account.enabled;
      }
    }
    return false;
  }

  render() {
    const { loadingLookups, depositMethods, countries, controls, showPanels, merchants,
      loadingSettings, prioritize, midsPriorities, showSuccess, showError, errorMessage, errorsMap,
      saveTouched, toggleLoader } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module psp-cascading" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Cascading"
            img={ pspCascadingIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%' } }>
              { loadingLookups ? (<Panel>
                <Panel.Heading>
                  <Panel.Title> FILTER </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div>
                </Panel.Body>
              </Panel>) :
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      FILTER
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <label> Merchant </label>
                          <Select
                            className={ controls.errors.has('merchantId') ? 'error-field' : "" }
                            value={ controls.merchantId || '' }
                            required={ true }
                            disabled={ false }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'merchantId') }
                            options={ merchants }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Select
                            className={ controls.errors.has('currency') ? 'error-field' : "" }
                            value={ controls.currency || '' }
                            required={ true }
                            disabled={ !controls.merchantId }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'currency') }
                            options={ this.getUniqueCurrencies(controls.merchantId) }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payment Methods </label>
                          <Multiselect
                            isError={ controls.errors.has('depositMethods') }
                            disabled={ false }
                            selectedItems={ this.mapMethods() }
                            items={ depositMethods }
                            type={ "depositMethods" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Country </label>
                          <Select
                            className={ controls.errors.has('country') ? 'error-field' : "" }
                            value={ controls.country || '' }
                            required={ true }
                            disabled={ false }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'country') }
                            options={ countries }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <Button
                            type="submit"
                            style={ { outline: "0" } }
                            className={ "btn defaultBtn" }
                            onClick={ () => this.onUpdateClick() }>
                            UPDATE
                          </Button>
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              }
              { showPanels && <Panel>
                <Panel.Heading>
                  <Panel.Title> PROVIDER PRIORITY </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { loadingSettings ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    { this.checkPageAccess("PSP_CASCADING_EDIT") && <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> PRIORITIZE </label>
                          <Row flexGrow={ 1 } wrap={ true } style={ { width: '100%' } }>
                            <p style={ { margin: "10px 10px 10px 0px" } }>
                              <input
                                className="psp-radio"
                                type="radio"
                                id="always"
                                name="radio-group"
                                checked={ prioritize === "manual" }
                                onChange={ () => this.handlePrioritizeChange("manual") }/>
                              <label htmlFor="always"> By Manual Prioritization </label>
                            </p>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column">
                          <p style={ { margin: "10px 10px 10px 0px" } }>
                            <input
                              className="psp-radio"
                              type="radio"
                              id="response"
                              name="radio-group"
                              checked={ prioritize === "response" }
                              onChange={ () => this.handlePrioritizeChange("response") }/>
                            <label htmlFor="response"> By Response Time </label>
                          </p>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column">
                          <p style={ { margin: "10px 10px 10px 0px" } }>
                            <input
                              className="psp-radio"
                              type="radio"
                              id="success"
                              name="radio-group"
                              checked={ prioritize === "success" }
                              onChange={ () => this.handlePrioritizeChange("success") }/>
                            <label htmlFor="success"> By Success Rate </label>
                          </p>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column">
                          <Button
                            type="submit"
                            className="btn defaultBtn"
                            onClick={ () => this.onSavePriorities() }>
                              SAVE
                          </Button>
                        </Column>
                      </Row>
                    </Row> }
                    {
                      midsPriorities && <Column flexGrow={ 1 } vertical='start' style={ {width: '100%'} }>
                        {
                          this.sortMidsPriorities().map(method => {
                            return <Column key={ method } flexGrow={ 1 } vertical='start' style={ { marginBottom: "20px" } }>
                              <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' className="icon-div">
                                <img src={ icons[method] } alt="method"/>
                              </Row>
                              <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' className="mid-priority-wrapper">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th className="td-priority">
                                        Priority
                                      </th>
                                      <th>
                                        MID name
                                      </th>
                                      <th>
                                        MID ID
                                      </th>
                                      <th>
                                        Provider
                                      </th>
                                      <th>
                                        MID TZ
                                      </th>
                                      <th className="text-right">
                                        Max. Daily Load
                                      </th>
                                      <th className="text-right">
                                        Successful Load
                                      </th>
                                      <th className="text-right">
                                        Total Load
                                      </th>
                                      <th className="text-center">
                                        Load status
                                      </th>
                                      <th className="text-right">
                                        Success Rate
                                      </th>
                                      <th className="text-right">
                                        Response Time
                                      </th>
                                      <th className="text-right">
                                        MID Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      midsPriorities[method].map((mid) => {
                                        return <tr key={ mid.midId }>
                                          <td className="td-priority">
                                            <Select
                                              value={ mid.priority }
                                              required={ true }
                                              clearable={ false }
                                              className={ errorsMap[mid.midId] && saveTouched ? "error-field" : "" }
                                              disabled={ prioritize === "response" || prioritize === "success" ||
                                                          mid.totalLoad === mid.maxDailyLoad || !this.checkPageAccess("PSP_CASCADING_EDIT") }
                                              onChange={ (value) => this.onPriorityValueChange(value, method, mid.midId) }
                                              options={ this.prioritiesList(midsPriorities[method].length) }
                                            />
                                          </td>
                                          <td>
                                            {mid.midName}
                                          </td>
                                          <td>
                                            {mid.midId}
                                          </td>
                                          <td>
                                            {mid.psp}
                                          </td>
                                          <td>
                                            {mid.timeZone}
                                          </td>
                                          <td className="text-right">
                                            {mid.maxDailyLoad ? mid.currencySymbol + " " + Number(mid.maxDailyLoad).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "Unlimited"}
                                          </td>
                                          <td className="text-right">
                                            {mid.currencySymbol + " " + Number(mid.successfulLoad).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                          </td>
                                          <td className="text-right">
                                            {mid.currencySymbol + " " + Number(mid.totalLoad).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                          </td>
                                          <td>
                                            {mid.totalLoad === mid.maxDailyLoad ? <Row style={ {width: '100%'} } flexGrow={ 1 }>
                                              <span className="mid-warning"> MAX DAILY LOAD HAS BEEN REACHED  </span>
                                            </Row> : !mid.workingNow ? <Row style={ {width: '100%'} } flexGrow={ 1 }>
                                              <span className="mid-warning"> OFFLINE DUE TO HOLIDAY  </span>
                                            </Row> : <Row className="tooltips" style={ {width: '100%'} } flexGrow={ 1 }>
                                              <span style={ { left: this.getLoadStatus(mid.totalLoad, mid.maxDailyLoad) + '%' } }>
                                                { this.getLoadStatus(mid.totalLoad, mid.maxDailyLoad) + "%" }
                                              </span>
                                              <div id="myProgress">
                                                <div id="myBar" style={ {width: (100 - this.getLoadStatus(mid.totalLoad, mid.maxDailyLoad)) + '%'} }
                                                  className={ this.getLoadStatus(mid.totalLoad, mid.maxDailyLoad) !== 0 ? 'noRadius' : '' }>
                                                </div>
                                              </div>
                                            </Row> }
                                          </td>
                                          <td className="text-right">
                                            {Number(mid.successRate).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,') + "%"}
                                          </td>
                                          <td className="text-right">
                                            {Number(mid.responseTime).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,') + "s"}
                                          </td>
                                          <td className={ `text-${toggleLoader.length && toggleLoader.indexOf(mid.midId) > -1 ? 'center' : 'right'}` }>
                                            {toggleLoader.length && toggleLoader.indexOf(mid.midId) > -1 ?
                                              <img src={ loadingIcon } alt="loading" />
                                              : (
                                                <Toggler
                                                  active={ mid.midEnabled }
                                                  id={ mid.midId }
                                                  className="right-align"
                                                  disabled={ !this.checkPageAccess("MIDS_ON_OFF_STATUS") || this.isDisabledAccount(mid.accountId) }
                                                  onClick={ (value) => this.onTogglerClick(value, method, mid.midId) }
                                                />
                                              )}
                                          </td>
                                        </tr>;
                                      })
                                    }
                                  </tbody>
                                </table>
                              </Row>
                            </Column>;
                          })
                        }
                      </Column>
                    }
                  </div> }
                </Panel.Body>
              </Panel>
              }
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ `Priorities successfully updated` }
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#25282a"
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default PspCascading;
