import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Datetime from 'react-datetime';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';

import Form from "../../../core/Form";
import Multiselect from "../customMultiselect";
import PromptModal from '../generateReport/PromptModal';
import Select from "../Select";
import Spinner from '../../Spinner';
import Toggler from '../Toggler';

import '../../../assets/css/mids.css';
import MidsIcon from '../../../assets/images/mids.png';

import { crudActions } from "../../../services/crudActions";
const store = require('../../../reducers/index');

class ManageMid extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  };

  node = null;
  burgerNode = null;
  emailsRef = React.createRef();
  depositStatusToggleRef = React.createRef();
  payoutStatusToggleRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    this.loadData();
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  getInitialState = () => {
    const initialState = {
      midForm: new Form({
        id: null,
        merchants: null,
        midName: "",
        midUsage: "single",

        category: "",
        currency: "",
        countries: [],
        timeZone: "",
        
        weekDaysSchedule: [],
        bankHolidaysSchedule: [],
        supportedCardTypes: [],
        
        dailyTransactions: null,
        weeklyTransactions: null,
        dailyVolume: null,
        weeklyVolume: null,
      }, [{
        name: "merchants",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "midName",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "category",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "countries",
        type: "isArray",
        rules: {
          required: true,
          min: 0
        }
      }, {
        name: "currency",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "timeZone",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "weekDaysSchedule",
        type: "isArray",
        rules: {
          required: false
        }
      }, {
        name: "bankHolidaysSchedule",
        type: "isArray",
        rules: {
          required: false
        }
      }, {
        name: "supportedCardTypes",
        type: "isArray",
        rules: {
          required: true,
          min: 0
        }
      }, {
        name: "dailyTransactions",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "weeklyTransactions",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "dailyVolume",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "weeklyVolume",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }]),

      depositForm: new Form({
        pspId: "",
        accountId: "",
        paymentMethod: "",
        mode: "LIVE",
        enabled: false,

        minAmount: "",
        maxAmount: "",
        maxDailyLoad: "",

        rounding: "",
        updateAmount: "",
        username: "",
        password: ""
      }, [{
        name: "pspId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "accountId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "paymentMethod",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "mode",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "minAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "maxAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "maxDailyLoad",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "rounding",
        type: "isNumber",
        rules: {
          min: 1,
          required: false
        }
      }, {
        name: "updateAmount",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "username",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "password",
        type: "isString",
        rules: {
          required: false
        }
      }]),

      payoutForm: new Form({
        pspId: "",
        accountId: "",
        paymentMethod: "",
        mode: "LIVE",
        enabled: false,

        minAmount: "",
        maxAmount: "",
        payoutWalletType: "",

        username: "",
        password: ""
      }, [{
        name: "pspId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "accountId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "paymentMethod",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "mode",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "minAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "maxAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "payoutWalletType",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "username",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "password",
        type: "isString",
        rules: {
          required: false
        }
      }]),

      midSecurity: new Form({
        maxSameNamePerHour: null,
        maxSamePostalPerHour: null,
        maxSameEmailPerHour: null,
        maxNumberFailedTx: null,
        maxPendingPerCardPerPeriod: null,
        maxDeclinedPerCardPerPeriod: null,
        emails: []
      }, [{
        name: "maxSameNamePerHour",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxSamePostalPerHour",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxSameEmailPerHour",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxNumberFailedTx",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxPendingPerCardPerPeriod",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxDeclinedPerCardPerPeriod",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "emails",
        type: "isArray",
        rules: {
          required: false,
          min: 0
        }
      }]),

      merchants: null,
      buyMethods: [],
      payoutMethods: [],
      payoutWalletTypes: [],
      timezones: [],
      cardTypes: [],
      providers: [],
      accounts: [],
      pspUrlsData: [],

      foundMid: {},
      lookups: {},
      selectedLookups: {},
      isMidSecurityEnabled: false,
      testValuesPermitted: false,

      temporaryEmail: "",
      liveStatus: "always", //custom
      holidayControl: {
        holidayName: "",
        from: this.getPeriod(0).from.format("DD.MM.YYYY"),
        to: this.getPeriod(0).to.format("DD.MM.YYYY")
      },

      holidaysNewRowClicked: false,
      holidayErrors: {
        holidayName: false,
        from: false,
        to: false
      },

      timeStamp: {
        fromDateTimeStamp: this.getPeriod(0).from,
        toDateTimeStamp: this.getPeriod(0).to
      },

      weekDaysSchedule: [{
        dayName: "MONDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "TUESDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "WEDNESDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "THURSDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "FRIDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "SATURDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "SUNDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }],

      midsLinks: [{
        name: "ADD MIDs",
        url: "/add-mid",
      }],

      removeEmptyField: false,

      showSuccess: false,
      showError: false,
      errorMessage: "",
      redirectToMids: false,
      isLoading: true,
      submitTouched: false,
      isBurgerOpen: false,
      mode: "",
      showPromptModal: false,
      editableField: "",
      editableFieldValue: null,
      editableForm: ""
    };

    return initialState;
  }

  loadData = () => {
    const { midId } = this.props;
    const { midForm, depositForm, payoutForm, midSecurity } = this.state;

    Promise.all([
      (crudActions.get('v1/adminpanel/lookups')),
      (midId ? crudActions.get(`v1/mids/${midId}`) : Promise.resolve()),
      crudActions.get("v1/mids/timezones"),
      crudActions.get("v1/clients/all"),
      crudActions.get("v1/mids/card-types")
    ]).then(
      (data) => {
        const lookups = data[0];
        const foundMid = data[1];
        const timezones = data[2];
        const clientData = data[3];
        const cardTypes = data[4];
        let updateObject = {};

        if (lookups && timezones && clientData) {
          crudActions.get(`v1/adminpanel`).then(
            (dropdowns) => {
              if (foundMid) {
                updateObject = {
                  lookups: lookups,
                  foundMid,
                  selectedLookups: dropdowns.storedLookups,
                  midForm: Object.assign(midForm, foundMid, {
                    midUsage: "single"
                  }),
                  depositForm: Object.assign(depositForm, foundMid.depositSettings, {
                    mode: !foundMid ? lookups.pspUrlTypes[1].value : foundMid.depositSettings.mode ? foundMid.depositSettings.mode : "LIVE",
                    updateAmount: foundMid.updateAmount ? "final" : "initial"
                  }),
                  payoutForm: Object.assign(payoutForm, foundMid.payoutSettings, {
                    mode: !foundMid ? lookups.pspUrlTypes[1].value : foundMid.payoutSettings.mode ? foundMid.payoutSettings.mode : "LIVE"
                  }),
                  midSecurity: Object.assign(midSecurity, foundMid.midsSecurityDTO),
                  isMidSecurityEnabled: foundMid.midsSecurityDTO.enabled,
                  testValuesPermitted: foundMid.midsSecurityDTO.withTest,
                  isLoading: false,
                  liveStatus: foundMid.weekDaysSchedule.length !== 0 ? "custom" : "always"
                };

                if (foundMid.weekDaysSchedule.length) {
                  updateObject.weekDaysSchedule = foundMid.weekDaysSchedule;
                }
              } else {
                updateObject = {
                  lookups: lookups,
                  selectedLookups: dropdowns.storedLookups,
                  isLoading: false
                };
              }

              updateObject.timezones = timezones;

              updateObject = Object.assign(updateObject, {
                merchants: clientData.clients.map(elem => {
                  return {
                    value: parseInt(elem.client.value, 10),
                    label: elem.client.label
                  };
                }),
                providers: clientData.psps.map(psp => {
                  return {
                    value: parseInt(psp.value, 10),
                    label: psp.label,
                    buyMethods: psp.buyMethods,
                    payoutMethods: psp.payoutMethods,
                    shortName: psp.shortName
                  };
                }),
                accounts: clientData.accounts.map(account => {
                  return {
                    value: parseInt(account.id, 10),
                    label: account.label,
                    pspId: account.pspId,
                    enabled: account.enabled
                  };
                }),
                pspUrlsData: lookups.pspUrlTypes.map(pspUrlTypes => {
                  return {
                    value: pspUrlTypes.value,
                    label: pspUrlTypes.label
                  };
                }),
                cardTypes: cardTypes || [],
                payoutWalletTypes: lookups.payoutWalletTypes || []
              });

              this.setState(updateObject);
            }
          );
        }
      }
    );
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  }

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  mapSelectedItems = (propertyName, lookupsName) => {
    const field = this.state.midForm[propertyName];
    let lookup = this.state.lookups[lookupsName];
    if (!lookup) {
      lookup = this.state[lookupsName];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapMerchants = () => {
    const { midForm, merchants } = this.state;
    const field = midForm["merchants"];
    return field.map(elem => {
      return merchants.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapMethods = (methodType, form) => {
    const { selectedLookups, lookups, providers } = this.state;
    const currentForm = this.state[form];

    const paymentMethodTypeMap = {
      "PAYOUT": "payoutMethods",
      "BUY": "paymentMethods"
    };

    const paymentMethodLookupsMap = {
      "PAYOUT": "payoutMethods",
      "BUY": "buyMethods"
    };

    const methods = (selectedLookups[paymentMethodTypeMap[methodType]] || []).map(
      field => {
        const selectedProvider = providers.find(item => item.value === currentForm.pspId);
        if (selectedProvider && selectedProvider[paymentMethodLookupsMap[methodType]].includes(field)) {
          return lookups[paymentMethodLookupsMap[methodType]].find(elem => elem.value === field);
        }
        return {};
      }
    );

    const filtered = methods.filter(item => item.value);
    return filtered.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
  };

  mapCountries = (methodType) => {
    const countriesMethodTypeMap = {
      "BUY": "country",
      "PAYOUT": "payoutCountries"
    };

    return countriesMethodTypeMap[methodType];
  };

  filterAvailableAccounts = (formId) => {
    const { accounts } = this.state;
    const currentForm = this.state[formId];
    return accounts.filter(item => item && item.pspId === currentForm.pspId);
  };

  formLookups = (propertyName, oneByOne, lookupsPropertyName) => {
    const { selectedLookups, lookups } = this.state;
    if (!selectedLookups[propertyName]) {
      return [];
    } else if (oneByOne) {
      return selectedLookups[propertyName].map(elem => {
        return {
          label: elem,
          value: elem
        };
      });
    }

    return selectedLookups[propertyName].map(elem => {
      return lookups[lookupsPropertyName].find(lookup => lookup.value === elem);
    });
  };

  isFieldDisabled = (fieldName) => {
    const viewOrEdit = this.props.viewOrEdit;

    if (!viewOrEdit) {
      return false;
    }

    return viewOrEdit === 1;
  };

  isAccountEnabled = (formId) => {
    const { accounts } = this.state;
    const currentForm = this.state[formId];

    const selectedAccount = accounts &&
      accounts.length > 0 &&
      accounts.find(acc => acc.value === currentForm.accountId);
    return selectedAccount && selectedAccount.enabled;
  }

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day'),
        to: moment().utc().subtract(1, 'day')
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1),
        to: moment().utc()
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth())
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1),
        to: moment().utc()
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc(),
        to: moment().utc()
      };
    }
  };

  onValueChange = (newValue, form, fieldName) => {
    let currentForm = this.state[form];
    const { liveStatus, midForm, submitTouched } = this.state;
    const isNumber = currentForm.fieldRules.find(rule => rule.name === fieldName).type === "isNumber";
    if (!newValue) {
      currentForm[fieldName] = newValue;
    } else if (newValue.value) {
      if ((fieldName === "pspId" || fieldName === "accountId" || fieldName === "paymentMethod") && midForm.id && currentForm[fieldName]) {
        this.setState({
          showPromptModal: true,
          editableField: fieldName,
          editableFieldValue: newValue,
          editableForm: form
        });
        return;
      } else {
        currentForm = Object.assign(currentForm, {
          [fieldName]: isNumber && !isNaN(parseFloat(newValue.value)) ? parseFloat(newValue.value) : newValue.value
        });
      }
    } else if (newValue.target) {
      currentForm = Object.assign(currentForm, {
        [fieldName]: isNumber && !isNaN(parseFloat(newValue.target.value)) ? parseFloat(newValue.target.value) : newValue.target.value
      });
    } else {
      currentForm = Object.assign(currentForm, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    if (fieldName === "maxDailyLoad") {
      const fieldRule = midForm.fieldRules.find(rule => rule.name === "timeZone");
      fieldRule.rules.required = currentForm.maxDailyLoad > 0 || liveStatus === "custom";
    } else if (fieldName === "pspId" && form === "payoutForm") {
      currentForm = Object.assign(currentForm, {
        accountId: "",
        paymentMethod: ""
      });
    }

    if (submitTouched) {
      currentForm.isFormValid();
    }

    this.setState({ [form]: currentForm });
  };

  handleConfirmPromptModal = () => {
    const { editableField, editableFieldValue, editableForm, submitTouched } = this.state;
    let currentForm = this.state[editableForm];

    const isNumber = currentForm.fieldRules.find(rule => rule.name === editableField).type === "isNumber";

    currentForm = Object.assign(currentForm, {
      [editableField]: isNumber && !isNaN(parseFloat(editableFieldValue.value)) ? parseFloat(editableFieldValue.value) : editableFieldValue.value
    });

    if (editableField === "accountId") {
      const accounts = this.filterAvailableAccounts(editableForm);
      const selectedAccount = accounts.length && editableFieldValue && accounts.find(acc => acc.value === editableFieldValue.value);
      if (selectedAccount) {
        currentForm = Object.assign(currentForm, {
          enabled: selectedAccount.enabled
        });
        if (editableForm === "depositForm") {
          this.depositStatusToggleRef.current.onChangeState(selectedAccount.enabled);
        } else if (editableForm === "payoutForm") {
          this.payoutStatusToggleRef.current.onChangeState(selectedAccount.enabled);
        }
      }
    }

    if (submitTouched) {
      currentForm.isFormValid();
    }

    this.setState({
      [editableForm]: currentForm,
      showPromptModal: false,
      editableField: "",
      editableFieldValue: null,
      editableForm: ""
    });
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      editableField: "",
      editableFieldValue: null,
      editableForm: ""
    });
  };

  checkIsVaderPay = (pspId) => {
    const { providers } = this.state;
    if (!pspId) {
      return false;
    }
    const selectedProvider = providers.find(psp => psp.value === pspId);
    if (selectedProvider) {
      return selectedProvider.shortName === "VADER_PAY";
    }
    return false;
  };

  onTogglerClick = (value, type) => {
    const { foundMid, midForm } = this.state;
    const currentFormName = `${type}Form`;
    const currentForm = this.state[currentFormName];
    if (!midForm.id || !foundMid.merchants) {
      return this.setState({
        [currentFormName]: Object.assign(currentForm, {
          enabled: value
        })
      });
    }

    crudActions.patch(`v1/mids`, {
      enabled: value,
      type,
      id: midForm.id
    }).then(
      () => {
        this.setState({
          [currentFormName]: Object.assign(currentForm, {
            enabled: value
          })
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            errorMessage: err.message,
            showError: true
          });
        }
      }
    );
  };

  onSubmitMid = () => {
    const {
      depositForm,
      isMidSecurityEnabled,
      midForm,
      midSecurity,
      payoutForm,
      temporaryEmail,
      testValuesPermitted
    } = this.state;

    const isMidFormValid = midForm.isFormValid();
    const isDepositFormValid = depositForm.isFormValid();
    const isPayoutFormValid = payoutForm.isFormFieldValid("pspId", null, false) &&
      payoutForm.isFormFieldValid("accountId", null, false) &&
      payoutForm.isFormFieldValid("paymentMethod", null, false) &&
      payoutForm.isFormFieldValid("mode", null, false) &&
      payoutForm.isFormFieldValid("minAmount", null, false) &&
      payoutForm.isFormFieldValid("maxAmount", null, false) &&
      payoutForm.isFormFieldValid("payoutWalletType", null, false);
    const isMidSecurityFormValid = midSecurity.isFormValid();

    this.setState({
      midForm,
      depositForm,
      isLoading: true,
      submitTouched: true,
      removeEmptyField: true
    });

    if (isMidFormValid && isDepositFormValid && isMidSecurityFormValid) {
      const data = midForm.data();
      let depositFormData = depositForm.data();
      const payoutFormData = payoutForm.data();
      depositFormData = {
        ...depositFormData,
        updateAmount: depositFormData.updateAmount === "final"
      };
      const midSecurityData = midSecurity.data();
      delete data.midUsage;
      delete data.depositSettings;
      delete data.payoutSettings;

      if (temporaryEmail && !midSecurityData.emails.find(email => email === temporaryEmail)) {
        midSecurityData.emails.push(temporaryEmail);
      }

      crudActions.post("v1/mids", Object.assign(data, {
        depositSettings: depositFormData,
        ...(isPayoutFormValid && { payoutSettings: payoutFormData }),
        midsSecurityDTO: Object.assign(midSecurityData, {
          enabled: isMidSecurityEnabled,
          withTest: testValuesPermitted
        })
      })).then(
        () => {
          this.setState({
            showSuccess: true,
            isLoading: false,
            temporaryEmail: "",
            removeEmptyField: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true,
              isLoading: false
            });
          }

          this.setState({
            removeEmptyField: false
          });
        }
      );
    } else {
      this.setState({
        errorMessage: "Some fields have errors. Please, fix them.",
        showError: true,
        isLoading: false
      });
    }
  };

  onCloneMid = () => {
    const {
      depositForm,
      isMidSecurityEnabled,
      midForm,
      midSecurity,
      payoutForm,
      temporaryEmail,
      testValuesPermitted
    } = this.state;

    const isMidFormValid = midForm.isFormValid();
    const isDepositFormValid = depositForm.isFormValid();
    const isPayoutFormValid = payoutForm.isFormFieldValid("pspId", null, false) &&
      payoutForm.isFormFieldValid("accountId", null, false) &&
      payoutForm.isFormFieldValid("paymentMethod", null, false) &&
      payoutForm.isFormFieldValid("mode", null, false) &&
      payoutForm.isFormFieldValid("minAmount", null, false) &&
      payoutForm.isFormFieldValid("maxAmount", null, false) &&
      payoutForm.isFormFieldValid("payoutWalletType", null, false);
    const isMidSecurityFormValid = midSecurity.isFormValid();

    this.setState({
      midForm,
      depositForm,
      isLoading: true
    });

    if (isMidFormValid && isDepositFormValid && isMidSecurityFormValid) {
      const data = midForm.data();
      let depositFormData = depositForm.data();
      const payoutFormData = payoutForm.data();
      depositFormData = {
        ...depositFormData,
        updateAmount: data.updateAmount === "final"
      };
      const midSecurityData = midSecurity.data();
      delete data.midUsage;
      delete data.depositSettings;
      delete data.payoutSettings;

      if (temporaryEmail && !midSecurityData.emails.find(email => email === temporaryEmail)) {
        midSecurityData.emails.push(temporaryEmail);
      }
      
      crudActions.post("v1/mids/clone", Object.assign(data, {
        depositSettings: depositFormData,
        ...(isPayoutFormValid && { payoutSettings: payoutFormData }),
        midsSecurityDTO: Object.assign(midSecurityData, {
          enabled: isMidSecurityEnabled,
          withTest: testValuesPermitted
        })
      })).then(
        (mid) => {
          window.location.href = `/edit-mid/${mid.id}`;
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true,
              isLoading: false
            });
          }
        }
      );
    } else {
      this.setState({
        errorMessage: "Some fields have errors. Please, fix them.",
        showError: true,
        isLoading: false
      });
    }
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      redirectToMids: this.state.showSuccess
    });
  };

  render() {
    const {
      cardTypes,
      depositForm,
      errorMessage,
      isBurgerOpen,
      isLoading,
      merchants,
      midForm,
      midsLinks,
      payoutForm,
      payoutWalletTypes,
      providers,
      pspUrlsData,
      redirectToMids,
      showError,
      showPromptModal,        
      showSuccess,
      timezones
    } = this.state;

    const isFieldDisabled = this.isFieldDisabled();

    const isVaderPay = this.checkIsVaderPay(depositForm.pspId);

    if (redirectToMids) {
      return <Redirect to='/mids' />;
    }

    return (
      <Row flexGrow={ 1 } className="t365 module mids" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MidsIcon } alt="" style={ { marginRight: 10 } } />
                MIDs
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink to={ `/add-mid` } className="btn add-deposit-button">
                      ADD MIDs
                    </NavLink>
                    <div ref={ node => this.burgerNode = node } className={ "burger-container " + (isBurgerOpen ? "change" : "") } style={ { float: "right" } } onClick={ this.onBurgerClick }>
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    {isBurgerOpen && (
                      <div ref={ node => this.node = node } className="burger-content">
                        {midsLinks.map((link, i) => {
                          return (
                            <div key={ i } className="link-item-container">
                              <NavLink
                                to={ link.url }
                                className="link-item"
                                onClick={ () => this.handleClickOutside() }
                              >
                                {link.name}
                              </NavLink>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> MIDs </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      MIDs
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Merchant </label>
                          <Select
                            value={ midForm.merchants || '' }
                            className={ midForm.errors.has('merchants') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'merchants') }
                            options={ merchants }
                          />
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> MID name </label>
                          {midForm.midName && <ReactTooltip effect='solid' type={ 'light' } place={ 'top' } border={ true } getContent={ (dataTip) => <h5>{dataTip}</h5> } />}
                          <div data-tip={ midForm.midName ? midForm.midName : null }>
                            <input
                              className={ "form-control " + (midForm.errors.has('midName') ? 'error-field' : "") }
                              value={ midForm.midName || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'midForm', 'midName') } />
                            {midForm.errors.has('midName') && <span className="error-message"> {midForm.errors.get("midName")}  </span>}
                          </div>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Category </label>
                          <Select
                            value={ midForm.category || '' }
                            className={ midForm.errors.has('category') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'category') }
                            options={ this.formLookups("midCategories", true) }
                          />
                          {midForm.errors.has('category') && <span className="error-message"> {midForm.errors.get("category")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Select
                            value={ midForm.currency || '' }
                            className={ midForm.errors.has('currency') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'currency') }
                            options={ this.formLookups("currency", false, "currency") }
                          />
                          {midForm.errors.has('currency') && <span className="error-message"> {midForm.errors.get("currency")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Country </label>
                          <Multiselect
                            isError={ midForm.errors.has('countries') }
                            selectedItems={ this.mapSelectedItems("countries", "country") }
                            items={ this.formLookups(this.mapCountries("BUY"), false, "country") }
                            disabled={ isFieldDisabled }
                            type={ "countries" }
                            showTooltip={ true }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'countries') } />
                          {midForm.errors.has('countries') && <span className="error-message"> {midForm.errors.get("countries")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Time Zone </label>
                          <Select
                            value={ midForm.timeZone || '' }
                            className={ midForm.errors.has('timeZone') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            enableSort={ false }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'timeZone') }
                            options={ timezones || [] }
                          />
                          {midForm.errors.has('timeZone') && <span className="error-message"> {midForm.errors.get("timeZone")}  </span>}
                        </Column>

                        {midForm.paymentMethod === "CREDIT_CARD" && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Card types </label>
                            <Multiselect
                              isError={ midForm.errors.has('supportedCardTypes') }
                              selectedItems={ this.mapSelectedItems("supportedCardTypes", "cardTypes") }
                              items={ cardTypes }
                              disabled={ isFieldDisabled }
                              type={ "supportedCardTypes" }
                              showTooltip={ true }
                              onChange={ (value) => this.onValueChange(value, 'midForm', 'supportedCardTypes') } />
                          </Column>
                        )}
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> DEPOSIT SETTINGS </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      DEPOSIT SETTINGS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <Select
                            value={ depositForm.pspId || '' }
                            className={ depositForm.errors.has('pspId') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || midForm.successfulDepositExists }
                            onChange={ (value) => this.onValueChange(value, 'depositForm', 'pspId') }
                            options={ providers }
                          />
                          {depositForm.errors.has('pspId') && <span className="error-message"> {depositForm.errors.get("pspId")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Account </label>
                          <Select
                            value={ depositForm.accountId || '' }
                            className={ depositForm.errors.has('accountId') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || midForm.successfulDepositExists }
                            onChange={ (value) => this.onValueChange(value, 'depositForm', 'accountId') }
                            options={ this.filterAvailableAccounts('depositForm') }
                          />
                          {depositForm.errors.has('accountId') && <span className="error-message"> {depositForm.errors.get("accountId")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Deposit Method </label>
                          <Select
                            value={ depositForm.paymentMethod || '' }
                            className={ depositForm.errors.has('paymentMethod') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'depositForm', 'paymentMethod') }
                            options={ this.mapMethods('BUY', 'depositForm') || [] }
                          />
                          {depositForm.errors.has('paymentMethod') && <span className="error-message"> {depositForm.errors.get("paymentMethod")}  </span>}
                        </Column>
                        {store.default.getState().authReducer.roleId === "MASTER_TECH" && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Provider URLs </label>
                            <Select
                              value={ depositForm.mode }
                              className={ depositForm.errors.has('mode') ? 'error-field' : "" }
                              required={ true }
                              clearable={ false }
                              disabled={ isFieldDisabled }
                              onChange={ (value) => this.onValueChange(value, 'depositForm', 'mode') }
                              options={ pspUrlsData }
                            />
                          </Column>
                        )}
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label style={ { marginBottom: "10px" } }>
                            Status
                          </label>
                          <Toggler
                            ref={ this.depositStatusToggleRef }
                            active={ depositForm.enabled }
                            disabled={ isFieldDisabled || !this.isAccountEnabled('depositForm') || !midForm.merchants }
                            value="deposit"
                            onClick={ this.onTogglerClick }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Min Tx Amount </label>
                          <div data-tip={ depositForm.minAmount ? depositForm.minAmount : null }>
                            <input
                              className={ "form-control " + (depositForm.errors.has('minAmount') ? 'error-field' : "") }
                              value={ depositForm.minAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'minAmount') } />
                            {depositForm.errors.has('minAmount') && <span className="error-message"> {depositForm.errors.get("minAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Max Tx Amount </label>
                          <div>
                            <input
                              className={ "form-control " + (depositForm.errors.has('maxAmount') ? 'error-field' : "") }
                              value={ depositForm.maxAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'maxAmount') } />
                            {depositForm.errors.has('maxAmount') && <span className="error-message"> {depositForm.errors.get("maxAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Max Daily Load </label>
                          {!!depositForm.maxDailyLoad && <ReactTooltip effect='solid' type={ 'light' } place={ 'top' } border={ true } getContent={ (dataTip) => <h5>{dataTip}</h5> } />}
                          <div data-tip={ depositForm.maxDailyLoad ? depositForm.maxDailyLoad : null }>
                            <input
                              className={ "form-control " + (depositForm.errors.has('maxDailyLoad') ? 'error-field' : "") }
                              value={ depositForm.maxDailyLoad || '' }
                              type="number"
                              placeholder="Unlimited"
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'maxDailyLoad') } />
                            {depositForm.errors.has('maxDailyLoad') && <span className="error-message"> {depositForm.errors.get("maxDailyLoad")}  </span>}
                          </div>
                        </Column>
                        {isVaderPay && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Update Amount </label>
                            <Select
                              value={ depositForm.updateAmount || '' }
                              className={ depositForm.errors.has('updateAmount') ? 'error-field' : "" }
                              required={ true }
                              clearable={ false }
                              disabled={ isFieldDisabled }
                              enableSort={ false }
                              onChange={ (value) => this.onValueChange(value, 'depositForm', 'updateAmount') }
                              options={ [{ value: 'initial', label: 'Initial amount' }, { value: 'final', label: 'Final amount' }] }
                            />
                          </Column>
                        )}
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Rounding </label>
                          {!!depositForm.rounding && <ReactTooltip effect='solid' type={ 'light' } place={ 'top' } border={ true } getContent={ (dataTip) => <h5>{dataTip}</h5> } />}
                          <div data-tip={ depositForm.rounding ? depositForm.rounding : null }>
                            <input
                              className={ "form-control " + (depositForm.errors.has('rounding') ? 'error-field' : "") }
                              value={ depositForm.rounding || "" }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'rounding') } />
                            {depositForm.errors.has('rounding') && <span className="error-message"> {depositForm.errors.get("rounding")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Username </label>
                          {depositForm.username && <ReactTooltip effect='solid' type={ 'light' } place={ 'top' } border={ true } getContent={ (dataTip) => <h5>{dataTip}</h5> } />}
                          <div data-tip={ depositForm.username ? depositForm.username : null }>
                            <input
                              name="depositN"
                              autoComplete="new-off"
                              className={ "form-control " + (depositForm.errors.has('username') ? 'error-field' : "") }
                              value={ depositForm.username || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'username') } />
                            {depositForm.errors.has('username') && <span className="error-message"> {depositForm.errors.get("username")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Password </label>
                          <div>
                            <input
                              name="depositP"
                              autoComplete="new-password"
                              type="password"
                              className={ "form-control " + (depositForm.errors.has('password') ? 'error-field' : "") }
                              value={ depositForm.password || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'password') } />
                            {depositForm.errors.has('password') && <span className="error-message"> {depositForm.errors.get("password")}  </span>}
                          </div>
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> PAYOUT SETTINGS </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      PAYOUT SETTINGS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <div data-tip={ midForm.pendingPayoutExists ? "Locked due to payouts in progress" : null }>
                            <Select
                              value={ payoutForm.pspId || '' }
                              className={ payoutForm.errors.has('pspId') ? 'error-field' : "" }
                              required={ true }
                              clearable={ true }
                              disabled={ isFieldDisabled || midForm.pendingPayoutExists }
                              onChange={ (value) => this.onValueChange(value, 'payoutForm', 'pspId') }
                              options={ providers }
                            />
                            {payoutForm.errors.has('pspId') && <span className="error-message"> {payoutForm.errors.get("pspId")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Account </label>
                          <div data-tip={ midForm.pendingPayoutExists ? "Locked due to payouts in progress" : null }>
                            <Select
                              value={ payoutForm.accountId || '' }
                              className={ payoutForm.errors.has('accountId') ? 'error-field' : "" }
                              required={ true }
                              clearable={ true }
                              disabled={ isFieldDisabled || midForm.pendingPayoutExists }
                              onChange={ (value) => this.onValueChange(value, 'payoutForm', 'accountId') }
                              options={ this.filterAvailableAccounts('payoutForm') }
                            />
                            {payoutForm.errors.has('accountId') && <span className="error-message"> {payoutForm.errors.get("accountId")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Method </label>
                          <Select
                            value={ payoutForm.paymentMethod || '' }
                            className={ payoutForm.errors.has('paymentMethod') ? 'error-field' : "" }
                            required={ true }
                            clearable={ true }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'payoutForm', 'paymentMethod') }
                            options={ this.mapMethods('PAYOUT', 'payoutForm') || [] }
                          />
                          {payoutForm.errors.has('paymentMethod') && <span className="error-message"> {payoutForm.errors.get("paymentMethod")}  </span>}
                        </Column>
                        {store.default.getState().authReducer.roleId === "MASTER_TECH" && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Provider URLs </label>
                            <Select
                              value={ payoutForm.mode }
                              className={ payoutForm.errors.has('mode') ? 'error-field' : "" }
                              required={ true }
                              clearable={ true }
                              disabled={ isFieldDisabled }
                              onChange={ (value) => this.onValueChange(value, 'payoutForm', 'mode') }
                              options={ pspUrlsData }
                            />
                          </Column>
                        )}
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label style={ { marginBottom: "10px" } }>
                            Status
                          </label>
                          <Toggler
                            ref={ this.payoutStatusToggleRef }
                            active={ payoutForm.enabled }
                            disabled={ isFieldDisabled || !this.isAccountEnabled('payoutForm') || !midForm.merchants }
                            value="payout"
                            onClick={ this.onTogglerClick }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Min Tx Amount </label>
                          <div data-tip={ payoutForm.minAmount ? payoutForm.minAmount : null }>
                            <input
                              className={ "form-control " + (payoutForm.errors.has('minAmount') ? 'error-field' : "") }
                              value={ payoutForm.minAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'payoutForm', 'minAmount') } />
                            {payoutForm.errors.has('minAmount') && <span className="error-message"> {payoutForm.errors.get("minAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Max Tx Amount </label>
                          <div>
                            <input
                              className={ "form-control " + (payoutForm.errors.has('maxAmount') ? 'error-field' : "") }
                              value={ payoutForm.maxAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'payoutForm', 'maxAmount') } />
                            {payoutForm.errors.has('maxAmount') && <span className="error-message"> {payoutForm.errors.get("maxAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Wallet Type </label>
                          <Select
                            value={ payoutForm.payoutWalletType || '' }
                            className={ payoutForm.errors.has('payoutWalletType') ? 'error-field' : "" }
                            required={ true }
                            clearable={ true }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'payoutForm', 'payoutWalletType') }
                            options={ payoutWalletTypes }
                          />
                          {payoutForm.errors.has('payoutWalletType') && <span className="error-message"> {payoutForm.errors.get("payoutWalletType")}  </span>}
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Username </label>
                          {payoutForm.username && <ReactTooltip effect='solid' type={ 'light' } place={ 'top' } border={ true } getContent={ (dataTip) => <h5>{dataTip}</h5> } />}
                          <div data-tip={ payoutForm.username ? payoutForm.username : null }>
                            <input
                              name="payoutN"
                              autoComplete="new-off"
                              className={ "form-control " + (payoutForm.errors.has('username') ? 'error-field' : "") }
                              value={ payoutForm.username || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'payoutForm', 'username') } />
                            {payoutForm.errors.has('username') && <span className="error-message"> {payoutForm.errors.get("username")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Password </label>
                          <div>
                            <input
                              name="payoutP"
                              autoComplete="new-password"
                              type="password"
                              className={ "form-control " + (payoutForm.errors.has('password') ? 'error-field' : "") }
                              value={ payoutForm.password || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'payoutForm', 'password') } />
                            {payoutForm.errors.has('password') && <span className="error-message"> {payoutForm.errors.get("password")}  </span>}
                          </div>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                        <Column flexGrow={ 0 } vertical='end' className="button-block input-column">
                          {!isFieldDisabled && <Button
                            type="submit"
                            className="btn"
                            onClick={ () => this.onCloneMid() }>
                            Clone Mid
                          </Button>}
                        </Column>
                        <Column flexGrow={ 0 } vertical='end' className="button-block input-column">
                          {!isFieldDisabled && <Button
                            type="submit"
                            className="btn defaultBtn"
                            onClick={ () => this.onSubmitMid() }>
                            Save
                          </Button>}
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text="Settings successfully saved."
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#25282a"
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
        <PromptModal
          title="Attention"
          message="Changing Account, Provider or Payment Method could affect related pending transaction. Do you want to continue?"
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ false }
          showModal={ showPromptModal }
        />
      </Row>
    );
  }
}

export default ManageMid;
