module.exports = {
  en: {
    roles: {
      TECH_MASTER: "Tech Master",
      SUPER_ADMIN: "Super Admin",
      BASIC_USER: "Basic User",
      MERCHANT_MASTER: "Merchant Master",
      MERCHANT_BASIC: "Merchant Basic",
      MASTER_TECH: "Master Tech",
      MASTER_ADMIN: "Master Admin",
      MASTER_USER: "Master User",
      MERCHANT_ADMIN: "Merchant Admin",
      MERCHANT_USER: "Merchant User",
      ADMIN: "Admin",
      USER: "User",
      AGENT: "Agent"
    },

    permissions: {
      REPORTS_DEPOSITS: "Deposits",
      REPORTS_GROSS_PROFIT: "Gross Profits",
      REPORTS_ACTIVITY: "Activity",
      REPORTS_TRANSACTIONS: "Server Logs",
      REPORTS_PAYOUT: "Payout",
      REPORTS_RUNNING_BALANCE: "Running Balance",
      REPORTS_SETTLEMENTS: "Settlements",
      REPORTS_RISK_SECURITY: "Risk & Security",
      REPORTS_FRAUD_CHECK: "Fraud Check",
      UPDATE_STATUS: "Change Status",
      MERCHANTS_VIEW: "View",
      MERCHANTS_EDIT: "Edit",
      ADMIN_PANEL_VIEW: "View",
      ADMIN_PANEL_EDIT: "Edit",
      DASHBOARD_VIEW: "View",
      MIDS_VIEW: "View",
      MIDS_EDIT: "Edit",
      MIDS_ON_OFF_STATUS: "On/Off",
      ADMIN_PANEL: "ADMIN PANEL",
      MERCHANTS: "MERCHANTS",
      REPORTS: "REPORTS",
      DASHBOARD: "DASHBOARD",
      MIDS: "MIDS",
      USER_MANAGEMENT: "USER MANAGEMENT",
      USER_VIEW: "View",
      USER_EDIT: "Edit",
      CASCADING: "CASCADING",
      PSP_CASCADING_VIEW: "View",
      PSP_CASCADING_EDIT: "Edit",
      PAYOUT_CREATE: "Create",
      NOTES_CREATE: "Create",
      NOTES_VIEW: "View",
      TEST_PAYMENT_CREATE: "Create test payment",
      REFUND_VIEW: "View",
      REFUND_EDIT: "Edit",
      REFERRALS_VIEW: "View 'Pending Referrals'",
      REFERRALS_EDIT: "Edit 'Pending Referrals'",
      REFERRAL_LINKS: "View 'Referral Links'",
      PAYOUT_EXCEL_DOWNLOAD: "Download Payouts",
      PAYOUT_EXCEL_UPLOAD: "Upload Payouts",
      PAYOUT_EXCEL_UPLOAD_SAMPLE: "Upload sample",
      PAYOUT_EXCEL_DOWNLOAD_SAMPLE: "Download sample",
      SEND_POSTBACK: "Send postback",
      CHECK_STATUS:"Check status",
      DATA_INPUT_READ: "View Manual Input",
      DATA_INPUT_WRITE: "Edit Manual Input",
      PROVIDERS: "Providers",
      PSP_VIEW: "View",
      PSP_EDIT: "Edit",
      PSP_ACCOUNT_EDIT: "Edit Accounts",
      HIDE_PRICING_ROW: "Hide Pricing Rows",
      GENERATE_DEPOSIT_ORDER: "Generate order",
      BLACKLIST_VIEW: "View Blacklist",
      BLACKLIST_EDIT: "Edit Blacklist",
	    SEE_MANUAL_TOP_UP: "Payout Wallet Top Up",
      REPORTS_PERFORMANCE: "Performance Report",
      BLOCK_REQUESTS: "Block Requests",
      BANKLIST_VIEW: "View Bank Codes",
      BANKLIST_EDIT: "Edit Bank Codes",
      REPORTS_MERGED: "Transactions",
      CURRENCY_TABLE_VIEW: "View",
      CURRENCY_TABLE_EDIT: "Edit"
    },

    methods: {
      UNIONPAY: 'UnionPay Transfer',
      BANKPAY: 'Fast Transfer',
      ALIPAY: 'Alipay',
      UNIONPAY_INTERNATIONAL: 'UnionPay Card',
      UNIONPAY_INTERNATIONAL_EURO: 'UnionPay Card EURO',
      QUICKPAY: 'QuickPay',
      BANK_TRANSFER: 'Bank Transfer',
      CREDIT_CARD: "Credit Card",
      COD: "Cash On Delivery",
      UPI: "UPI",
      NETBANKING: "Netbanking / UPI / Cards",
      NB_CASHIER: "NB Cashier",
      DEBIT_CARD: "Debit Card",
      MASTERCARD: "MasterCard",
      VISA: "Visa",
      JCB: "JCB",
      AMERICAN_EXPRESS: "American Express",
      DISCOVER: "Discover",
      DINERS_CLUB: "Diners Club",
      UPI_PHONE_PE: "UPI - PhonePE",
      UPI_PAY_TM: "UPI - PayTM",
      UPI_BHIM: "UPI - BHIM",
      G_PAY: "G Pay",
      CUP: "CUP Wallet",
      EXPRESS_BANK_TRANSFER: "Express Bank Transfer",
      VIRTUAL_ACCOUNT_IDR: "Virtual Account IDR",
      QR_CODE_THB: "QR Code THB",
      WALLETS: "Wallets",
      NB: "NB",
      CASH: "Cash",
      ONLINE: "Online",
      S2S_UPI: "S2S UPI",
      PAY_TM: "PayTM",
      PHONE_PE: "PhonePe",
      QR_CODE: "QR Code",
      OPEN_BANKING: "Open Banking",
      DUITNOW: "Duitnow",
      FPX: "FPX",
      QRIS: "QRIS",
      TRUEMONEY: "True Money",
      ALIPAY_SCAN: "Alipay Scan",
      ZONE_PAY: "Zone Pay",
      PANDA_PAY: "Panda Pay"
    }
  }
};
