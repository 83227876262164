import React, { Component } from 'react';

import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';

import Form from "../../../core/Form";
import Spinner from '../../Spinner';

import '../../../assets/css/editClient.css';
import '../../../assets/css/bankCodes.css';
import MerchantsIcon from '../../../assets/images/merchants.png';

import { crudActions } from "../../../services/crudActions";

const store =  require('../../../reducers/index');

class ManageBankCodes extends Component {
  state = {
    bankCodeForms: [
      new Form({
        name: "",
        code: ""
      }, [{
        name: "name",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "code",
        type: "isString",
        rules: {
          required: true
        }
      }])],

    isLoading: false,
    access: [],

    showSuccess: false,
    successMessage: "",
    showError: false,
    errorMessage: ""
  };

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  onBankCodeItemChange = (event, index, field) => {
    const { bankCodeForms } = this.state;

    bankCodeForms[index][field] = event.target.value;

    this.setState({
      bankCodeForms
    });
  };

  onAddBankCodeItem = () => {
    const { bankCodeForms } = this.state;
    bankCodeForms.push(
      new Form({
        name: "",
        code: ""
      }, [{
        name: "name",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "code",
        type: "isString",
        rules: {
          required: true
        }
      }]));
    this.setState({
      bankCodeForms
    });
  };

  onDeleteBankCodeItem = (index) => {
    const { bankCodeForms } = this.state;

    bankCodeForms.splice(index, 1);
    this.setState({
      bankCodeForms
    });
  };

  onSaveBankCodes = () => {
    const { bankCodeForms } = this.state;
    let isFormsValid = true;

    bankCodeForms.forEach(form => {
      form.isFormValid();
      if (!form.isFormValid()) {
        isFormsValid = false;
      }
    });

    this.setState({
      bankCodeForms
    });

    if (isFormsValid) {
      this.setState({
        isLoadingBankCodes: true
      });
      const data = [];
      bankCodeForms.forEach(form => {
        const formData = form.data();
        data.push(formData);
      });

      crudActions.post(`v1/banks/add-banks`, data)
        .then(response => {
          this.setState({
            showSuccess: true,
            successMessage: response.message,
            isLoadingBankCodes: false,
            bankCodeForms: [
              new Form({
                name: "",
                code: ""
              }, [{
                name: "name",
                type: "isString",
                rules: {
                  required: true
                }
              }, {
                name: "code",
                type: "isString",
                rules: {
                  required: true
                }
              }])]
          });
        })
        .catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showError: true,
                errorMessage: err.message,
                isLoadingBankCodes: false
              });
            }
          }
        );
    }
  };

  onConfirm = () => {
    this.setState({
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: ""
    });
  };

  render() {
    const {
      bankCodeForms,
      isLoading,
      errorMessage,
      showSuccess,
      showError,
      successMessage
    } = this.state;

    return <Row flexGrow={ 1 } className="t365 module apidata editClient psps bankCodes" vertical='start'>
      <Column flexGrow={ 1 }>
        <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
          <Column>
            <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
              <img src={ MerchantsIcon } alt="" style={ { marginRight: 10 } }/>
              System Bank Codes
            </Row>
          </Column>
          
        </Row>
        <Row flexGrow={ 1 } horizontal='start' wrap vertical='start'>
          <Column flexGrow={ 1 } vertical='start' className="panel-block">
            
            <Panel>
              <Panel.Heading>
                ADD SYSTEM BANK CODE
              </Panel.Heading>
              <Panel.Body>
                { isLoading ? (
                  <div style={ {
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center" } }
                  >
                    <Spinner smallContainer={ true } />
                  </div>
                ) : (
                  <div className="panel-content provider-accounts" style={ { overflow: 'unset' } }>
                    { bankCodeForms.length > 0 && bankCodeForms.map((bankCodeForm, index) => 
                      <Row
                        key={ index }
                        flexGrow={ 1 }
                        horizontal='start'
                        wrap
                        vertical='end'
                        style={ { width: '100%' } }
                        className="account-row"
                      > 
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Bank Name </label>
                          <input
                            value={ bankCodeForm.name || '' }
                            placeholder="Type here..."
                            className={ "form-control " + (bankCodeForm.errors.has('name') ? 'error-field' : "") }
                            onChange={ (event) => this.onBankCodeItemChange(event, index, 'name') }
                          />
                        </Column>

                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> System Bank Code </label>
                          <input
                            value={ bankCodeForm.code || '' }
                            placeholder="Type here..."
                            className={ "form-control " + (bankCodeForm.errors.has('code') ? 'error-field' : "") }
                            onChange={ (event) => this.onBankCodeItemChange(event, index, 'code') }
                          />
                        </Column>

                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <Row horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                            <Column vertical='center' className="actions">
                              <button
                                className="delete"
                                onClick={ () => this.onDeleteBankCodeItem(index) }
                              >
                                X
                              </button>
                                
                              {index === bankCodeForms.length - 1 && (
                                <button
                                  className="add"
                                  onClick={ () => this.onAddBankCodeItem() }
                                >
                                  <span className="plus"> + </span>
                                </button>
                              )}
                            </Column>
                          </Row>
                        </Column>
                        
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        
                      </Row>
                    )}

                    {bankCodeForms.length === 0 && (
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } horizontal='start' alignSelf='start' className="input-column" style={ { flexDirection: 'row', alignItems: 'center' } }>
                          Add Bank Code
                          <button
                            className="add"
                            onClick={ () => this.onAddBankCodeItem() }
                          >
                            <span className="plus"> + </span>
                          </button>
                        </Column>
                      </Row>
                    )}
                    <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                      <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='start'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='end'>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <Button
                            type="submit"
                            className="btn defaultBtn"
                            disabled={ !bankCodeForms.length || !this.checkPageAccess("BANKLIST_EDIT") }
                            onClick={ () => this.onSaveBankCodes() }
                          >
                            Save
                          </Button>
                        </Column>
                      </Row>
                    </Row>
                  </div>
                )}
              </Panel.Body>
            </Panel>
            
          </Column>
        </Row>
      </Column>
      <SweetAlert
        show={ showSuccess }
        title="Success"
        type="success"
        confirmButtonColor="#25282a"
        text={ successMessage }
        onConfirm={ this.onConfirm }
      />
      <SweetAlert
        show={ showError }
        title="Error"
        type="error"
        confirmButtonColor="#25282a"
        text={ errorMessage }
        onConfirm={ this.onConfirm }
      />
    </Row>;
  }
};

export default ManageBankCodes;
