import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import SweetAlert from 'sweetalert2-react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";

import ComponentHeader from "../../componentHeader";
import Form from "../../../core/Form";
import Select from "../Select";
import Spinner from '../../Spinner';

import '../../../assets/css/payouts.css';
import Arrow from '../../../assets/images/arrow.png';
import PayoutIcon from '../../../assets/images/payouts.png';

import { crudActions } from "../../../services/crudActions";

class ManualPayout extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      merchants: [],
      providers: [],
      clientsPayoutMids: [],

      showError: false,
      showSuccess: false,
      errorMessage: "",
      loadingXlsx: false,
    }, this.getInitialState());
  }

  getInitialState = () => {
    return {
      filterForm: new Form({
        merchantId: "",
        providerId: "",
        payoutMidId: ""
      }, [{
        name: "merchantId",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "providerId",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "payoutMidId",
        type: "isString",
        rules: {
          required: true
        }
      }]),

      parsedData: {},
      fields: [],
      additionalFields: [],
      fieldHeaders: {},

      fileUploaded: "",

      showSecondStep: false,
      showThirdStep: false,

      submitClicked: false,
      uploadClicked: false,
      finishImportClicked: false
    };
  };

  componentDidMount() {
    crudActions.get(`v1/clients/all`).then(
      (data) => {
        if (data) {
          this.setState({
            merchants: data.clients.map(currElem => {
              return currElem.client;
            }),
            providers: data.psps.map(currElem => {
              return currElem;
            }),
            clientsPayoutMids: data.clients.reduce((accumulator, currentElem) => {
              accumulator[currentElem.client.value] = currentElem.payoutMids.map(elem => elem);
              return accumulator;
            }, {})
          });
        }
      }
    );
  };

  filterMids = () => {
    const { filterForm, clientsPayoutMids } = this.state;
    if (!filterForm.providerId || !filterForm.merchantId) {
      return [];
    }

    return clientsPayoutMids[filterForm.merchantId].filter((mid) => filterForm.providerId === mid.pspId.toString());
  };

  getMidNameById = (midId) => {
    const { filterForm, clientsPayoutMids } = this.state;
    if (!filterForm.providerId || !filterForm.merchantId) {
      return [];
    }

    return clientsPayoutMids[filterForm.merchantId].find((mid) => mid.value.toString() === midId.toString()).label;
  };

  onFilterValueChange = (event, fieldName) => {
    let { filterForm } = this.state;
    const { submitClicked } = this.state;
    const fieldType = filterForm.fieldRules.find(elem => elem.name === fieldName).type;
    filterForm = Object.assign(filterForm, {
      [fieldName]: fieldType === "isNumber" ? parseFloat(event.value) : event.value
    });

    if (submitClicked) {
      filterForm.isFormValid();
    }

    this.setState({
      filterForm
    });
  };

  onSubmit = () => {
    const { filterForm } = this.state;
    const isFilterFormValid = filterForm.isFormValid();

    this.setState({
      filterForm: filterForm,
      submitClicked: true
    });

    if (isFilterFormValid) {
      const payoutS2S = "/api/v5/external/payout/s2s";
      Promise.all([
        crudActions.get(`v1/generate-order/inputs?client=${filterForm.merchantId}&url=${payoutS2S}`),
        crudActions.get(`v1/generate-order/inputs/additional?client=${filterForm.merchantId}&url=${payoutS2S}&midId=${filterForm.payoutMidId}`)
      ])
        .then((data) => {
          let inputs = data[0];
          const additionalInputs = data[1];

          if (inputs) {
            if (additionalInputs.fields.length) {
              inputs = inputs.concat(additionalInputs.fields);
            }
            this.setState({
              fields: inputs,
              additionalFields: additionalInputs.fields,
              fieldHeaders: new Form(inputs.reduce((accumulator, currElem) => {
                accumulator[currElem.fieldName] = "";
                return accumulator;
              }, {}), inputs.map(elem => {
                return {
                  name: elem.fieldName,
                  type: "isString",
                  rules: {
                    required: elem.required
                  }
                };
              }))
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
            });
          }
        });
      this.setState({
        showSecondStep: true
      });
    }
  };

  formParsedResultData = (parsedData) => {
    try {
      const headers = parsedData[0];
      const formedData = [];
      const parsedDataLength = parsedData.length;
      const formObject = (array) => {
        const result = {};
        headers.forEach((elem, index) => {
          result[elem] = array[index];
        });

        return result;
      };

      for (let i = 1; i < parsedDataLength; i++) {
        formedData.push(formObject(parsedData[i]));
      }

      return formedData;
    } catch {
      this.setState({
        showError: true,
        errorMessage: "This file couldn't be processed."
      });

      return [];
    }
  };

  onUpload = () => {
    const { fileUploaded, filterForm } = this.state;
    this.setState({
      uploadClicked: true,
      loadingXlsx: true
    });

    if (!fileUploaded) {
      this.setState({
        showError: true,
        errorMessage: "Upload file first."
      });

      return false;
    }

    this.parseXlsxFile().then((data) => {
      if (data) {
        const formedData = this.formParsedResultData(data);
        if (formedData) {
          const fileHeadersList = data[0].map((elem, index) => {
            return {
              value: elem.toString(),
              label: elem.toString() === "midId" ? `Payout MID - ${this.getMidNameById(filterForm.payoutMidId)}` : elem.toString()
            };
          });

          if (data[0].indexOf("midId") < 0) {
            fileHeadersList.push({
              label: `Payout MID - ${this.getMidNameById(filterForm.payoutMidId)}`,
              value: "midId"
            });
          }
          
          this.setState({
            parsedData: {
              headers: fileHeadersList,
              data: formedData
            },
            showThirdStep: true,
            loadingXlsx: false
          }, () => {
            const { fieldHeaders, parsedData } = this.state;

            const fileHeaders = parsedData.headers;
            const headers = fieldHeaders.data();

            Object.entries(headers).forEach(([key, value]) => {
              const appropriateHeader = fileHeaders.find(header => header.value === key);
              if (appropriateHeader) {
                headers[key] = appropriateHeader.value;
              }
            });

            this.setState({
              fieldHeaders: Object.assign(fieldHeaders, headers)
            });
          });
        }
      } else {
        this.setState({
          loadingXlsx: false
        });
      }
    }).catch((err) => {
      let updateObject = {
        loadingXlsx: false
      };

      if (err && err.message) {
        updateObject = {
          ...updateObject,
          showError: true,
          errorMessage: err.message
        };
      }

      this.setState(updateObject);
    });
  };

  parseXlsxFile = () => {
    return new Promise((resolve, reject) => {
      const { fileUploaded } = this.state;
      const reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        const readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        resolve(dataParse);
      };

      reader.onerror = (event) => {
        this.setState({
          showError: true,
          errorMessage: "This file couldn't be processed."
        });
        reader.abort();
      };

      reader.readAsBinaryString(fileUploaded);
    }).catch(
      () => {
        this.setState({
          showError: true,
          errorMessage: "This file can't be processed."
        });

        return false;
      }
    );
  };

  onFileChange = (e) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (file) {
      const splittedName = file.name.split(".");
      const extension = splittedName[splittedName.length - 1];
      if (extension !== "xlsx" && extension !== "xls") {
        this.setState({
          showError: true,
          errorMessage: "Wrong file format."
        });
      } else {
        this.setState({
          fileUploaded: e.target.files.length ? e.target.files[0] : ""
        });
      }
    }
  };

  onHeaderChange = (event, fieldName) => {
    const { fieldHeaders, finishImportClicked } = this.state;
    fieldHeaders[fieldName] = event ? event.value : event;

    if (finishImportClicked) {
      fieldHeaders.isFormValid();
    }

    this.setState({
      fieldHeaders
    });
  };

  filterHeaders = (fieldValue) => {
    const { parsedData, fieldHeaders } = this.state;
    const headers = parsedData.headers;
    const keys = fieldHeaders.fieldRules.map(elem => elem.name);

    const filteredHeaders = headers.filter(elem => !keys.some(key => fieldHeaders[key] === elem.value));
    const concatHeader = headers.find(elem => elem.value && elem.value === fieldValue);

    return (fieldValue && concatHeader) ? filteredHeaders.concat([concatHeader]) : filteredHeaders;
  };

  onFinishImport = () => {
    const { additionalFields, fieldHeaders, filterForm, parsedData } = this.state;
    const isFormValid = fieldHeaders.isFormValid();
    this.setState({
      fieldHeaders,
      finishImportClicked: true
    });

    if (isFormValid) {
      const headersData = fieldHeaders.data();
      const keys = Object.keys(headersData);
      const data = parsedData.data.map(elem => {
        return keys.reduce((accumulator, currElem) => {
          if (additionalFields.find(e => e.fieldName === currElem)) {
            accumulator = {
              ...accumulator,
              data: {
                ...accumulator.data,
                [currElem]: (elem[headersData[currElem]] || "").toString()
              }
            };
          } else {
            accumulator[currElem] = (elem[headersData[currElem]] || "").toString();
          }
          return accumulator;
        }, {});
      });

      this.setState({
        loadingXlsx: true
      });

      crudActions.post(`v2/payout/import`, {
        merchantId: filterForm.merchantId,
        midId: filterForm.payoutMidId,
        payoutDTOS: data
      }).then(
        () => {
          this.setState({
            showSuccess: true,
            loadingXlsx: false,
            ...this.getInitialState()
          });
        }
      ).catch(
        (err) => {
          let updateObject = {
            loadingXlsx: false
          };

          if (err && err.message) {
            updateObject = {
              ...updateObject,
              showError: true,
              errorMessage: err.message
            };
          }

          this.setState(updateObject);
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      loadingXlsx: false
    });
  };

  render() {
    const {
      errorMessage,
      filterForm,
      fileUploaded,
      fieldHeaders,
      fields,
      loadingXlsx,
      merchants,
      providers,
      showError,
      showSecondStep,
      showSuccess,
      showThirdStep
    } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module payouts" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Payout"
            img={ PayoutIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    CHOOSE COUNTRY & PAYOUT METHOD
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Merchant </label>
                        <Select
                          className={ filterForm.errors.has('merchantId') ? 'error-field' : "" }
                          value={ filterForm.merchantId }
                          required={ true }
                          clearable={ false }
                          disabled={ showSecondStep }
                          onChange={ (value) => this.onFilterValueChange(value, 'merchantId') }
                          options={ merchants }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Provider </label>
                        <Select
                          className={ filterForm.errors.has('providerId') ? 'error-field' : "" }
                          value={ filterForm.providerId }
                          required={ true }
                          clearable={ false }
                          disabled={ showSecondStep }
                          onChange={ (value) => this.onFilterValueChange(value, 'providerId') }
                          options={ providers }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Payout MID </label>
                        <Select
                          className={ filterForm.errors.has('payoutMidId') ? 'error-field' : "" }
                          value={ filterForm.payoutMidId }
                          required={ true }
                          clearable={ false }
                          disabled={ showSecondStep || ! filterForm.merchantId || !filterForm.providerId }
                          onChange={ (value) => this.onFilterValueChange(value, 'payoutMidId') }
                          options={ this.filterMids() }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' className="input-column">
                        <Button
                          type="submit"
                          disabled={ showSecondStep }
                          className={ `btn ${!showSecondStep ? 'defaultBtn' : 'btn-secondary'}` }
                          onClick={ () => this.onSubmit() }>
                          SUBMIT
                        </Button>
                      </Column>
                    </Row>
                  </div>
                </Panel.Body>
              </Panel>

              { showSecondStep && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      IMPORT PAYOUTS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loadingXlsx ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : !showThirdStep ? (
                      <div className="panel-content" style={ {overflow: 'unset'} }>
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                            <label> Upload file (.xlsx, .xls) </label>
                            <input
                              id="file-upload"
                              type="file" onChange={ (e) => this.onFileChange(e) }
                              accept=".xlsx, .xls"/>
                            <label htmlFor="file-upload" className="file-upload">
                              { !fileUploaded ? "No files uploaded yet." : fileUploaded.name}
                              <span className="browse"> Browse... </span>
                            </label>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                            <Button
                              type="submit"
                              className="btn defaultBtn"
                              onClick={ () => this.onUpload() }>
                              UPLOAD
                            </Button>
                          </Column>
                        </Row>
                      </div>
                    ) : (
                      <div className="panel-content" style={ {overflow: 'unset'} }>
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' className="import-row">
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="import-column">
                            <h4> Your table headers </h4>
                          </Column>
                          <Column flexGrow={ 0 } vertical='start' alignSelf="end" className="import-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="import-column">
                            <h4> Required Values </h4>
                          </Column>
                        </Row>
                        {fields.map(field => {
                          return (
                            <Row key={ field.fieldName } flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' className="import-row">
                              <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="import-column">
                                <Select
                                  className={ fieldHeaders.errors.has(field.fieldName) ? 'error-field' : "" }
                                  value={ fieldHeaders[field.fieldName] || "" }
                                  required={ true }
                                  clearable={ false }
                                  disabled={ field.fieldName === "midId" }
                                  onChange={ (value) => this.onHeaderChange(value, field.fieldName) }
                                  options={ this.filterHeaders(fieldHeaders[field.fieldName]) }
                                />
                              </Column>
                              <Column flexGrow={ 0 } vertical='center' horizontal="center" alignSelf="end" className="arrow-column">
                                <img src={ Arrow } alt="arrow" style={ { width: 20, height: 12 } }/>
                              </Column>
                              <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="import-column">
                                <input
                                  className="form-control"
                                  disabled
                                  value={ field.fieldLabel }
                                />
                              </Column>
                            </Row>
                          );
                        })}
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end' className="import-row">
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                            <Button
                              type="submit"
                              className='btn defaultBtn'
                              onClick={ () => this.onFinishImport() }>
                              FINISH IMPORT
                            </Button>
                          </Column>
                        </Row>
                      </div>
                    )}
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text="Payout batch is being processed."
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor={ "#DD6B55" }
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default ManualPayout;
