import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';

import Spinner from '../../Spinner';

import '../../../assets/css/adminPanel.css';
import '../../../assets/css/currencyTable.css';
import PayoutsIcon from '../../../assets/images/payouts.png';

import { crudActions } from "../../../services/crudActions";
import { FETCH_PERMISSIONS } from '../../../actions/types';
const store =  require('../../../reducers/index');

class CurrencyTable extends Component {
  state = {
    columns: [{
      value: "currencyPair",
      label: "Currency Pair",
      disabled: true
    }, {
      value: "fxMidRate",
      label: "FX Mid Rate",
      disabled: true
    }, 
    {
      value: "buyFee",
      label: "FX Markup Buy",
      isPercents: true
    },{
      value: "buyRate",
      label: "FX Buy Rate",
      disabled: true
    }, {
      value: "sellFee",
      label: "FX Markup Sell",
      isPercents: true
    }, {
      value: "sellRate",
      label: "FX Sell Rate",
      disabled: true
    }],

    fxForm: {},
    fxErrorsMap: {},

    showSuccess: false,
    showError: false,
    errorMessage: "",

    loadingSettings: true,

    access: []
  };

  pairsRef = React.createRef();
  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    this.loadFxSettings();
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  loadFxSettings = () => {
    const { fxForm } = this.state;

    this.setState({
      loadingFXSettings: true
    });

    crudActions.get(`v1/fxsettings`).then(
      (baseData) => {
        if (baseData) {
          const cleanFxForm = {};
          const baseDataLength = baseData.length;
          for (let i = 0; i < baseDataLength; i++) {
            const elem = baseData[i];

            const tempElem = {
              id: elem.id || null,
              buyFee: elem.buyFee,
              buyRate: elem.buyRate,
              sellFee: elem.sellFee,
              sellRate: elem.sellRate,
              fxSettingsId: elem.fxSettingsId,
              baseCurrency: elem.baseCurrency,
              currency: elem.currency,
              exchangeRate: elem.exchangeRate
            };

            if (!fxForm[elem.id]) {
              fxForm[elem.id] = tempElem;
            }

            cleanFxForm[elem.id] = tempElem;
          }

          const updateObject = {
            baseData,
            loadingSettings: false
          };

          updateObject.fxForm = cleanFxForm;

          this.setState(updateObject);
        }
      }
    );
  }

  filterBasePairs = () => {
    const { baseData, fxForm } = this.state;
    if (!baseData.length) {
      return [];
    }

    return baseData.filter(item => item.baseCurrency !== item.currency).map(elem => {
      return Object.assign({
        uniqueId: elem.id,
        currencyPair: elem.baseCurrency + "/" + elem.currency,
        fxMidRate: elem.exchangeRate,
        equalCurrencies: elem.baseCurrency === elem.currency
      }, fxForm[elem.id]);
    });
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  onChangeFxSettings = (event, column, fxId) => {
    const { fxForm } = this.state;
    this.setState({
      fxForm: Object.assign(fxForm, {
        [fxId]: Object.assign(fxForm[fxId], {
          [column]: parseFloat(event.target.value)
        })
      })
    });
  };

  onSubmitFxSettings = () => {
    const { fxForm, fxErrorsMap } = this.state;

    // check fx settings
    const fxKeys = Object.keys(fxForm).filter(id => this.filterBasePairs().some(elem => elem.uniqueId.toString() === id.toString()));
    const fxKeysLength = fxKeys.length;
    let isFxErrors = false;
    let isExchangeRateError = false;

    for (let i = 0; i < fxKeysLength; i++) {
      const key = fxKeys[i];
      const { buyFee, sellFee, buyRate, sellRate } = fxForm[key];
      const buyFeeError = ((!buyFee && buyFee !== 0) || buyFee < 0 || buyFee > 100);
      const sellFeeError = ((!sellFee && sellFee !== 0) || sellFee < 0 || sellFee > 100);
      const fxMidRateError = (!buyRate || !sellRate);

      fxErrorsMap[key] = {
        buyFee: buyFeeError,
        sellFee: sellFeeError,
        fxMidRate: fxMidRateError
      };

      if (buyFeeError || sellFeeError) {
        isFxErrors = true;
      }

      if (fxMidRateError) {
        isExchangeRateError = true;
      }
    }

    this.setState({
      fxErrorsMap: fxErrorsMap
    });

    if (isExchangeRateError) {
      this.setState({
        showError: true,
        errorMessage: "Some exchange rates are missing."
      });

      return false;
    }

    if (!isFxErrors) {
      this.setState({
        loadingSettings: true
      });
      crudActions.put(`v1/fxsettings`, fxKeys.map(key => fxForm[key])).then(
        () => {
          this.setState({
            showSuccess: true,
            loadingSettings: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              loadingSettings: false
            });
          }
        }
      );
    } else {
      this.setState({
        showError: true,
        errorMessage: "Values in some fields are invalid. Please, fix them to continue."
      });
    }
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: ""
    });
  };

  render() {
    const {
      columns,
      errorMessage,
      fxForm,
      fxErrorsMap,
      loadingSettings,
      showError,
      showSuccess
    } = this.state;

    return <Row flexGrow={ 1 } className="t365 module apidata adminPanel currencyTable" vertical='start'>
      <Column flexGrow={ 1 }>
        <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
          <Column>
            <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
              <img src={ PayoutsIcon } alt="" style={ { marginRight: 10 } }/>
              Currency Table
            </Row>
          </Column>
        </Row>
        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
          <Column flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%' } }>
            { loadingSettings ? (
              <Panel>
                <Panel.Heading>
                  <Panel.Title> FX SETTINGS </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div>
                </Panel.Body>
              </Panel>
            ) : (
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    FX SETTINGS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ { overflow: 'unset' } }>
                    <div className="fx-table-wrapper">
                      <table className="table fx-table">
                        <thead>
                          <tr>
                            { columns.map(column => {
                              return (
                                <th key={ column.value }>
                                  {column.label}
                                </th>
                              );
                            })
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.filterBasePairs().map((row, j) => {
                              return (
                                <tr key={ j }>
                                  {columns.map(column => {
                                    return (
                                      <td
                                        key={ column.value }
                                        className={ `td-element ${column.disabled || row.equalCurrencies ? "td-disabled" : ""} ${fxErrorsMap[row.uniqueId] && fxErrorsMap[row.uniqueId][column.value] ? "td-error" : ""}` }
                                      >
                                        {column.disabled || row.equalCurrencies ? (
                                          <span>{ row[column.value] }</span>
                                        ) : (
                                          <input
                                            type="number"
                                            className='table-input'
                                            disabled={ !this.checkPageAccess("CURRENCY_TABLE_EDIT") }
                                            value={ this.getValue(fxForm[row.uniqueId][column.value]) }
                                            onChange={ (event) => this.onChangeFxSettings(event, column.value, row.uniqueId) }
                                          />
                                        )}
                                        {column.isPercents && <span className="percent-sign"> % </span>}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                    <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                      <span/>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                      <span/>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                      <span/>
                    </Column>
                    <Column flexGrow={ 1 } vertical='end' className="button-block">
                      { !loadingSettings && this.checkPageAccess("CURRENCY_TABLE_EDIT") && (
                        <Button
                          type="submit"
                          className="btn defaultBtn"
                          onClick={ () => this.onSubmitFxSettings() }
                        >
                          Save
                        </Button>
                      )}
                    </Column>
                  </Row>
                </Panel.Body>
              </Panel>
            )}
          </Column>
        </Row>
      </Column>

      <SweetAlert
        show={ showSuccess }
        title="Success"
        type="success"
        confirmButtonColor="#25282a"
        text="Settings successfully saved."
        onConfirm={ this.onConfirm }
      />
      <SweetAlert
        show={ showError }
        title="Error"
        type="error"
        confirmButtonColor="#25282a"
        text={ errorMessage }
        onConfirm={ this.onConfirm }
      />
    </Row>;
  }
};

export default CurrencyTable;
