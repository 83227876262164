import React, {Component} from 'react';

import Table from "../../Table";
import {  Panel } from "react-bootstrap";
import '../../../../assets/css/apiData.css';
import 'react-select/dist/react-select.css';
import { toFormattedNumber } from "../../../../utils/utils.js";
import Spinner from '../../../Spinner';
import { DASHBOARD_PURCHASE_COUNTRIES_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardPurchasesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaseMethods: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "country",
        label: "Country"
      }, {
        value: "topUp",
        label: "Top Up",
        isNumber: true
      }, {
        value: "total",
        label: "Total",
        isNumber: true,
        rightAligned: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PURCHASE_COUNTRIES_EVENT && this.props.thisMonth && state.purchaseMethods.socket) {
        this.setState({ purchaseMethods: state.purchaseMethods, loading: false, socket: state.purchaseMethods.socket });
        return;
      }

      if (state.update === DASHBOARD_PURCHASE_COUNTRIES_EVENT && !state.purchaseMethods.socket) {
        this.setState({ purchaseMethods: state.purchaseMethods, loading: false, socket: state.purchaseMethods.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { purchaseMethods } = this.state;

    if (!purchaseMethods) {
      return [];
    }

    return purchaseMethods.map(elem => {
      return {
        country: elem.country,
        topUp: elem.topUp,
        purchases: elem.currency + toFormattedNumber(elem.purchases),
        fxFee: elem.currency + toFormattedNumber(elem.fxFee),
        purchaseFee: elem.currency + toFormattedNumber(elem.purchaseFee),
        total: elem.currency + toFormattedNumber(elem.total)
      };
    });
  };

  render() {
    const { purchaseMethods, loading, columns } = this.state;

    let purchaseMethodsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> DEPOSITS PER COUNTRY </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && purchaseMethods) {
      purchaseMethodsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> DEPOSITS PER COUNTRY </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return purchaseMethodsWrap;
  }
}

export default DashBoardPurchasesComponent;