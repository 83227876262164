import React, {Component} from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import SweetAlert from 'sweetalert2-react';
import Datetime from 'react-datetime';
import { saveAs } from 'file-saver';

import Form from "../../../core/Form";
import PromptModal from '../generateReport/PromptModal';
import Spinner from '../../Spinner';
import Table from "../Table";

import '../../../assets/css/blacklist.css';
import UserManagementIcon from '../../../assets/images/user-management.png';

import { FETCH_PERMISSIONS } from '../../../actions/types';
import { crudActions } from "../../../services/crudActions";
const store =  require('../../../reducers/index');

class Blacklist extends Component {
  state = {
    controls: new Form({
      email: "",
      mobileNumber: "",
      firstName: "",
      lastName: "",
      ipAddress: ""
    }, [{
      name: "email",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "mobileNumber",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "firstName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "lastName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "ipAddress",
      type: "isString",
      rules: {
        required: false
      }
    }]),

    blacklistAccounts: [],
    clients: [],
    filterOn: false,
    access: [],
    isLoading: false,
    isBlacklistLoading: false,
    isExportSettingsOpen: false,
    showError: false,
    errorMessage: "",
    showSuccess: false,
    successMessage: "",
    showPromptModal: false,
    accountToDelete: {},
    currentPage: 1,
    limit: 100,
    records: null
  };

  subscribeFunction = null;
  exportNode = null;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get(`v1/clients/all`)
      .then(response => {
        if (response) {
          this.setState({
            clients: response.clients.map(currElem => {
              return currElem.client;
            }),
            isLoading: false
          });
        } 
      });
  };

  handleClickOutside = (event) => {
    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    this.setState({
      controls
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  tableColumns = () => {
    const columns = [{
      value: "email",
      label: "Email"
    }, {
      value: "firstName",
      label: "First Name"
    }, {
      value: "lastName",
      label: "Last Name"
    }, {
      value: "mobileNumber",
      label: "Phone Number"
    }, {
      value: "ipAddress",
      label: "IP Address"
    }, {
      value: "merchantId",
      label: "Merchant ID"
    }];

    if (this.checkPageAccess("BLACKLIST_EDIT")) {
      columns.push({
        value: "id",
        label: "Actions",
        centerAligned: true,
        className: "btn remove-bl-account",
        buttonLabel: "Remove",
        triggerEvent: (acc) => this.removeBlacklistAccount(acc),
        isButton: true,
        isButtonDisabled: (acc) => this.checkRemoveAvailability(acc)
      });
    }

    return columns;
  };

  checkRemoveAvailability = (account) => {
    if (!account) return true;

    return false;
  }

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  removeBlacklistAccount = (account) => {
    if (!account) return;

    this.setState({
      showPromptModal: true,
      accountToDelete: account
    });
  };

  handleConfirmPromptModal = () => {
    const { accountToDelete, blacklistAccounts } = this.state;
    crudActions.remove(`v1/blacklist/${accountToDelete.id}`)
      .then(() => {
        const filtered = blacklistAccounts.filter(acc => acc.id !== accountToDelete.id);
        this.setState({
          blacklistAccounts: filtered,
          accountToDelete: {},
          showPromptModal: false
        });
      })
      .catch(() => {
        this.setState({
          showPromptModal: false
        });
      });
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      accountToDelete: {}
    });
  };

  onAlertConfirm = () => {
    this.setState({
      showError: false,
      errorMessage: ""
    });
  };

  onGenerateBlacklist = (type) => {
    const { controls, currentPage, limit } = this.state;
    const isControlsFormValid = controls.isFormValid();

    this.setState({
      controls
    });

    if (isControlsFormValid) {
      this.setState({
        isBlacklistLoading: true
      });

      const data = {
        ...controls.data(),
        pageNumber: currentPage,
        limit: limit
      };

      let url = `v1/blacklist/search`;
      if (type === "file") {
        url += `/xlsx`;
      }

      crudActions.post(url, data)
        .then(response => {
          if (type === "list") {
            if (response) {
              this.setState({
                isBlacklistLoading: false,
                filterOn: true,
                blacklistAccounts: response.data,
                records: response.count
              });
            }
          } else {
            if (response && response.ok) {
              response.blob().then(
                result => {
                  saveAs(result, `Blacklist-${Datetime.moment().format("DD-MM-YYYY")}.xlsx`);
                }
              );
            } else {
              return Promise.reject({
                message: "Request can not be processed."
              });
            }
          }
        })
        .catch(err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true,
              isBlacklistLoading: false
            });
          }
        });
    }
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon"
      || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
      || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else
    if(event.target.textContent){
      pageNum = event.target.textContent;
    }
   
    this.setState({ currentPage: pageNum }, () => {
      this.onGenerateBlacklist('list');
    }); 
  };

  render() {
    const {
      blacklistAccounts,
      records,
      controls,
      currentPage,
      errorMessage,
      filterOn,
      isExportSettingsOpen,
      isLoading,
      limit,
      showError,
      showPromptModal
    } = this.state;

    const columns = this.tableColumns();
    const totalPageCount = Math.ceil(records / limit);

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    return (
      <Row flexGrow={ 1 } className="t365 module generateReports blacklist" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } }/>
                Blacklist
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {this.checkPageAccess("BLACKLIST_EDIT") && (
                  <Column horizontal='end' style={ {paddingRight: 15} }>
                    <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                      <NavLink to={ `/add-blacklist` } className="btn add-user-button">
                        ADD USER
                      </NavLink>
                      <NavLink to={ `/upload-blacklist` } className="btn upload-button">
                        UPLOAD
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH BLACKLIST
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Email </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('email') ? 'error-field' : "") }
                            value={ controls.email || '' }
                            onChange={ (e) => this.onValueChange(e, "email") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Phone Number </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('mobileNumber') ? 'error-field' : "") }
                            value={ controls.mobileNumber || '' }
                            onChange={ (e) => this.onValueChange(e, "mobileNumber") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> First Name </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('firstName') ? 'error-field' : "") }
                            value={ controls.firstName || '' }
                            onChange={ (e) => this.onValueChange(e, "firstName") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Last Name </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('lastName') ? 'error-field' : "") }
                            value={ controls.lastName || '' }
                            onChange={ (e) => this.onValueChange(e, "lastName") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> IP Address </label>
                          <input
                            type="text"
                            className={ "form-control " + (controls.errors.has('ipAddress') ? 'error-field' : "") }
                            value={ controls.ipAddress || '' }
                            onChange={ (e) => this.onValueChange(e, "ipAddress") }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label/>
                          <Button
                            type="button"
                            className="btn defaultBtn"
                            onClick={ (e) => this.onGenerateBlacklist('list') }
                          >
                            SEARCH
                          </Button>
                        </Column>                 
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>

              { filterOn && (
                <>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title>
                        REPORT GENERATED
                      </Panel.Title>
                      <div className="settings-panel">
                        <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                          <svg className="setting-icon" viewBox="0 0 32 32">
                            <g>
                              <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd" />
                              <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd" />
                            </g>
                          </svg>
                          <span className="text"> Export </span>
                        </span>
                      </div>
                      {isExportSettingsOpen && <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onGenerateBlacklist('file') }> Export to Excel </p>
                      </div>
                      }
                    </Panel.Heading>
                    <Panel.Body>
                      <div className="blacklist-table">
                        <Table
                          columns={ columns }
                          data={ blacklistAccounts }
                          isStriped={ true }
                          defaultSortBy={ columns[0].value }
                          useArrow={ true }
                        />
                      </div>
                    </Panel.Body>
                  </Panel>

                  {totalPageCount > 0 && (
                    <Panel style={ { marginTop : "-13px", borderColor : "white", maxHeight: "105px", minHeight: "100px" } }>
                      <Panel.Body>
                        <div className="pagination">
                          <Pagination 
                            count={ totalPageCount } 
                            variant="outlined" 
                            shape="rounded" 
                            page={ Number(currentPage) }
                            onClick= { this.handleChangePage } 
                          />
                        </div>
                      </Panel.Body>
                    </Panel>
                  )}
                </>
              )}
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#DD6B55"
          text={ errorMessage }
          onConfirm={ this.onAlertConfirm }
        />
        <PromptModal
          title="Remove blacklist account"
          message="Do you want to delete an account from the Blacklist?"
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          showModal={ showPromptModal }
        />
      </Row>
    );
  }
}

export default Blacklist;
