import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';

import Form from "../../../core/Form";
import Select from "../Select";
import Spinner from '../../Spinner';

import '../../../assets/css/blacklist.css';
import UserManagementIcon from '../../../assets/images/user-management.png';

import { FETCH_PERMISSIONS } from '../../../actions/types';
import { crudActions } from "../../../services/crudActions";
const store =  require('../../../reducers/index');

class AddBlacklist extends Component {
  state = {
    controls: new Form({
      merchantId: ""
    }, [{
      name: "merchantId",
      type: "isString",
      rules: {
        required: true
      }
    }]),
    defaultFormFields: {
      email: "",
      firstName: "",
      lastName: "",
      ipAddress: "",
      mobileNumber: ""
    },
    defaultFormRules: [{
      name: "email",
      type: "isEmail",
      rules: {
        required: true
      }
    }, {
      name: "firstName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "lastName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "ipAddress",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "mobileNumber",
      type: "isString",
      rules: {
        required: false
      }
    }],
    access: [],
    blacklist: [],
    merchants: [],
    isLoading: false,
    showSuccess: false,
    successMessage: "",
    showError: false,
    errorMessage: "",
    isSaveClicked: false
  };

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get(`v1/clients/all`)
      .then(response => {
        const merchants = response.clients.map(currElem => {
          return currElem.client;
        });
        const { blacklist, defaultFormFields, defaultFormRules } = this.state;
        blacklist.push(new Form(defaultFormFields, defaultFormRules));
        
        this.setState({
          blacklist,
          merchants,
          isLoading: false
        });
      });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls, isSaveClicked } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (isSaveClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls
    });
  };

  onBlacklistValueChange = (event, index, field) => {
    const { blacklist } = this.state;
    blacklist[index][field] = event.target.value;

    blacklist.forEach(form => {
      form.isFormValid();
    });

    this.setState({
      blacklist
    });
  };

  onDeleteItem = (index) => {
    const { blacklist } = this.state;
    blacklist.splice(index, 1); 
    this.setState({
      blacklist
    });
  };

  onAddItem = () => {
    const { blacklist, defaultFormFields, defaultFormRules } = this.state;
    blacklist.push(new Form(defaultFormFields, defaultFormRules)); 
    this.setState({
      blacklist
    });
  };

  onAlertConfirm = () => {
    this.setState({
      showError: false,
      showSuccess: false
    });
  };

  onBlacklistSave = () => {
    const { blacklist, controls, defaultFormFields, defaultFormRules } = this.state;
    const isControlsFormValid = controls.isFormValid();
    let isBlacklistFormsValid = true;

    blacklist.forEach(form => {
      form.isFormValid();
      if (!form.isFormValid()) {
        isBlacklistFormsValid = false;
      }
    });

    this.setState({
      blacklist,
      controls,
      isSaveClicked: true
    });

    if (isControlsFormValid && isBlacklistFormsValid) {
      this.setState({
        isLoading: true
      });
      var toStore = blacklist.map(num => {
        return {
          email: num.email,
          firstName: num.firstName,
          lastName: num.lastName,
          ipAddress: num.ipAddress,
          mobileNumber: num.mobileNumber,
          merchantId: controls.merchantId
        };
      });

      crudActions.post(`v1/blacklist/store`, {
        toStore: toStore,
      }).then(
        (result) => {
          this.setState({
            blacklist: [new Form(defaultFormFields, defaultFormRules)],
            isLoading: false,
            showSuccess: true,
            successMessage: "Added to blacklist successfully!"
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    }
  };

  render() {
    const {
      blacklist,
      controls,
      errorMessage,
      isLoading,
      merchants,
      showError,
      showSuccess,
      successMessage    
    } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module blacklist" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } }/>
                Blacklist
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    ADD USER TO BLACKLIST
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%', marginBottom: '25px' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Merchant </label>
                          <Select
                            id="merchantId"
                            name="merchantId"
                            className={ controls.errors.has('merchantId') ? 'error-field' : "" }
                            value={ controls.merchantId || '' }
                            required={ true }
                            clearable={ true }
                            onChange={ (value) => this.onValueChange(value, 'merchantId') }
                            options={ merchants }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>

                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      { blacklist.map((blForm, i) => 
                        <Row key={ i } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='center' className="blacklist-add-form">
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Email </label>
                            <input
                              type="text"
                              className={ "form-control " + (blForm.errors.has('email') ? 'error-field' : "") }
                              value={  blForm.email }
                              onChange={ (e) => this.onBlacklistValueChange(e, i, "email") }
                            />
                            { blForm.errors.has('email') && <p className="error-message"> { blForm.errors.get('email')} </p>}
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> First Name </label>
                            <input
                              type="text"
                              className={ "form-control " + (blForm.errors.has('firstName') ? 'error-field' : "") }
                              value={  blForm.firstName }
                              onChange={ (e) => this.onBlacklistValueChange(e, i, "firstName") }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Last Name </label>
                            <input
                              type="text"
                              className={ "form-control " + (blForm.errors.has('lastName') ? 'error-field' : "") }
                              value={  blForm.lastName }
                              onChange={ (e) => this.onBlacklistValueChange(e, i, "lastName") }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Phone Number </label>
                            <input
                              type="text"
                              className={ "form-control " + (blForm.errors.has('mobileNumber') ? 'error-field' : "") }
                              value={  blForm.mobileNumber }
                              onChange={ (e) => this.onBlacklistValueChange(e, i, "mobileNumber") }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> IP Address </label>
                            <input
                              type="text"
                              className={ "form-control " + (blForm.errors.has('ipAddress') ? 'error-field' : "") }
                              value={ blForm.ipAddress }
                              onChange={ (e) => this.onBlacklistValueChange(e, i, "ipAddress") }
                            />
                          </Column>
                          <Column className="actions" style={ { flexDirection: "row" } }>
                            <button
                              className="delete"
                              onClick={ () => this.onDeleteItem(i) }
                            >
                              X
                            </button>
                            <button
                              className={ `add ${i !== blacklist.length - 1 && 'add-hidden'}` }
                              onClick={ () => this.onAddItem() }
                            >
                              <span className="plus"> + </span>
                            </button>
                          </Column>
                        </Row>
                      )}
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label/>
                          <Button
                            type="button"
                            className="btn defaultBtn"
                            onClick={ (e) => this.onBlacklistSave() }
                          >
                            SAVE
                          </Button>
                        </Column>                 
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ successMessage }
          onConfirm={ this.onAlertConfirm }
        />

        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#DD6B55"
          text={ errorMessage }
          onConfirm={ this.onAlertConfirm }
        />
      </Row>
    );
  }
}

export default AddBlacklist;
