import React, { Component } from 'react';

import { Column, Row } from 'simple-flexbox';
import ManageClient from './ManageClient';

class ViewClient extends Component {
  state = {};

  render() {
    return <Row flexGrow={ 1 } className='module apidata viewClient' vertical='start'>
      <Column flexGrow={ 1 }>
        <ManageClient clientId={ this.props.match.params.clientId } viewOrEdit={ 1 }/>
      </Column>
    </Row>;
  }
}

export default ViewClient;