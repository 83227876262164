import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import Datetime from 'react-datetime';
import SweetAlert from 'sweetalert2-react';

import ComponentHeader from "../../componentHeader";
import Multiselect from "./Multiselect";
import Spinner from '../../Spinner';

import AdminPanelIcon from '../../../assets/images/adminpanel.png';
import '../../../assets/css/adminPanel.css';

import { crudActions } from "../../../services/crudActions";
import { FETCH_PERMISSIONS } from '../../../actions/types';
const store =  require('../../../reducers/index');

class AdminPanel extends Component {
  state = {
    dropdowns: [
      {
        id: "blockedCountries",
        selectedId: "blockedCountries",
        header: "BLOCKED COUNTRIES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "currency",
        selectedId: "currency",
        header: "CURRENCIES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "midCategories",
        selectedId: "midCategories",
        header: "MID CATEGORIES",
        data: [],
        type: "input",
        deletable: true
      }, {
        id: "buyMethods",
        selectedId: "paymentMethods",
        header: "PAYMENT METHODS",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "payoutCountries",
        selectedId: "payoutCountries",
        header: "PAYOUT COUNTRIES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "payoutMethods",
        selectedId: "payoutMethods",
        header: "PAYOUT METHODS",
        data: [],
        type: "select",
        deletable: true
      }
    ],

    selectedDropdowns: {
      blockedCountries: [],
      currency: [],
      payoutCountries: [],
      midCategories: [],
      payoutMethods: [],
      paymentMethods: []
    },

    temporaryItems: {},

    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),
    showSuccess: false,
    showError: false,
    errorMessage: "",
    updateDataOnConfirm: false,

    removeEmptyField: false,

    loadingSettings: true,

    access: []
  };

  pairsRef = React.createRef();
  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get('v1/adminpanel/lookups').then(
      (data) => {
        if (data) {
          const stateDropdowns = this.state.dropdowns;
          stateDropdowns[0].data = data.country;
          stateDropdowns[1].data = data.currency;

          stateDropdowns[3].data = data.buyMethods;
          stateDropdowns[4].data = data.country;
          stateDropdowns[5].data = data.payoutMethods;
          
          this.setState({
            dropdowns: stateDropdowns
          }, () => {
            this.loadActualSettings();
          });
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  loadActualSettings = () => {
    crudActions.get(`v1/adminpanel`).then(
      (dropdowns) => {
        if (dropdowns) {
          const selectedDropdowns = this.state.selectedDropdowns;
          if (!dropdowns || !dropdowns.storedLookups) {
            return;
          }

          const storedLookups = dropdowns.storedLookups;
          const updated = Datetime.moment(storedLookups.updated).format('HH:mm - DD. MMM YYYY');
          delete storedLookups.updated;
          storedLookups.language = [];
          storedLookups.withdrawalMethods = [];

          this.setState({
            selectedDropdowns: Object.assign(selectedDropdowns, storedLookups),
            lastUpdate: updated,
            loadingSettings: false
          });
        }
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showError: true,
            errorMessage: err.message,
          });
        }
      }
    );
  };

  //Map functions
  mapSelectedItems = (fieldId, selectedFieldId, areSelected) => {
    const { temporaryItems } = this.state;

    const sortStrings = (array) => {
      array.sort((elemA, elemB) => {
        if (elemA.label < elemB.label)
          return -1;
        if (elemA.label > elemB.label)
          return 1;
        return 0;
      });
    };

    const selectedDropdown = this.state.selectedDropdowns[selectedFieldId];
    const dropdown = this.state.dropdowns.find(dropdwn => dropdwn.id === fieldId);
    if (dropdown.type === "select") {
      const dropdowns = (selectedDropdown || []).map(selectedItem => dropdown.data.find(elem => elem.value === selectedItem));
      if (!areSelected && temporaryItems[fieldId]) {
        dropdowns.push(dropdown.data.find(elem => elem.value === temporaryItems[fieldId]));
      }

      sortStrings(dropdowns);
      return dropdowns;
    }

    const dropdowns = selectedDropdown.map(selectedItem => {
      return {
        value: selectedItem,
        label: selectedItem
      };
    });

    if (!areSelected && temporaryItems[fieldId]) {
      dropdowns.push({
        value: temporaryItems[fieldId],
        label: temporaryItems[fieldId]
      });
    }

    sortStrings(dropdowns);

    return dropdowns;
  };

  onMultiselectChange = (id, value) => {
    const { selectedDropdowns, temporaryItems } = this.state;
    selectedDropdowns[id] = value;
    this.setState({
      selectedDropdowns: selectedDropdowns,
      temporaryItems: Object.assign(temporaryItems, { [id]: null })
    });
  };

  onSubmitAdminPanel = () => {
    const { selectedDropdowns, temporaryItems } = this.state;

    const updateObject = {
      showSuccess: true,
      updateDataOnConfirm: true,
      removeEmptyField: true
    };

    const temporaryKeys = Object.keys(temporaryItems);
    for (let i = 0; i < temporaryKeys.length; i++) {
      const key = temporaryKeys[i];
      if (temporaryItems[key] && !selectedDropdowns[key].find(elem => elem === temporaryItems[key])) {
        selectedDropdowns[key].push(temporaryItems[key]);
      }
    }

    this.setState({
      loadingSettings: true
    });

    crudActions.post(`v1/adminpanel`, {
      storedLookups: selectedDropdowns,
      buys: [],
      sells: []
    }).then(
      (result) => {
        this.setState({
          updateObject,
          showSuccess: true,
          loadingSettings: false
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showError: true,
            errorMessage: err.message,
            loadingSettings: false
          });
        }
      }
    );
  };

  onConfirm = () => {
    const updateDataOnConfirm = this.state.updateDataOnConfirm ? this.loadActualSettings : null;

    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      updateDataOnConfirm: false,
      removeEmptyField: false
    }, updateDataOnConfirm);
  };

  computedPattern = (fieldId) => {
    const { temporaryItems } = this.state;
    const { currency, baseCurrencies } = this.state.selectedDropdowns;
    if (fieldId !== "currencyPairs") {
      return null;
    }

    const leftSide = baseCurrencies.join("|") + (temporaryItems["baseCurrencies"] ? `|${temporaryItems["baseCurrencies"]}` : "");
    const rightSide = currency.join("|") +  (temporaryItems["currency"] ? `|${temporaryItems["currency"]}` : "");
    return `(${leftSide})/(${rightSide})$`;
  };

  onTemporaryChange = (id, value) => {
    this.setState({
      temporaryItems: Object.assign(this.state.temporaryItems, { [id]: value })
    });
  };

  render() {
    const {
      dropdowns,
      errorMessage,
      loadingSettings,
      removeEmptyField,
      showError,
      showSuccess
    } = this.state;

    return <Row flexGrow={ 1 } className="t365 module apidata adminPanel" vertical='start'>
      <Column flexGrow={ 1 }>
        <ComponentHeader
          title={ 'Admin Panel' }
          lastUpdate={ this.state.lastUpdate }
          img={ AdminPanelIcon }
        />
        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
          <Column flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%'} }>
            { loadingSettings ? (
              <Panel>
                <Panel.Heading>
                  <Panel.Title> PROPERTY SETTINGS </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div>
                </Panel.Body>
              </Panel>
            ) : (
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    PROPERTY SETTINGS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
                      {dropdowns.slice(0, 4).map(field => {
                        return <Column key={ field.id } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                          <Multiselect id={ field.id }
                            selectedId={ field.selectedId }
                            header={ field.header }
                            items={ field.data }
                            type={ field.type } update={ removeEmptyField }
                            selectedItems={ this.mapSelectedItems(field.id, field.selectedId, true) }
                            pattern={ this.computedPattern(field.id) }
                            onChange={ this.onMultiselectChange }
                            deletable={ field.deletable && this.checkPageAccess("ADMIN_PANEL_EDIT") }
                            onTemporaryChange={ this.onTemporaryChange }/>
                        </Column>;
                      })}
                    </Row>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
                      {dropdowns.slice(4, 8).map(field => {
                        return <Column key={ field.id } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                          <Multiselect id={ field.id }
                            selectedId={ field.selectedId }
                            header={ field.header }
                            items={ field.data }
                            type={ field.type }
                            update={ removeEmptyField }
                            selectedItems={ this.mapSelectedItems(field.id, field.selectedId, true) }
                            pattern={ this.computedPattern(field.id) }
                            ref={ field.id === "currencyPairs" ? this.pairsRef : "" }
                            onChange={ this.onMultiselectChange }
                            deletable={ field.deletable && this.checkPageAccess("ADMIN_PANEL_EDIT") }
                            onTemporaryChange={ this.onTemporaryChange }/>
                        </Column>;
                      })}
                      <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                        <span/>
                      </Column>
                    </Row>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
                      {dropdowns.slice(8).map(field => {
                        return <Column key={ field.id } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                          <Multiselect id={ field.id }
                            selectedId={ field.selectedId }
                            header={ field.header }
                            items={ field.data }
                            type={ field.type }
                            update={ removeEmptyField }
                            selectedItems={ this.mapSelectedItems(field.id, field.selectedId, true) }
                            pattern={ this.computedPattern(field.id) }
                            ref={ field.id === "currencyPairs" ? this.pairsRef : "" }
                            onChange={ this.onMultiselectChange }
                            deletable={ field.deletable && this.checkPageAccess("ADMIN_PANEL_EDIT") }
                            onTemporaryChange={ this.onTemporaryChange }/>
                        </Column>;
                      })}
                      <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                        <span/>
                      </Column>
                    </Row>
                  </div>
                  <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                    <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                      <span/>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                      <span/>
                    </Column>
                    <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                      <span/>
                    </Column>
                    <Column flexGrow={ 1 } vertical='end' className="button-block">
                      { !loadingSettings && this.checkPageAccess("ADMIN_PANEL_EDIT") && (
                        <Button
                          type="submit"
                          className="btn defaultBtn"
                          onClick={ () => this.onSubmitAdminPanel() }>
                          Save
                        </Button>
                      )}
                    </Column>
                  </Row>
                </Panel.Body>
              </Panel>
            )}
          </Column>
        </Row>
      </Column>
      <SweetAlert
        show={ showSuccess }
        title="Success"
        type="success"
        confirmButtonColor="#25282a"
        text="Settings successfully saved."
        onConfirm={ this.onConfirm }
      />
      <SweetAlert
        show={ showError }
        title="Error"
        type="error"
        confirmButtonColor="#25282a"
        text={ errorMessage }
        onConfirm={ this.onConfirm }
      />
    </Row>;
  }
};

export default AdminPanel;