import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';
import { Redirect, NavLink } from 'react-router-dom';

import Multiselect from "../customMultiselect";
import Form from "../../../core/Form";
import Spinner from '../../Spinner';
import Toggler from '../Toggler';

import '../../../assets/css/editClient.css';
import '../../../assets/css/pspTables.css';
import PSPIcon from '../../../assets/images/psp.png';
import loadingIcon from '../../../assets/images/loading.png';

import language from '../../../assets/lang/language';
import { crudActions } from "../../../services/crudActions";
const store =  require('../../../reducers/index');

class ManagePSP extends Component {
  state = {
    pspForm: new Form({
      name: "",
      shortName: "",
      paymentMethods: [],
      payoutMethods: [],
      approvalRequestId: null,
      depositTestUrl: "",
      payoutTestUrl: "",
      refundTestUrl: "",
      depositLiveUrl: "",
      payoutLiveUrl: "",
      refundLiveUrl: "",
    }, [{
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "shortName",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "depositTestUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "payoutTestUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "refundTestUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "depositLiveUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "payoutLiveUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "refundLiveUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "approvalRequestId",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "paymentMethods",
      type: "isArray",
      rules: {
        required: false
      }
    }, {
      name: "payoutMethods",
      type: "isArray",
      rules: {
        required: false
      }
    }]),

    accountForms: [],
    accountStatusLoader: [],

    pspLinks: [{
      name: "ADD DEPOSIT TABLE",
      url: "/providers/add-table/buy",
    }, {
      name: "ADD PAYOUT TABLE",
      url: "/providers/add-table/payout"
    }],

    enableRefund: false,
    updatePayoutByFile: false,
    statusApiDeposit: false,
    statusApiPayout: false,
    defaultCallbackUrl: false,
    depositTestUrl: "",
    payoutTestUrl:"",
    refundTestUrl: "",
    depositLiveUrl: "",
    payoutLiveUrl: "",
    refundLiveUrl: "",
    paymentMethods: [],
    isLoading: true,
    isLoadingAccounts: false,
    access: [],
    roleId: "",

    buysTable: null,
    payoutsTable: null,

    buysClickMap: {},
    payoutsClickMap: {},
    creditCardClickMap: {},

    buysErrorsMap: {},
    payoutsErrorsMap: {},
    creditCardErrorsMap: {},

    showSuccess: false,
    redirectToPsps: false,
    isBurgerOpen: false,
    showError: false,
    saveClicked: false,
    errorMessage: ""
  };

  node = null;
  subscribeFunction = null;
  burgerNode = null;

  buysRefs = {};
  payoutsRefs = {};

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access,
        roleId: storeState.roleId
      });
    }
    const pspId = this.props.pspId;
    const routeState = this.props.location && this.props.location.state;
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    if (approvalRequestId) {
      crudActions.get(`v1/approval/requests/${approvalRequestId}`).then(
        (requestData) => {
          if (requestData) {
            this.setState({
              pspForm: Object.assign(this.state.pspForm, {
                agentId: requestData.referredAgentId
              })
            });
          }
        }
      );
    }

    crudActions.get('v1/adminpanel/lookups').then(lookups => {
      if (lookups) {
        this.setState({
          paymentMethods: lookups.buyMethods
        });
      }

      if (pspId) {
        crudActions.get(`v1/psp/${pspId}`)
          .then(
            (psp) => {
              if (psp) {
                this.setState({
                  pspForm: Object.assign(this.state.pspForm, psp),
                  enableRefund: !!psp.enableRefund,
                  updatePayoutByFile: !!psp.updatePayoutByFile,
                  statusApiDeposit: !!psp.statusApiDeposit,
                  statusApiPayout: !!psp.statusApiPayout,
                  defaultCallbackUrl: !!psp.defaultCallbackUrl,
                  depositTestUrl: !!psp.depositTestUrl,
                  payoutTestUrl: !!psp.payoutTestUrl,
                  refundTestUrl: !!psp.refundTestUrl,
                  depositLiveUrl: !!psp.depositLiveUrl,
                  payoutLiveUrl: !!psp.payoutLiveUrl,
                  refundLiveUrl: !!psp.refundLiveUrl,
                  isLoading: false
                }, () => {
                  this.loadSettingsAndAccounts();
                });
              }
            }
          )
          .catch(
            (err) => {
              if (err && err.message) {
                this.setState({
                  showError: true,
                  errorMessage: err.message,
                  isLoading: false
                });
              }
            }
          );
      } else {
        this.setState({
          isLoading: false,
          isLoadingAccounts: false
        });
      }
    });
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  }

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  isFieldDisabled = () => {
    const viewOrEdit = this.props.viewOrEdit;

    if (!viewOrEdit) {
      return false;
    }

    return viewOrEdit === 1;
  }

  onConfirm = () => {
    this.setState({
      showError: false,
      errorMessage: "",
      showSuccess: false,
      redirectToPsps: this.state.showSuccess
    });
  };

  mapPaymentMethods = () => {
    const { paymentMethods, pspForm } = this.state;
    const selectedPaymentMethods = pspForm.paymentMethods;

    return selectedPaymentMethods.map(elem => {
      return paymentMethods.find(paymentMethod => paymentMethod.value === elem);
    });
  };

  mapPayoutMethods = () => {
    const { paymentMethods, pspForm } = this.state;
    const selectedPayoutMethods = pspForm.payoutMethods;

    return selectedPayoutMethods.map(elem => {
      return paymentMethods.find(paymentMethod => paymentMethod.value === elem);
    });
  };

  onValueChange = (event, fieldName) => {
    let { pspForm } = this.state;

    if (event.value) {
      pspForm = Object.assign(pspForm, {
        [fieldName]: event.value
      });
    } else if (event.target) {
      pspForm = Object.assign(pspForm, {
        [fieldName]: event.target.value
      });
    } else {
      pspForm = Object.assign(pspForm, {
        [fieldName]: event.map(elem => elem.value)
      });
    }

    pspForm.isFormValid();

    this.setState({
      pspForm
    });
  };

  submitPSPSettings = () => {
    const { pspForm, enableRefund, updatePayoutByFile, statusApiPayout, defaultCallbackUrl, statusApiDeposit } = this.state;
    const pspId = this.props.pspId;
    const routeState = this.props.location && this.props.location.state;
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    const isFormValid = pspForm.isFormValid();
    this.setState({
      pspForm
    });

    if (isFormValid) {
      this.setState({
        isLoading: true
      });
      const data = Object.assign(pspForm.data(), { enableRefund, updatePayoutByFile, statusApiPayout, defaultCallbackUrl, statusApiDeposit });
      (pspId ? crudActions.put(`v1/psp`, Object.assign(data, {
        id: pspId
      })) : crudActions.post(`v1/psp`, Object.assign(data, {
        approvalRequestId: approvalRequestId
      }))).then(
        () => {
          this.setState({
            showSuccess: true,
            isLoading: false
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    } else {
      this.setState({
        showError: true,
        errorMessage: "Some fields have errors. Please, fix them."
      });
    }
  };

  setRef = (ref, rowId, columnName, mapName) => {
    if (!this[mapName][rowId]) {
      this[mapName][rowId] = {};
    }

    this[mapName][rowId][columnName] = ref;
  };

  onTableDataClick = (value, columnName, rowId, clickMapName, mapName) => {
    const viewOrEdit = this.props.viewOrEdit;
    const clickMap = this.state[clickMapName];
    if ((value !== 0 && !value) || viewOrEdit === 1) {
      return false;
    }

    clickMap[rowId][columnName] = !clickMap[rowId][columnName];

    this.setState({
      [clickMapName]: clickMap
    }, () => {
      const input = this[mapName][rowId][columnName];
      if (input) {
        input.focus();
      }
    });
  };

  onKeyPress = (event, value, rowId, fieldName, clickMap, refsMap) => {
    const keyPressed = event.charCode;
    if (keyPressed === 13) {
      this.onTableDataClick(value, rowId, fieldName, clickMap, refsMap);
    }
  };

  onChangeGeneralSettings = (event, columnName, tableName, rowId) => {
    const { saveClicked } = this.state;

    const table = this.state[tableName];
    const editRow = table.rows.find(elem => elem.id === rowId);
    if (editRow) {
      editRow.defaults[columnName] = event.target.value ? parseFloat(event.target.value) : "";
    }

    this.setState({
      [tableName]: table
    });

    if (saveClicked) {
      this.checkValidation();
    }
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  loadSettingsAndAccounts = () => {
    const { pspForm } = this.state;
    const { pspId } = this.props;

    this.setState({
      isLoadingAccounts: true
    }, () => {
      Promise.all([
        crudActions.get(`v1/psp/defaults?psp=${pspForm.shortName}`),
        crudActions.get(`v1/psp/accounts/${pspId}`)
      ])
        .then(
          (response) => {
            const tables = response[0];
            const accounts = response[1];
            if (tables) {
              const createMap = (name, setValue) => {
                const columnMap = tables[name].fields.map(column => column.name).reduce((accumulator, currentElem) => {
                  accumulator[currentElem] = setValue;
                  return accumulator;
                }, {});

                return tables[name].rows.map(elem => elem.id).reduce((accumulator, currentElem) => {
                  accumulator[currentElem] = Object.assign({}, columnMap);
                  return accumulator;
                }, {});
              };

              this.setState({
                buysTable: tables.buys,
                buysClickMap: createMap("buys", true),
                buysErrorsMap: createMap("buys", false),

                payoutsTable: tables.payouts,
                payoutsClickMap: createMap("payouts", true),
                payoutsErrorsMap: createMap("payouts", false)
              });
            }
            if (accounts) {
              this.setState({
                isLoadingAccounts: false,
                accountForms: []
              }, () => {
                const accountsList = [];
                const userRole = store.default.getState().authReducer.roleId;
                accounts.length && accounts.forEach(account => {
                  const fields = {
                    id: account.id,
                    authKey: account.authKey || "",
                    secretKey: account.secretKey || "",
                    name: account.name,
                    enabled: account.enabled,
                    pspId: pspId,
                    frozen: false
                  };
                  const fieldsRules = [{
                    name: "authKey",
                    type: "isString",
                    rules: {
                      required: !!account.authKey || userRole === "MASTER_TECH" || userRole === "MASTER_ADMIN"
                    }
                  }, {
                    name: "secretKey",
                    type: "isString",
                    rules: {
                      required: !!account.secretKey || userRole === "MASTER_TECH" || userRole === "MASTER_ADMIN"
                    }
                  }, {
                    name: "name",
                    type: "isString",
                    rules: {
                      required: true
                    }
                  }];
                  accountsList.push(new Form(fields, fieldsRules));
                });
                this.setState({
                  accountForms: accountsList
                });
              });
            }
          }
        ).catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showError: true,
                errorMessage: err.message,
                isLoadingAccounts: false
              });
            }
          }
        );
    });
  };

  checkValidation = () => {
    const { buysTable, payoutsTable, buysErrorsMap, creditCardErrorsMap, payoutsErrorsMap,
      buysClickMap, creditCardClickMap, payoutsClickMap } = this.state;
    let isErrors = false;

    const checkTableErrors = (table, errorsMap, clickMap) => {
      const rows = table.rows;
      const columns = table.fields;

      rows.forEach(
        row => {
          columns.forEach(
            column => {
              const columnValue = row.defaults[column.name];

              if (column.isPercent) {
                errorsMap[row.id][column.name] = columnValue < 0 || columnValue > 100 || (!columnValue && columnValue !== 0);
              } else if (column.name === "MIN_AMOUNT") {
                errorsMap[row.id][column.name] = columnValue > row.defaults.MAX_AMOUNT || columnValue < 0 || (!columnValue && columnValue !== 0);
              } else if (column.name === "MAX_AMOUNT") {
                errorsMap[row.id][column.name] = columnValue < row.defaults.MIN_AMOUNT || columnValue < 0 || (!columnValue && columnValue !== 0);
              } else {
                errorsMap[row.id][column.name] = columnValue < 0 || (!columnValue && columnValue !== 0);
              }

              if (errorsMap[row.id][column.name]) {
                isErrors = true;
                clickMap[row.id][column.name] = false;
              }
            }
          );
        }
      );
    };

    checkTableErrors(buysTable, buysErrorsMap, buysClickMap);
    checkTableErrors(payoutsTable, payoutsErrorsMap, payoutsClickMap);

    this.setState({
      buysTable,
      payoutsTable,

      buysErrorsMap,
      creditCardErrorsMap,
      payoutsErrorsMap,

      buysClickMap,
      creditCardClickMap,
      payoutsClickMap
    });

    return isErrors;
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    
    if (!foundPermission) {
      return false;
    }
 
    return foundPermission.state;
  };

  saveDefaultSettings = () => {
    const { buysTable, payoutsTable } = this.state;

    this.setState({
      saveClicked: true
    });

    const isError = this.checkValidation();

    if (!isError) {
      crudActions.put(`v1/psp/defaults`, {
        buys: buysTable.rows,
        payouts: payoutsTable.rows
      }).then(
        () => {
          this.setState({
            showSuccess: true
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message
            });
          }
        }
      );
    }
  };

  onAccountItemChange = (event, index, field) => {
    const { accountForms } = this.state;

    if (typeof event === "boolean") {
      accountForms[index][field] = event;
    } else {
      accountForms[index][field] = event.target.value;
    }

    this.setState({
      accountForms
    });
  };

  onToggleAccountItemStatus = (event, index, field, accountId) => {
    const { accountStatusLoader } = this.state;
    if (accountId) {
      accountStatusLoader.push(index);
      const {accountForms} = this.state;
      accountForms.forEach(element => {
        element.frozen = true;
      });
      this.setState({
        accountStatusLoader,
        accountForms: accountForms
      });
      crudActions.patch(`v1/psp/accounts`, {
        enabled: event,
        id: accountId
      }).then(response => {
        this.onAccountItemChange(event, index, field);
        accountForms.forEach(element => {
          element.frozen = false;
        });
        this.setState({
          accountStatusLoader: this.state.accountStatusLoader.filter(i => i !== index),
          accountForms: accountForms
        });
      })
        .catch(
          err => {
            accountForms.forEach(element => {
              element.frozen = false;
            });
            if (err && err.message) {
              this.setState({
                errorMessage: err.message,
                showError: true,
                accountStatusLoader: this.state.accountStatusLoader.filter(i => i !== index),
                accountForms: accountForms
              });
            }
          }
        );
    } else {
      this.onAccountItemChange(event, index, field);
    }
  }

  onAddAccountItem = () => {
    const { accountForms } = this.state;
    accountForms.push(new Form({
      id: "",
      authKey: "",
      secretKey: "",
      name: "",
      enabled: true,
      pspId: this.props.pspId
    }, [{
      name: "authKey",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "secretKey",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }]));
    this.setState({
      accountForms
    });
  };

  onDeleteAccountItem = (index) => {
    const { accountForms } = this.state;

    accountForms.splice(index, 1);
    this.setState({
      accountForms
    });
  };

  saveProviderAccounts = () => {
    const { accountForms } = this.state;
    const { pspId } = this.props;
    let isFormsValid = true;

    accountForms.forEach(form => {
      form.isFormValid();
      if (!form.isFormValid()) {
        isFormsValid = false;
      }
    });

    this.setState({
      accountForms
    });

    if (isFormsValid) {
      this.setState({
        isLoadingAccounts: true
      });
      const data = [];
      accountForms.forEach(form => {
        const formData = form.data();
        if (!formData.id) {
          delete formData.id;
        }
        data.push(formData);
      });

      crudActions.post(`v1/psp/accounts/${pspId}`, data)
        .then(response => {
          this.setState({
            showSuccess: true,
            successMessage: response.message,
            isLoadingAccounts: false
          });
        })
        .catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showError: true,
                errorMessage: err.message,
                isLoadingAccounts: false
              });
            }
          }
        );
    }
    
  };

  render() {
    const { pspId, viewOrEdit } = this.props;
    const { accountForms, accountStatusLoader, pspForm, showSuccess, showError, errorMessage, redirectToPsps,
      paymentMethods, isLoading, enableRefund, updatePayoutByFile, pspLinks, isBurgerOpen, buysClickMap,
      payoutsClickMap, buysTable, buysErrorsMap, payoutsErrorsMap, payoutsTable, statusApiPayout, defaultCallbackUrl,
      statusApiDeposit, isLoadingAccounts } = this.state;

    const userRole = store.default.getState().authReducer.roleId;
    const isAbleToEditAccounts = userRole === "MASTER_TECH" || userRole === "MASTER_ADMIN";

    if (redirectToPsps) {
      return <Redirect to={ '/providers' }/>;
    }
    return <Row flexGrow={ 1 } className="t365 module apidata editClient psps" vertical='start'>
      <Column flexGrow={ 1 }>
        <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
          <Column>
            <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
              <img src={ PSPIcon } alt="" style={ { marginRight: 10 } }/>
              { !viewOrEdit ? `Add Provider` : viewOrEdit === 1 ? `View Provider` : `Edit Provider` }
            </Row>
          </Column>
          <Column horizontal='end'>
            <Row horizontal='end' vertical='center'>
              {this.checkPageAccess("PSP_EDIT") && (
                <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink to={ `/providers/add-table/buy` } className="btn add-deposit-button">
                      ADD DEPOSIT TABLE
                    </NavLink>
                    <NavLink to={ `/providers/add-table/payout` } className="btn add-payout-button">
                      ADD PAYOUT TABLE
                    </NavLink>
                    <div
                      ref={ node => this.burgerNode = node }
                      className={ "burger-container " + (isBurgerOpen ? "change" : "") }
                      style={ { float: "right" } }
                      onClick={ this.onBurgerClick }
                    >
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    { isBurgerOpen && (
                      <div ref={ node => this.node = node } className="burger-content">
                        { pspLinks.map((link, i) => {
                          return <div key={ i } className="link-item-container">
                            <NavLink
                              to={ link.url }
                              className="link-item"
                              onClick={ () => this.handleClickOutside() }
                            >
                              {link.name}
                            </NavLink>
                          </div>;
                        }) }
                      </div>
                    )}
                  </Row>
                </Column>
              )}
            </Row>
          </Column>
        </Row>
        <Row flexGrow={ 1 } horizontal='start' wrap vertical='start'>
          <Column flexGrow={ 1 } vertical='start' className="panel-block">
            {userRole === "MASTER_TECH" && (
              <Panel>
                <Panel.Heading>
                  PROVIDER SETTINGS
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Name </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('name') ? 'error-field' : "") }
                            value={ pspForm.name || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'name') }/>
                          { pspForm.errors.has('name') && <span className="error-message"> {pspForm.errors.get("name")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Short name </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('shortName') ? 'error-field' : "") }
                            value={ pspForm.shortName || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'shortName') }/>
                          { pspForm.errors.has('shortName') && <span className="error-message"> {pspForm.errors.get("shortName")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payment Methods </label>
                          <Multiselect
                            isError={ pspForm.errors.has('paymentMethods') }
                            selectedItems={ this.mapPaymentMethods() }
                            disabled={ this.isFieldDisabled() }
                            items={ paymentMethods }
                            type={ "paymentMethods" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Methods </label>
                          <Multiselect
                            isError={ pspForm.errors.has('payoutMethods') }
                            selectedItems={ this.mapPayoutMethods() }
                            disabled={ this.isFieldDisabled() }
                            items={ paymentMethods }
                            type={ "payoutMethods" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Demo Deposit URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('depositTestUrl') ? 'error-field' : "") }
                            value={ pspForm.depositTestUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'depositTestUrl') }/>
                          { pspForm.errors.has('depositTestUrl') && <span className="error-message"> {pspForm.errors.get("depositTestUrl")}  </span> }
                        </Column>
                      
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Live Deposit URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('depositLiveUrl') ? 'error-field' : "") }
                            value={ pspForm.depositLiveUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'depositLiveUrl') }/>
                          { pspForm.errors.has('depositLiveUrl') && <span className="error-message"> {pspForm.errors.get("depositLiveUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Demo Payout URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('payoutTestUrl') ? 'error-field' : "") }
                            value={ pspForm.payoutTestUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'payoutTestUrl') }/>
                          { pspForm.errors.has('payoutTestUrl') && <span className="error-message"> {pspForm.errors.get("payoutTestUrl")}  </span> }
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Live Payout URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('payoutLiveUrl') ? 'error-field' : "") }
                            value={ pspForm.payoutLiveUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'payoutLiveUrl') }/>
                          { pspForm.errors.has('payoutLiveUrl') && <span className="error-message"> {pspForm.errors.get("payoutLiveUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Demo Refund URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('refundTestUrl') ? 'error-field' : "") }
                            value={ pspForm.refundTestUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'refundTestUrl') }/>
                          { pspForm.errors.has('refundTestUrl') && <span className="error-message"> {pspForm.errors.get("refundTestUrl")}  </span> }
                        </Column>
                      
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Live Refund URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('refundLiveUrl') ? 'error-field' : "") }
                            value={ pspForm.refundLiveUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'refundLiveUrl') }/>
                          { pspForm.errors.has('refundLiveUrl') && <span className="error-message"> {pspForm.errors.get("refundLiveUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input disabled={ this.isFieldDisabled() } type="checkbox" id="updatePayoutByFile" checked={ updatePayoutByFile } value={ updatePayoutByFile }
                                onChange={ () => this.setState({ updatePayoutByFile: !updatePayoutByFile }) }/>
                              <label style={ { fontWeight: "normal" } } htmlFor="updatePayoutByFile"> Update Payout by File </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input disabled={ this.isFieldDisabled() } type="checkbox" id="enableRefund" checked={ enableRefund } value={ enableRefund }
                                onChange={ () => this.setState({ enableRefund: !enableRefund }) }/>
                              <label style={ { fontWeight: "normal" } } htmlFor="enableRefund"> Enable Refund </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input disabled={ this.isFieldDisabled() } type="checkbox" id="statusApiDeposit" checked={ statusApiDeposit } value={ statusApiDeposit }
                                onChange={ () => this.setState({ statusApiDeposit: !statusApiDeposit }) }/>
                              <label style={ { fontWeight: "normal" } } htmlFor="statusApiDeposit"> Enable Status Api Deposit </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input disabled={ this.isFieldDisabled() } type="checkbox" id="statusApiPayout" checked={ statusApiPayout } value={ statusApiPayout }
                                onChange={ () => this.setState({ statusApiPayout: !statusApiPayout }) }/>
                              <label style={ { fontWeight: "normal" } } htmlFor="statusApiPayout"> Enable Status Api Payout </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input disabled={ this.isFieldDisabled() } type="checkbox" id="defaultCallbackUrl" checked={ defaultCallbackUrl } value={ defaultCallbackUrl }
                                onChange={ () => this.setState({ defaultCallbackUrl: !defaultCallbackUrl }) }/>
                              <label style={ { fontWeight: "normal" } } htmlFor="defaultCallbackUrl"> Enable Default Callback URL </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          { viewOrEdit !== 1 ? (
                            <Button
                              type="submit"
                              className="btn defaultBtn"
                              onClick={ this.submitPSPSettings }
                            >
                              Save
                            </Button>
                          ) : (
                            <span/>
                          )}
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            )}
            { viewOrEdit && (
              <Panel>
                <Panel.Heading>
                  DEFAULT SETTINGS
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content report-content" style={ { overflow: 'unset'} } >
                    <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: "100%", overflow: 'auto'} }>
                      { buysTable && buysTable.fields && buysTable.fields.length > 0 && (
                        <div className="settings-table-wrapper">
                          <table className="table table-striped settings-table">
                            <thead>
                              <tr>
                                <th> Payment Method </th>
                                <th> Currency </th>
                                { buysTable.fields.map(column => <th key={ column.name }> {column.label} </th>) }
                              </tr>
                            </thead>
                            <tbody>
                              { buysTable.rows.map(row => <tr key={ row.id }>
                                <td> { language.en.methods[row.paymentMethod] } </td>
                                <td> { row.currency } </td>
                                {
                                  buysTable.fields.map(column => {
                                    return <td key={ column.name }
                                      onDoubleClick={ () => this.onTableDataClick(row.defaults[column.name], column.name, row.id, "buysClickMap", "buysRefs") }
                                      className="td-element">
                                      {
                                        buysClickMap[row.id][column.name] ?
                                          <span>
                                            { this.getValue(row.defaults[column.name]) }
                                          </span> :
                                          <input className={ `table-input ${buysErrorsMap[row.id][column.name] ? "td-error" : ""}` }
                                            type="number" value={ this.getValue(row.defaults[column.name]) }
                                            ref={ (input) => this.setRef(input, row.id, column.name, "buysRefs") }
                                            onKeyPress={ (event) => this.onKeyPress(event, row.defaults[column.name], row.id, column.name, "buysClickMap", "buysRefs") }
                                            onChange={ (event) => this.onChangeGeneralSettings(event, column.name, "buysTable", row.id) }
                                            onBlur={ () => this.onTableDataClick(row.defaults[column.name], column.name, row.id, "buysClickMap", "buysRefs") }
                                            onDoubleClick={ (e) => e.stopPropagation() }/>
                                      }
                                      {
                                        column.isPercent && <span className="percent-sign"> % </span>
                                      }
                                    </td>;
                                  })
                                }
                              </tr> )
                              }
                            </tbody>
                          </table>
                        </div>
                      )}
                      { payoutsTable && payoutsTable.fields && payoutsTable.fields.length > 0 && (
                        <div className="settings-table-wrapper">
                          <table className="table table-striped settings-table">
                            <thead>
                              <tr>
                                <th> Payout Method </th>
                                <th> Currency </th>
                                { payoutsTable.fields.map(column => <th key={ column.name }> {column.label} </th>) }
                              </tr>
                            </thead>
                            <tbody>
                              { payoutsTable.rows.map(row => <tr key={ row.id }>
                                <td> { language.en.methods[row.paymentMethod] } </td>
                                <td> { row.currency } </td>
                                {
                                  payoutsTable.fields.map(column => {
                                    return <td key={ column.name }
                                      onDoubleClick={ () => this.onTableDataClick(row.defaults[column.name], column.name, row.id, "payoutsClickMap", "payoutsRefs") }
                                      className="td-element">
                                      {
                                        payoutsClickMap[row.id][column.name] ?
                                          <span>
                                            { this.getValue(row.defaults[column.name]) }
                                          </span> :
                                          <input className={ `table-input ${payoutsErrorsMap[row.id][column.name] ? "td-error" : ""}` }
                                            type="number" value={ this.getValue(row.defaults[column.name]) }
                                            ref={ (input) => this.setRef(input, row.id, column.name, "payoutsRefs") }
                                            onKeyPress={ (event) => this.onKeyPress(event, row.defaults[column.name], row.id, column.name, "payoutsClickMap", "payoutsRefs") }
                                            onChange={ (event) => this.onChangeGeneralSettings(event, column.name, "payoutsTable", row.id) }
                                            onBlur={ () => this.onTableDataClick(row.defaults[column.name], column.name, row.id, "payoutsClickMap", "payoutsRefs") }
                                            onDoubleClick={ (e) => e.stopPropagation() }/>
                                      }
                                      {
                                        column.isPercent && <span className="percent-sign"> % </span>
                                      }
                                    </td>;
                                  })
                                }
                              </tr> )
                              }
                            </tbody>
                          </table>
                        </div>
                      )}
                    </Row>
                    { ((buysTable && buysTable.rows.length > 0) || (payoutsTable && payoutsTable.rows.length > 0)) && (
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='end'>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' className="input-column">
                            { viewOrEdit !== 1 ? <Button
                              type="submit"
                              className="btn defaultBtn"
                              onClick={ this.saveDefaultSettings }>
                              Save
                            </Button> : <span/> }
                          </Column>
                        </Row>
                      </Row>
                    )}
                  </div>
                </Panel.Body>
              </Panel>
            )}
  
            {this.checkPageAccess('PSP_ACCOUNT_EDIT') && pspId && (
              <Panel>
                <Panel.Heading>
                  PROVIDER ACCOUNTS
                </Panel.Heading>
                <Panel.Body>
                  {isLoadingAccounts ? (
                    <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content provider-accounts">
                      { accountForms.length > 0 && accountForms.map((accountForm, index) => 
                        <Row
                          key={ index }
                          flexGrow={ 1 }
                          horizontal='start'
                          wrap
                          vertical='end'
                          style={ { width: '100%' } }
                          className="account-row"
                        >
                          { isAbleToEditAccounts && (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Auth Key </label>
                              <input
                                className={ "form-control " + (accountForm.errors.has('authKey') ? 'error-field' : "") }
                                value={ accountForm.authKey || '' }
                                disabled={ this.isFieldDisabled() }
                                onChange={ (event) => this.onAccountItemChange(event, index, 'authKey') }
                              />
                            </Column>
                          )}
                          { isAbleToEditAccounts && (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Secret Key </label>
                              <input
                                className={ "form-control " + (accountForm.errors.has('secretKey') ? 'error-field' : "") }
                                value={ accountForm.secretKey || '' }
                                disabled={ this.isFieldDisabled() }
                                onChange={ (event) => this.onAccountItemChange(event, index, 'secretKey') }
                              />
                            </Column>
                          )}
                          { !isAbleToEditAccounts && (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Provider </label>
                              <input
                                className="form-control"
                                value={ pspForm.name || '' }
                                disabled={ true }
                                onChange={ (event) => this.onValueChange(event, 'name') }
                              />
                            </Column>
                          )}
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Account Name </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('name') ? 'error-field' : "") }
                              value={ accountForm.name || '' }
                              disabled={ this.isFieldDisabled() }
                              onChange={ (event) => this.onAccountItemChange(event, index, 'name') }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <Row horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                              <Column vertical='center'>
                                <label style={ { marginBottom: "10px" } }> Status </label>
                                {accountStatusLoader.length && accountStatusLoader.indexOf(index) > -1 ?
                                  <div className="loading-wrapper">
                                    <img src={ loadingIcon } alt="loading" />
                                  </div>
                                  : (
                                    <Toggler
                                      active={ accountForm.enabled }
                                      disabled={ accountForm.frozen }
                                      onClick={ (event) => this.onToggleAccountItemStatus(event, index, 'enabled', accountForm.id) }
                                    />
                                  )}
                                
                              </Column>
                              { isAbleToEditAccounts && viewOrEdit !== 1 && (
                                <Column vertical='center' className="actions">
                                  {!accountForm.id && (
                                    <button
                                      className="delete"
                                      onClick={ () => this.onDeleteAccountItem(index) }
                                    >
                                      X
                                    </button>
                                  )}
                                    
                                  {index === accountForms.length - 1 && viewOrEdit !== 1 && (
                                    <button
                                      className="add"
                                      onClick={ () => this.onAddAccountItem() }
                                    >
                                      <span className="plus"> + </span>
                                    </button>
                                  )}
                                </Column>
                              )}
                            </Row>
                          </Column>
                          { !isAbleToEditAccounts && (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <span/>
                            </Column>
                          )}
                        </Row>
                      )}
                      {isAbleToEditAccounts && accountForms.length === 0 && viewOrEdit !== 1 && (
                        <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                          <Column flexGrow={ 1 } horizontal='start' alignSelf='start' className="input-column" style={ { flexDirection: 'row', alignItems: 'center' } }>
                            Add Account
                            <button
                              className="add"
                              onClick={ () => this.onAddAccountItem() }
                            >
                              <span className="plus"> + </span>
                            </button>
                          </Column>
                        </Row>
                      )}
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='end'>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' className="input-column">
                            { viewOrEdit !== 1 ? <Button
                              type="submit"
                              className="btn defaultBtn"
                              disabled={ !accountForms.length }
                              onClick={ () => this.saveProviderAccounts() }>
                              Save
                            </Button> : <span/> }
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            )}
          </Column>
        </Row>
      </Column>
      <SweetAlert
        show={ showSuccess }
        title="Success"
        type="success"
        confirmButtonColor="#25282a"
        text={ `Provider successfully ${viewOrEdit === 2 ? 'updated' : 'saved'}.` }
        onConfirm={ this.onConfirm }
      />
      <SweetAlert
        show={ showError }
        title="Error"
        type="error"
        confirmButtonColor="#25282a"
        text={ errorMessage }
        onConfirm={ this.onConfirm }
      />
    </Row>;
  }
};

export default ManagePSP;