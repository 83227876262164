import React, { Component } from 'react';
import { Panel } from "react-bootstrap";
//import Spinner from '../../../Spinner';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DASHBOARD_HISTOGRAM_EVENT } from '../../../../actions/types';

const store =  require('../../../../reducers/index');

am4core.useTheme(am4themes_animated);

class DashBoardTransactionsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      histogramData: undefined,
      loading: true,
      socket: false
    };
  }

  subscribeFunction = null;
  chart = null;

  createChart = () => {
    if (this.chart) {
      this.chart.dispose();
    }

    const chart = am4core.create("chart-hist", am4charts.XYChart);

    chart.responsive.enabled = true;
    chart.background.visible = false;
    this.chart = chart;
  };

  componentDidMount() {
    this.createChart();

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      const histogramData = state.histogramData;

      if (!histogramData || !histogramData.stats) {
        return;
      }

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });

        return;
      }

      if (state.update === DASHBOARD_HISTOGRAM_EVENT && ((this.props.thisMonth && histogramData.socket) || !histogramData.socket)) {
        this.setState({ loading: false, socket: histogramData.socket });
      }

      this.createChart();
      const stats = Object.assign({}, histogramData.stats);
      const categories = Object.keys(stats);

      if (categories.length === 0) {
        return;
      }

      categories.sort();

      const seriesNames = Object.keys(stats[categories[0]]).filter(seriesName => seriesName !== "category" && seriesName !== "position");
      const seriesNamesLength = seriesNames.length;

      const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.autoGridCount = true;
      categoryAxis.fontSize = 12;

      categoryAxis.renderer.labels.template.rotation = 90;
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.horizontalCenter = "middle";

      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

      categoryAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;

      const colors = {
        ALIPAY: am4core.color("#0080FF"),
        QUICKPAY: am4core.color("#588BAE"),
        BANKPAY: am4core.color("#000080"),
        UNIONPAY: am4core.color("#0E4D92"),
        UNIONPAY_INTERNATIONAL: am4core.color("#1034A6"),
        UNIONPAY_INTERNATIONAL_EURO: am4core.color("#7EF9FF"),
        BANK_TRANSFER: am4core.color("#0F52BA"),
        CREDIT_CARD: am4core.color("#008ECC"),
        COD: am4core.color("#6593F5"),
        PAYTM: am4core.color("#73C2FB"),
        NETBANKING: am4core.color("#4682B4"),
        UPI: am4core.color("#3FE0D0"),
        DEBIT_CARD: am4core.color("#95C8D8")
      };

      const formatSeriesName = (name) => {
        const lower = name.toLowerCase().replace(/_/g, " ");
        let result = lower.charAt(0).toUpperCase();
        const length = lower.length;
        for (let i = 1; i < length; i++) {
          const char = lower.charAt(i);
          if (char === " ") {
            result += char;
            i++;
            result += lower.charAt(i).toUpperCase();
          } else {
            result += char;
          }
        }

        return result;
      };

      for (let i = 0; i < seriesNamesLength; i++) {
        const seriesName = seriesNames[i];
        const series = this.chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(100);
        series.columns.template.strokeWidth = 0;
        series.columns.template.fill = colors[seriesName];
        series.name = formatSeriesName(seriesName);
        series.dataFields.categoryX = "category";
        series.dataFields.valueY = seriesName;
      }

      this.chart.legend = new am4charts.Legend();
      this.chart.legend.maxHeight = 150;
      this.chart.legend.scrollable = true;

      const formattedData = categories.map((category, i) => {
        return Object.assign(stats[category], {
          category: category
        });
      });

      formattedData.sort((elemA, elemB) => {
        return elemA.position - elemB.position;
      });

      this.chart.data = formattedData;
    });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }

    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  render() {
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title> TRANSACTIONS LAST 12 MONTHS </Panel.Title>
        </Panel.Heading>
        <Panel.Body >
          <div className="panel-content">
            <div id="chart-hist" style={ { width: "100%", minHeight: "500px" } }/>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

export default DashBoardTransactionsComponent;