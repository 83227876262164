import React, {Component} from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';

import ComponentHeader from "../../componentHeader";
import Spinner from '../../Spinner';

import '../../../assets/css/sandbox.css';
import UserManagementIcon from '../../../assets/images/user-management.png';

import { crudActions } from "../../../services/crudActions";

class UploadBlacklist extends Component {
  state = {
    showError: false,
    errorMessage: "",
    showSuccess: false,
    isLoading: false
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: ""
    });
  }

  onFileChange = (e) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (file) {
      const acceptedFormats = ["xlsx", "xls"];
      const splittedName = file.name.split(".");
      const extension = splittedName[splittedName.length - 1];
      if (!acceptedFormats.includes(extension)) {
        this.setState({
          showError: true,
          errorMessage: "Wrong file format."
        });
      } else {
        this.setState({
          fileUploaded: e.target.files.length ? e.target.files[0] : ""
        });
      }
    }
  };

  onAlertConfirm = () => {
    this.setState({
      showError: false,
      showSuccess: false
    });
  };

  onUpload = () => {
    const { fileUploaded } = this.state;

    if (fileUploaded) {
      this.setState({
        isLoading: true
      });
      const formData = new FormData();
      formData.append("file", fileUploaded);
      crudActions.post(`v1/blacklist/upload`, formData, {}, true).then(
        () => {
          this.setState({
            showSuccess: true,
            fileUploaded: "",
            isLoading: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    }
  };

  render() {
    const {
      errorMessage,
      fileUploaded,
      isLoading,
      showError,
      showSuccess
    } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module sandbox blacklist" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title={ 'Upload Blacklist' }
            img={ UserManagementIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    UPLOAD BLACKLIST
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                        <label> Upload XLSX file </label>
                        <input
                          id="file-upload"
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={ (e) => this.onFileChange(e) }
                        />
                        <label htmlFor="file-upload" className="file-upload">
                          { !fileUploaded ? "No files uploaded yet." : fileUploaded.name}
                          <span className="browse"> Browse... </span>
                        </label>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                        <Button
                          type="submit"
                          className="btn defaultBtn"
                          disabled={ isLoading }
                          onClick={ () => this.onUpload() }>
                          UPLOAD
                        </Button>
                      </Column>
                    </Row>
                  </div>
                  { isLoading && (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text="Blacklist successfully uploaded."
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#DD6B55"
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default UploadBlacklist;
