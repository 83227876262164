import React, { Component } from "react";
import config from '../../../config';

class ReCaptchaValidation extends Component {
  _isMounted = false;

  static defaultProps = {
    handleChange: null
  };

  state = {
    Recaptcha: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.importRecaptcha();
  }

  importRecaptcha = async () => {
    window.recaptchaOptions = {
      useRecaptchaNet: true,
      removeOnUnmount: true
    };

    const {
      default: Recaptcha
    } = await import('react-google-recaptcha');

    if (this._isMounted) {
      this.setState({ Recaptcha });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    delete window.recaptchaOptions;
  }

  render () {
    const { handleChange } = this.props;
    const { Recaptcha } = this.state;

    return (
      <div>
        {Recaptcha && (
          <Recaptcha
            sitekey={ config.captchaSiteKey }
            onChange={ handleChange }
          />
        )}
      </div>
    );
  }
};

export default ReCaptchaValidation;