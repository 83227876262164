import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, Button } from "react-bootstrap";
import Dropdowns from './dropdowns';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { Column, Row } from 'simple-flexbox';

import DashBoardRevenueComponent from "./dashBoardPanelComponents/DashBoardRevenueComponent";
import DashBoardPurchasesComponent from "./dashBoardPanelComponents/DashBoardPurchasesComponent";
import DashBoardPayoutComponent from "./dashBoardPanelComponents/DashBoardPayoutComponent";
import DashBoardPurchaseCountriesComponent from "./dashBoardPanelComponents/DashBoardPurchaseCountriesComponent";
import DashBoardCustomerBalancesComponent from "./dashBoardPanelComponents/DashBoardCustomerBalancesComponent";
import LastUpdateComponent from "./dashBoardPanelComponents/LastUpdateComponent";
import DashBoardPayoutCountriesComponent from "./dashBoardPanelComponents/DashBoardPayoutCountriesComponent";
import DashBoardDepositsPerMidComponent from "./dashBoardPanelComponents/DashBoardDepositsPerMidComponent";
import DashBoardPayoutsPerMidComponent from "./dashBoardPanelComponents/DashBoardPayoutsPerMidComponent";
import DashBoardDepositVolumePerMidComponent from './dashBoardPanelComponents/DashBoardDepositVolumePerMidComponent';
import DashBoardTransactionsComponent from "./dashBoardChartsComponents/DashBoardTransactionsComponent";
import Multiselect from "../customMultiselect";
import Select from "../Select";

import "react-datepicker/dist/react-datepicker.css";
import 'react-select/dist/react-select.css';
import '../../../assets/css/apiData.css';
import Calendar from '../../../assets/images/calendar.png';

import * as actions from '../../../actions';
import { backEndBaseUrl } from "../../../config";
import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
import { DASHBOARD_SELLS_EVENT, DASHBOARD_PURCHASES_EVENT, DASHBOARD_FTD_EVENT,
  DASHBOARD_REVENUE_EVENT, DASHBOARD_USERS_EVENT,
  DASHBOARD_PURCHASE_COUNTRIES_EVENT, DASHBOARD_CUSTOMER_BALANCES_EVENT,
  DASHBOARD_HISTOGRAM_EVENT, DASHBOARD_WITHDRAWALS_EVENT,
  DASHBOARD_PAYOUT_COUNTRIES_EVENT, DASHBOARD_PAYOUT_EVENT, DASHBOARD_DEPOSITS_PER_MIDS,
  DASHBOARD_PAYOUTS_PER_MIDS, DASHBOARD_AMOUNTS_PER_MID } from '../../../actions/types';
import {crudActions} from "../../../services/crudActions";
const combineReducers =  require('../../../reducers/index');

class APIData extends Component {
  state = {
    controls: {
		  timePeriod: {
        value:'today'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dropdowns: {
      periods: Dropdowns.periods,
      clients: Dropdowns.clients
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(0).from,
      toDateTimeStamp: this.getPeriod(0).to
    },
    dateFieldDisabled: false,
    clients: [],
    merchants: [],
    selectedMerchants: [],
    selectedPlatform: "all",
    clientError: null,
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),

    clientChosen: false,
    periodId: 0  //today
  };

  subscribed = true;
  thisMonth = false;
  timeout = 250;
  reconnect = true;
  connectTimeout = null;
  heartbeatInterval = null;
  heartbeatMessage = "ping";

  componentDidMount() {
    this.loadRestData().then(
      () => {
        // this.connect();
      }
    );
  };

  componentWillUnmount() {
    if (this.state.ws) {
      this.state.ws.close();
    }

    this.reconnect = false;
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.connectTimeout);
  };

  connect = () => {
    const user = JSON.parse(localStorage.user);
    const ws = new WebSocket(
      (window.location.protocol === "https:" ? "wss" : "ws") + "://" + backEndBaseUrl.split("/")[2] + "/dashboard-ws", //?token=" + user.accessToken
      //possible solution for putting token in headers
      [user.accessToken]
    );
    const that = this;

    ws.onopen = () => {
      console.log("WebSocket connected"); // eslint-disable-line no-console

      this.setState({
        ws: ws
      });

      const heartbeatMessage = this.heartbeatMessage;

      if (this.heartbeatInterval === null) {
        this.heartbeatInterval = setInterval(function() {
          ws.send(heartbeatMessage);
        }, 60000);
      }

      that.timeout = 250;
      clearTimeout(this.connectTimeout);
    };

    ws.onclose = e => {
      console.log( // eslint-disable-line no-console
        `Socket is closed. Reconnect will be attempted in ${(that.timeout * 2) / 1000} second.`,
        e
      );

      that.timeout = that.timeout * 2;
      this.connectTimeout = setTimeout(this.check, Math.min(10000, that.timeout));
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    };

    ws.onmessage = evt => {
      if (evt.data === "pong") {
        return;
      }
      const message = JSON.parse(evt.data);
      this.updateDashboard(message);
    };

    ws.onerror = err => {
      console.error( // eslint-disable-line no-console
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  check = () => {
    const { ws } = this.state;
    if ((!ws || ws.readyState === WebSocket.CLOSED) && this.reconnect) {
      // this.connect();
    }
  };

  updateDashboard = (data) => {
    this.setState({
      lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
    });

    switch(data.type) {
    case 'USERS':
      combineReducers.default.dispatch({type: DASHBOARD_USERS_EVENT, socket: true, payload: data.data});
      break;
    case 'REVENUE':
		 combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, socket: true, payload: data.data});
      break;
    case 'SELL_REQUESTS'://deprecated
      combineReducers.default.dispatch({type: DASHBOARD_SELLS_EVENT, socket: true, payload: data.data});
      break;
    case 'PURCHASE_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, socket: true, payload: data.data});
      break;
    case 'DEPOSITS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, socket: true, payload: data.data});
      break;
    case 'PAYOUTS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, socket: true, payload: data.data});
      break;
    case 'AMOUNTS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, socket: true, payload: data.data});
      break;
    case 'WITHDRAWALS_PER_COUNTRY'://deprecated
      combineReducers.default.dispatch({type: DASHBOARD_WITHDRAWALS_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'FTD_STATS':
      combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, socket: true, payload: data.data.purchases});
      combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, socket: true, payload: data.data.ftds});
      break;
    case 'TRANSACTIONS':
      combineReducers.default.dispatch({type: DASHBOARD_HISTOGRAM_EVENT, socket: true, payload: data.data});
      break;
    case 'CUSTOMER_BALANCES':
      combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, socket: true, payload: data.data});
      break;
    case 'PAYOUT_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, socket: true, payload: data.data});
      break;
    case 'PAYOUT_REQUESTS':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, socket: true, payload: data.data});
      break;
    default:
      console.log('Wrong type ' + data.type); // eslint-disable-line no-console
    }
  }

  loadRestData = () => {
    return crudActions.get("v1/clients/all").then(response => {
      if (response) {
        const merchants = response.clients.map(currElem => {
          return currElem.client;
        });

        const updateObject = {
          merchants,
          selectedMerchants: merchants.map(elem => elem.value)
        };

        this.setState(updateObject);
      }
    });
  };

  loadDashboardData = () => {
    const { periodId, selectedMerchants, selectedPlatform, timeStamp } = this.state;
    const period = this.getPeriod(periodId);

    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().format("DD.MM.YYYY-HH:mm:ss");
    const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().format("DD.MM.YYYY-HH:mm:ss");

    combineReducers.default.dispatch({ type: "LOADING" });
    this.setState({
      lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
    });

    if (selectedMerchants && selectedMerchants.length) {
      crudActions.get(`v2/master/dashboard/revenue?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/country/purchases?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, socket: false, payload: data});
        //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, sse: false, payload: data.purchaseMethodsPie});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/payout?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/ftd?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, socket: false, payload: data.purchases});
          combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, socket: false, payload: data.ftds});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/customer/balances?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/transactions?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_HISTOGRAM_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/deposits-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/payouts-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/amounts-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/country/payout?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${selectedMerchants.join(',')}&platform=${selectedPlatform}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });
    }
  };

  onChangeFilter = (e) => {
    const { /*ws,*/ selectedMerchants } = this.state;
    e.preventDefault();
    this.subscribed = this.thisMonth;
    if (selectedMerchants && selectedMerchants.length) {
      // ws.send(
      //   JSON.stringify({
      //     subscribed: this.subscribed
      //   })
      // );

      this.setState({
        clientChosen: true,
        clientError: false
      });

      this.loadDashboardData();
    } else {
      this.setState({
        clientError: true
      });
    }
  };

  mapSelectedMerchants = () => {
    const { selectedMerchants, merchants } = this.state;

    return selectedMerchants.map(elem => {
      return merchants.find(client => client.value === elem);
    });
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().subtract(1, 'day').hour(23).minute(59).second(59),
        id: periodType
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().hour(23).minute(59).second(59),
        id: periodType
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth()).hour(23).minute(59).second(59),
        id: periodType
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().hour(23).minute(59).second(59),
        id: periodType
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31).hour(23).minute(59).second(59),
        id: periodType
      };
    case 6: // last hour
      return {
        from: moment().utc().subtract(1, 'hour'),
        to: moment().utc(),
        id: periodType
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().subtract(6, 'hour'),
        to: moment().utc(),
        id: periodType
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().hour(0).minute(0).second(0),
        to: moment().utc(),
        id: periodType
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id <= 7; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      periodId: value.id
    });
  };

  onSelectChange = (value, fieldName) => {
    const newValue = value.value;

    const updateObject = {
      [fieldName]: newValue
    };

    this.setState(updateObject);
  };

  onMerchantsChange = (newValue, type) => {
    const { clientError } = this.state;
    const updateObject = {
      [type]: newValue.map(elem => elem.value),
    };

    if (clientError !== null) {
      updateObject.clientError = !newValue.length;
    }

    this.setState(updateObject);
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = { ...this.state.controls };
    const timeStamp = { ...this.state.timeStamp };

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({ controls: controls, timeStamp: timeStamp, isSubmitting: false });
  };

  isDateValid = (current, state) => {
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;
    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  render() {

    const controls = {...this.state.controls};
    const {
      clientChosen,
      clientError,
      dateFieldDisabled,
      dropdowns,
      isSubmitting,
      lastUpdate,
      merchants,
      selectedMerchants,
      timeStamp
    } = this.state;
    const thisMonth = this.thisMonth;

    return (
      <Row flexGrow={ 1 } className='module apidata' vertical='start'>
        <Column flexGrow={ 1 }>
          <LastUpdateComponent lastUpdate={ lastUpdate }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%'} }>
              <Panel>
                <Panel.Heading>
                  <Panel.Title> MERCHANT & TIME PERIOD</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <form>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Merchants </label>
                          <Multiselect
                            isError={ clientError }
                            disabled={ false }
                            selectedItems={ this.mapSelectedMerchants() }
                            items={ merchants }
                            type={ "selectedMerchants" }
                            onChange={ this.onMerchantsChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>Time Period</label>
                          <Select id="timePeriod"
                            name="timePeriod"
                            value={ controls.timePeriod.value || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ dropdowns.periods }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column first-datepicker" alignSelf='start'>
                          <label>From Date</label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                            disabled={ dateFieldDisabled || selectedMerchants.length === 0 }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column" alignSelf='start'>
                          <label>To Date</label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                            disabled={ dateFieldDisabled || selectedMerchants.length === 0 }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'from') }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <label style={ { minHeight: "20px" } }/>
                          <Button
                            type="button"
                            className="btn defaultBtn"
                            disabled={ isSubmitting }
                            onClick={ (e) => this.onChangeFilter(e) }>
                            Filter
                          </Button>
                        </Column>
                      </Row>
                    </form>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
            { clientChosen && <Column flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%' } }>
              <DashBoardRevenueComponent thisMonth={ thisMonth }/>
            </Column> }
           
            { clientChosen && <Column flexGrow={ 1 } className="table-column" vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%' } }>
              <DashBoardDepositsPerMidComponent thisMonth={ thisMonth }/>
            </Column> }

            { clientChosen && <Column flexGrow={ 1 } className="table-column" vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%' } }>
              <DashBoardPayoutsPerMidComponent thisMonth={ thisMonth }/>
            </Column> }
            
            { clientChosen &&<Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column flexGrow={ 1 } className="table-column" vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%' } }>
                <DashBoardDepositVolumePerMidComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPurchaseCountriesComponent thisMonth={ thisMonth }/>
              </Column>
            </Row> }
            
            { clientChosen && <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPayoutCountriesComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPurchasesComponent thisMonth={ thisMonth }/>
              </Column>
            </Row> }

            { clientChosen && <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPayoutComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardTransactionsComponent thisMonth={ thisMonth }/>
              </Column>
            </Row> }
            { clientChosen && <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardCustomerBalancesComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <span/>
              </Column>
            </Row> }
          </Row>
        </Column>
      </Row>
    );
  }
}

export default connect(null, actions)(APIData);
