import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import Pagination from '@mui/material/Pagination';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';
import TextareaAutosize from 'react-textarea-autosize';
import { saveAs } from 'file-saver';

import ComponentHeader from "../../componentHeader";
import Dropdowns from '../apiData/dropdowns';
import Form from "../../../core/Form";
import Modal from '../Modal';
import Multiselect from "../customMultiselect";
import PromptModal from './PromptModal';
import Select from "../Select";
import Spinner from '../../Spinner';

import ReportIcon from '../../../assets/images/report.png';
import Calendar from '../../../assets/images/calendar.png';
import loadingIcon from '../../../assets/images/loading.png';

import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/generateReports.css";
import '../../../assets/css/apiData.css';

import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
import { FETCH_PERMISSIONS } from '../../../actions/types';
import { crudActions } from "../../../services/crudActions";
import { SEARCH_TRANSACTIONS_REGEX } from "../../../constants";

const INTEGER_MAX = 2147483647;

const store = require('../../../reducers/index');

class GenerateReport extends Component {
  state = {
    controls: {
      timePeriod: {
        value: 'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),
    merchants: [],
    psps: [],
    accounts: [],
    clientsMids: {},
    clientsPayoutMids: {},
    depositMethod: [],
    depositStatus: [],
    sortBy: [],
    sortByDate: [],
    reportType: [],
    countries: [],
    properties: [],
    payoutMethod: [],
    payoutCountry: [],
    payoutStatus: [],
    settlementStatuses: [],
    extendWith: [],
    currency: [],
    baseCurrencies: [],
    displayMode: [],
    midIds: [],
    timeZones: [],
    timeStampList: [],
    reportFormRules: [{
      name: "clientIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "sortBy",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "reportType",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "timeStampList",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "timeZone",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "displayMode",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "sortByDate",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "currency",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositMethod",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositStatus",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      value: "",
      rules: {
        trim: true,
        required: false,
        pattern: SEARCH_TRANSACTIONS_REGEX
      }
    }],
    reportFormData: {
      clientIds: [],
      depositMethod: [],
      depositStatus: [],
      currency: [],
      sortBy: "",
      reportType: "",
      displayMode: "",
      transactionId: "",
      sortByDate: "DAY",
      midIds: [],
      timeZone: "",
      timeStampList: null
    },
    reportForm: {},
    extendWithMap: {
      RUNNING_BALANCE: {}
    },

    reportsGenerated: {},
    savedGeneratedReport: {},

    endpointGenerateMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/list"
      },
      PAYOUT: {
        LIST: "v1/reports/payout"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list"
      },
      RISK_AND_SECURITY: {
        LIST: "v1/reports/list/fraud"
      },
      FRAUD_CHECK: {
        LIST: "v1/reports/list/fraud-check"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/deposits_payouts"
      }
    },

    defaultTimePeriods: {
      GROSS_PROFIT: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SERVER_LOGS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      TRANSACTIONS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      PAYOUT: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      RUNNING_BALANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SETTLEMENTS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      RISK_AND_SECURITY: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      FRAUD_CHECK: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      PERFORMANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      DEPOSITS_PAYOUTS: {
        id: 0,
        value: 'today',
        label: 'Today'
      }
    },

    endpointXlsxMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list/xlsx",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed/xlsx"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions/xlsx"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/xlsx"
      },
      PAYOUT: {
        LIST: "v1/reports/payout/xlsx"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list/xlsx"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list/xlsx"
      },
      RISK_AND_SECURITY: {
        LIST: "v1/reports/list/fraud/xlsx"
      },
      FRAUD_CHECK: {
        LIST: "v1/reports/list/fraud-check/xlsx"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance/xlsx"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/deposits_payouts/xlsx"
      }
    },

    modalData: "{}",
    modalType: "json",
    modalTitle: "",

    isLoading: false,
    isLoadingLookups: true,
    showSuccess: false,
    showError: false,
    errorMessage: "",
    alertType: "error",
    successMessage: "",
    enableButton: false,

    access: [],
    blockLoader: [],
    isButtonLoading: "",
    loadingButtonType: "",
    roleId: "",
    isSortSettingsOpen: false,
    isExportSettingsOpen: false,
    isReportErrorFields: false,
    editableTransactionId: "",
    editableTransactionValue: "",
    editableTransactionField: "",
    showModal: false,
    isModalLoading: false,
    showPromptModal: false,
    changeTXPropertyLoading: false,
    changeValueMessage: "",
    currentPage: 1,
    rowsAmount: 100,
    clickButton: false,
    cnt: 0,
    pageType: "reports",
    periodId: 0 //today
  };

  sortNode = null;
  exportNode = null;
  thisMonth = true;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
    document.addEventListener("keydown", this.handleEscapeTransactionStatus, false);
    const { reportFormRules, reportFormData } = this.state;
    const { match } = this.props;
    this.setState({
      reportForm: new Form(reportFormData, reportFormRules),
      loading: false,
      pageType: match && match.path === "/transactions" ? "transactions" : "reports"
    });
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get(`v1/clients/all`).then(
      (data) => {
        if (data) {
          this.setState({
            merchants: data.clients.map(currElem => {
              return currElem.client;
            }),
            psps: data.psps.map(currElem => {
              return currElem;
            }),
            accounts: data.accounts.map(currElem => {
              return currElem;
            }),
            clientsMids: data.clients.reduce((accumulator, currentElem) => {
              accumulator[currentElem.client.value] = currentElem.mids.map(elem => elem);
              return accumulator;
            }, {}),
            clientsPayoutMids: data.clients.reduce((accumulator, currentElem) => {
              accumulator[currentElem.client.value] = currentElem.payoutMids.map(elem => elem);
              return accumulator;
            }, {}),
          }, () => {
            this.getReportsLookups();
          });
        }
      }
    );
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
    document.removeEventListener("keydown", this.handleEscapeTransactionStatus, false);
  };

  handleClickOutside = (event) => {
    if (this.sortNode && this.sortNode.contains(event.target)) {
      this.setState({
        isExportSettingsOpen: false
      });

      return;
    }

    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false,
      isSortSettingsOpen: false,
      showSuccess: false,
      showError: false,
      errorMessage: "",
      alertType: "error",
      showAlert: false,
      messageAlert: "",
      showInfo: false
    });
  };

  handleEscapeTransactionStatus = (event) => {
    if (event.keyCode === 27 && this.state.editableTransactionId !== "") {
      this.setState({
        editableTransactionId: "",
        editableTransactionValue: ""
      });
    }
  };

  getFields = () => {
    const { reportForm, sortBy, currency, extendWithMap, payoutStatus, merchants,
      depositMethod, depositStatus, depositPayoutStatus, properties, settlementStatuses } = this.state;

    const displayMode = reportForm.displayMode;
    const extendWithReportMap = extendWithMap[reportForm.reportType];
    const extendWithValue = extendWithReportMap ? extendWithReportMap[reportForm.extendWith] : "";

    let extendWithFilterItems = [];
    let extendWithFilterSelectedItems = [];
    if (extendWithValue) {
      extendWithFilterItems = extendWithValue.value === "merchants" ? merchants : this.state[extendWithValue.value];
      extendWithFilterSelectedItems = this.mapExtendWithFilter(extendWithValue.value);
    }

    const baseFields = [
      {
        id: "displayMode",
        type: "select",
        label: "Display Mode",
        disabled: false,
        options: this.filterDisplayMode()
      }, {
        id: "sortBy",
        type: "select",
        label: "Sort By",
        disabled: !reportForm.reportType,
        options: reportForm.reportType ? sortBy[reportForm.reportType] : []
      }, {}, {}
    ];

    const buttonField = [{
      id: "button",
      type: "button"
    }];
    
    const reportFields = {
      TRANSACTIONS: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: false,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: false,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          disabled: false,
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {
        }],
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(true, false)
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: false
        }, {}], [
          {}, {}, {
            id: "bulkButton",
            type: "bulkButton"
          }
        ]
      ],
      GROSS_PROFIT: [
        [{
          id: "extendWith",
          type: "select",
          label: "Extend with",
          notShowOnCondition: displayMode !== "LIST_DETAILED",
          disabled: false,
          options: this.filterExtendWith()
        }, {
          id: "properties",
          type: "multiselect",
          label: "Properties",
          notShowOnCondition: displayMode !== "LIST_DETAILED",
          selectedItems: this.mapSelectedItems("properties", "properties", true),
          items: reportForm.reportType ? properties[reportForm.reportType] : []
        }, {
          id: "extendWithFilter",
          type: "multiselect",
          notShowOnCondition: !extendWithValue,
          label: extendWithValue ? extendWithValue.label : "",
          selectedItems: extendWithFilterSelectedItems,
          items: extendWithFilterItems
        }, {}],
        [{}, {}, {
          id: "removeZeroValues",
          type: "checkbox",
          label: "Remove Zero Values"
        }]
      ],
      SERVER_LOGS: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: false,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: false,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          disabled: false,
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: false
        }],
        [{}, {}, {}]
      ],
      PAYOUT: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "midIds",
          type: "multiselect",
          disabled: false,
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(false, false)
        }, {
          id: "payoutStatus",
          type: "multiselect",
          label: "Payout Status",
          disabled: false,
          selectedItems: this.mapSelectedItems("payoutStatus", "payoutStatus"),
          items: payoutStatus
        }, {}],
        [{
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: false
        }, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      RUNNING_BALANCE: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "accountIds",
          type: "multiselect",
          label: "Accounts",
          selectedItems: this.mapSelectedItems("accountIds", "accountIds"),
          items: this.getAccounts()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(true, true)
        }, {}],
        [{}, {}, {}]
      ],
      SETTLEMENTS: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(true, false)
        }, {}],
        [{
          id: "settlementStatus",
          type: "multiselect",
          label: "Statuses",
          selectedItems: this.mapSelectedItems("settlementStatus", "settlementStatuses"),
          items: settlementStatuses
        }, {
          id: "invoiceNumber",
          type: "input",
          label: "Search Invoice #",
          disabled: false
        }, {}]
      ],
      RISK_AND_SECURITY: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: false,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: false,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: false
        }, {}],
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      FRAUD_CHECK: [
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "currency",
          type: "multiselect",
          disabled: false,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(true, false)
        }, {}],
        [{
          id: "depositMethod",
          type: "multiselect",
          disabled: false,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
          disabled: false
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      PERFORMANCE: [
        [{
          id: "email",
          type: "textarea",
          label: "Search Email",
          disabled: false
        }, {
          id: "ip",
          type: "textarea",
          label: "Search IP Address",
          disabled: false
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      DEPOSITS_PAYOUTS: [
        [{
          id: "currency",
          type: "multiselect",
          disabled: false,
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          disabled: false,
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          disabled: false,
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositPayoutStatus
        }, {
        }],
        [{
          id: "pspIds",
          type: "multiselect",
          label: "Providers",
          selectedItems: this.mapSelectedItems("pspIds", "pspIds"),
          items: this.getProviders()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidsByProvidersAndCurrencies(true, false)
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search",
          disabled: false
        }, {}], [
          {}, {}, {
            id: "bulkButton",
            type: "bulkButton"
          }
        ]
      ],
    };

    const buildFields = (type) => {
      const fields = reportFields[type];
      if (type === "RUNNING_BALANCE") {
        baseFields[2] = {
          id: "currency",
          type: "select",
          label: "Currency",
          disabled: false,
          options: this.getRunningBalanceCurrencies()
        };
      }
      fields.splice(0, 0, baseFields);
      fields[fields.length - 1] = fields[fields.length - 1].concat(buttonField);
      return fields;
    };

    const selectedReportFields = reportFields[reportForm.reportType];

    if (!selectedReportFields) {
      return buildFields('TRANSACTIONS');
    }

    return buildFields(reportForm.reportType);
  };

  defineReportForm = (reportType) => {
    const { reportForm, reportFormRules, reportFormData, merchants, timeStampList } = this.state;

    let selectedClient = "";
    if (merchants && merchants.length) {
      selectedClient = merchants[0].value;
    }

    if (!merchants.find(client => reportForm["clientIds"].includes(client.value))) {
      reportForm.clientIds = null;
    }

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const payoutForm = new Form({
      clientIds: reportForm.clientIds || [selectedClient],
      payoutStatus: [],
      currency: [],
      sortBy: "",
      reportType: reportType,
      displayMode: "",
      pspIds: [],
      midIds: [],
      sortByDate: reportForm.sortByDate,
      timeStampList: timeStampList,
      timeZone: "",
      transactionId: ""
    }, copyArray(reportFormRules.slice(0, 5)).concat([{
      name: "pspIds",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "midIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "payoutStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      rules: {
        trim: true,
        required: false,
        pattern: SEARCH_TRANSACTIONS_REGEX
      }
    }]));

    const grossProfitForm = new Form({
      clientIds: reportForm.clientIds || [selectedClient],
      reportType: reportType,
      displayMode: "",
      sortBy: "",
      sortByDate: reportForm.sortByDate,
      grossCurrency: "",
      removeZeroValues: false
    }, copyArray(reportFormRules.slice(0, 4)).concat(
      [{
        name: "grossCurrency",
        type: "isString",
        rules: {
          required: true
        }
      }]
    ));

    const runningBalanceForm = new Form(
      {
        clientIds: reportForm.clientIds || [selectedClient],
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        pspIds: [],
        accountIds: [],
        midIds: [],
        extendWithFilter: [],
        removeZeroValues: false,
        sortByDate: "DAY", 
        timeZone: "",
        currency: [],
      }, copyArray(reportFormRules.slice(0, 5)).concat(
        [{
          name: "extendWithFilter",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "accountIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            required: true
          }
        }]
      )
    );

    const settlementsForm = new Form(
      {
        clientIds: reportForm.clientIds || [selectedClient],
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        pspIds: [],
        midIds: [],
        currency : [],
        settlementStatus: [],
        invoiceNumber: "",
        removeZeroValues: false,
        sortByDate: "DAY"
      }, copyArray(reportFormRules.slice(0, 5)).concat(
        [{
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
            extendValue: "MIDS"
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
            extendValue: "CURRENCY"

          }
        }, {
          name: "settlementStatus",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "invoiceNumber",
          type: "isPattern",
          rules: {
            required: false,
            pattern: "^[0-9]+$"
          }
        }]
      )
    );

    const transactionsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        pspIds: reportForm.pspIds || [],
        midIds: reportForm.midIds || [],
        timeZone: "",
        timeStampList: timeStampList,
      }),
      copyArray(reportFormRules).concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }
      )
    );

    const serverLogsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
      }), copyArray(reportFormRules).filter(elem => elem.name !== "depositStatus")
    );

    const riskSecurityForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
      }), copyArray(reportFormRules).filter(elem => elem.name !== "depositStatus")
    );

    const fraudCheckForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        pspIds: [],
        midIds: [],
      }), copyArray(reportFormRules).filter(elem => elem.name !== "depositStatus").concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }
      )
    );

    const performanceForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        timeZone: "",
        timeStampList: timeStampList,
        email: "",
        ip: ""
      }),
      copyArray(reportFormRules).concat(
        {
          name: "email",
          type: "isString",
          rules: {
            required: false
          }
        },
        {
          name: "ip",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const depositsPayoutsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds || [selectedClient],
        displayMode: "",
        reportType: reportType,
        timeZone: "",
        timeStampList: timeStampList,
        pspIds: reportForm.pspIds || [],
        midIds: reportForm.midIds || []
      }),
      copyArray(reportFormRules).filter(elem => elem.name !== "transactionId").concat(
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "pspIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        },
        {
          name: "transactionId",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    if (reportType === "GROSS_PROFIT"){
      return grossProfitForm;
    } else if (reportType === "PAYOUT" ) {
      return payoutForm;
    } else if (reportType === "RUNNING_BALANCE" ) {
      return runningBalanceForm;
    } else if (reportType === "SETTLEMENTS" ) {
      return settlementsForm;
    } else if (reportType === "RISK_AND_SECURITY" ) {
      return riskSecurityForm;
    } else if (reportType === "FRAUD_CHECK" ) {
      return fraudCheckForm;
    } else if (reportType === "SERVER_LOGS") {
      return serverLogsForm;
    } else  if (reportType === "PERFORMANCE") {
      return performanceForm;
    } else if (reportType === "DEPOSITS_PAYOUTS"){
      return depositsPayoutsForm;
    } else {
      return transactionsForm;
    }
  };

  preselectReportFields = (reportType) => {
    const { sortBy, currency, depositMethod, depositStatus, depositPayoutStatus, properties, settlementStatuses, defaultTimePeriods,
      baseCurrencies, payoutStatus, timeZones, timeStampList, merchants, psps, pageType } = this.state;

    const displayMode = this.filterDisplayMode();
    const extendWith = this.filterExtendWith();
    const firstSortBy = sortBy[reportType] && sortBy[reportType][0];

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "RISK_AND_SECURITY" ||
      reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositMethod, "depositMethod");
    }

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE") {
      this.onValueChange(depositStatus, "depositStatus");
    }

    if (reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositPayoutStatus, "depositStatus");
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "RUNNING_BALANCE") {
      const defaultTimeZone = timeZones.find(zone => zone.value === "UTC");
      this.onValueChange(defaultTimeZone, "timeZone");
      this.onValueChange(timeStampList[1], "timeStampList");
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "SERVER_LOGS" ||
      reportType === "RISK_AND_SECURITY" || reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" ||
      reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(currency, "currency");
    }

    if (reportType === "RISK_AND_SECURITY") {
      this.onValueChange(merchants[0], "clientIds");
    }

    if (reportType === "PAYOUT") {
      this.onValueChange(payoutStatus, "payoutStatus");
      this.onValueChange(timeStampList[1], "timeStampList");
    }

    if (reportType === "GROSS_PROFIT") {
      this.onValueChange(baseCurrencies[0], "grossCurrency");
      this.onValueChange(properties[reportType], "properties");
    }

    if (reportType === "SETTLEMENTS") {
      const extendWithFirst = extendWith.filter(elem => elem.value !== firstSortBy.value)[0];
      this.onValueChange(extendWithFirst, "extendWith");
      this.onValueChange(settlementStatuses, "settlementStatus");
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "RUNNING_BALANCE" ||
      reportType === "SETTLEMENTS" || reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" ||
      reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(reportType === "RUNNING_BALANCE" ? merchants[0] : merchants, "clientIds");
      this.onValueChange(psps, "pspIds");
      if (reportType === "SETTLEMENTS") {
        this.onValueChange(currency, "currency");
      }
      if (reportType === "RUNNING_BALANCE") {
        const currencies = this.getRunningBalanceCurrencies();
        this.onValueChange(currencies[0], "currency");
        const accounts = this.getAccounts();
        this.onValueChange(accounts, "accountIds");
      }
      const mids = this.getMidsByProvidersAndCurrencies(reportType !== "PAYOUT", reportType === "RUNNING_BALANCE");
      this.onValueChange(mids, "midIds");
    }

    this.onValueChange(displayMode[0], "displayMode");
    this.onValueChange(firstSortBy, "sortBy");
    this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");

    if (pageType === "transactions") {
      this.onGenerateReport();
    }
  };

  getPeriod(periodType) {
    let timeZone = "00:00";
    if (this.state) {
      if (this.state.reportForm.timeZone) {
        timeZone = this.state.timeZones.find(zone => zone.value === this.state.reportForm.timeZone).offset;
      }
    }
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().zone(timeZone).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone).subtract(1, 'day').hour(23).minute(59).second(59)
      };
    case 2: // this month
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(moment().utc().zone(timeZone).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone)
      };
    case 3: // last month
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(moment().utc().zone(timeZone).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(moment().utc().zone(timeZone).month() - 1)
          .date(moment().utc().zone(timeZone).month(moment().utc().zone(timeZone).month() - 1).daysInMonth()).hour(23).minute(59).second(59)
      };
    case 4: // this year
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone)
      };
    case 5: // last year
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year() - 1).month(11).date(31).hour(23).minute(59).second(59)
      };
    case 6: // last hour
      return {
        from: moment().utc().zone(timeZone).subtract(1, 'hours'),
        to: moment().utc().zone(timeZone)
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().zone(timeZone).subtract(6, 'hours'),
        to: moment().utc().zone(timeZone)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().zone(timeZone).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone)
      };
    }
  };

  getReportsLookups = (event) => {
    const { reportForm, pageType } = this.state;

    if ( reportForm.reportType === "RUNNING_BALANCE" ) {
      reportForm.midIds = [];
    }
    this.setState({
      currentPage: 1
    }, () => {
      Promise.all([
        crudActions.get(`v1/reports/lookups`)
      ]).then(
        (data) => {
          let lookups = data[0];
          if (lookups) {
            // filter reports on Transactions page
            if (pageType === "transactions") {
              lookups = Object.assign(lookups, {
                reportType: lookups.reportType.filter(item => item.value === "DEPOSITS_PAYOUTS")
              });
            }
            // remove Activity report on FE, delete this after BE update
            const filtered = Object.assign(lookups, {
              reportType: lookups.reportType.filter(type => type.value !== "ACTIVITY"),
              sortBy: Object.assign(lookups.sortBy, {
                PERFORMANCE: [{
                  value: "EMAIL",
                  label: "Email"
                }, {
                  value: "IP",
                  label: "IP Address"
                }, {
                  value: "MERCHANT",
                  label: "Merchant"
                }, {
                  value: "PROVIDER",
                  label: "Provider"
                }]
              }),
              depositPayoutStatus: this.unionBy([lookups.depositStatus, lookups.payoutStatus], "value")
            });
            const reportType = lookups.reportType[0];
            this.setState({
              ...filtered,
              isLoadingLookups: false
            }, () => {
              this.onValueChange(reportType, "reportType");
            });
          }
        }
      );
    });
  };

  unionBy = (arrays, iteratee) => {
    const map = {};

    arrays.forEach((array) => {
      array.forEach((object) => {
        map[object[iteratee]] = object;
      });
    });

    return Object.values(map);
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = { ...this.state.timeStamp };
    const controls = { ...this.state.controls };
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id <= 7; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      currentPage: 1,
      periodId: value.id
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = { ...this.state.controls };
    const timeStamp = { ...this.state.timeStamp };

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({ controls: controls, timeStamp: timeStamp, isSubmitting: false });
  };

  isDateValid = (current, state) => {
    const { reportForm, timeZones } = this.state;
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;
    let timeZone = "00:00";
    if (reportForm.timeZone) {
      timeZone = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }
    const yesterday = Datetime.moment().utc().zone(timeZone).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  onValueChange = (newValue, fieldName) => {
    let { reportForm } = this.state;
    const { isReportErrorFields, extendWithMap, defaultTimePeriods } = this.state;
    if (newValue) {
      if (typeof newValue === "boolean") {
        reportForm = Object.assign(reportForm, {
          [fieldName]: !newValue
        });
      } else if (newValue.value) {
        reportForm = Object.assign(reportForm, {
          [fieldName]: fieldName === "clientIds" || fieldName === "pspIds" || fieldName === "currency" ? [newValue.value] : newValue.value
        });
      } else if (newValue.target) {
        reportForm = Object.assign(reportForm, {
          [fieldName]: newValue.target.value
        });
      } else {
        reportForm = Object.assign(reportForm, {
          [fieldName]: newValue.map(elem => elem.value)
        });
      }
    }

    if (isReportErrorFields) {
      reportForm.isFormValid(reportForm);
    }

    this.setState({
      currentPage: 1
    });

    const reportType = reportForm.reportType;
    const extendWithFilterField = reportForm.fieldRules.find(rule => rule.name === "extendWithFilter");
    const extendWithReport = extendWithMap[reportType];
    const extendWith = reportForm.extendWith;
    const extendWithField = reportForm.fieldRules.find(rule => rule.name === "extendWith");
    const propertiesField = reportForm.fieldRules.find(rule => rule.name === "properties");

    if ((fieldName === "extendWith" || fieldName === "sortBy") && extendWithReport) {
      const notRequired = !extendWithReport[extendWith] || !extendWith;
      extendWithFilterField.rules.min = notRequired ? 0 : 1;
      reportForm = Object.assign(reportForm, {
        extendWith: extendWith === reportForm.sortBy ? "" : extendWith,
        extendWithFilter: [],
      });
    } else if (fieldName === "displayMode") {
      if (extendWithFilterField && extendWithField) {
        const isListDetailed = reportForm[fieldName] === "LIST_DETAILED";
        extendWithField.rules.min = isListDetailed ? 1 : 0;
        extendWithField.rules.required = isListDetailed;
        extendWithFilterField.rules.min = isListDetailed && extendWithReport[extendWith] ? 1 : 0;
        propertiesField.rules.min = isListDetailed ? 1 : 0;
        propertiesField.rules.required = isListDetailed;

        reportForm = Object.assign(reportForm, {
          extendWith: "",
          extendWithFilter: [],
          properties: [],
        });
      }
    } else if ((fieldName === "currency" || fieldName === "accountIds") && (reportType === "RUNNING_BALANCE" || reportType === "SETTLEMENTS" || reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS")) {

      const mids = this.getMidsByProvidersAndCurrencies(true, reportType === "RUNNING_BALANCE");
      reportForm.midIds = mids.map(mid => mid.value);

    }  else if (fieldName === "clientIds" && (reportType === "RUNNING_BALANCE" || reportType === "PAYOUT" || reportType === "SETTLEMENTS" || reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS")) {
      
      const providers = this.getProviders();
      reportForm.pspIds = providers.map(psp => psp.value);
      const mids = this.getMidsByProvidersAndCurrencies(reportType !== "PAYOUT", reportType === "RUNNING_BALANCE");
      reportForm.midIds = mids.map(mid => mid.value);

    } else if (fieldName === "reportType" && reportType === "SETTLEMENTS") {
      
      delete reportForm.extendWith;

    } else if (fieldName === "pspIds") {
      if (reportType === "RUNNING_BALANCE") {
        const accounts = this.getAccounts();
        reportForm.accountIds = accounts.map(acc => acc.value);
      }
      const mids = this.getMidsByProvidersAndCurrencies(reportType !== "PAYOUT", reportType === "RUNNING_BALANCE");
      reportForm.midIds = mids.map(mid => mid.value);
    } else if ( reportType === "SERVER_LOGS") {
      delete reportForm.midIds;
      delete reportForm.timeStampList;
      delete reportForm.timeZone;
    } else if (fieldName === "timeZone" && (reportType === "RUNNING_BALANCE" || reportType === "PAYOUT" || reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS" || reportType === "PERFORMANCE")) {
      const currentPeriod = this.state.dropdowns.periods.find(elem => elem.id === this.state.controls.timePeriod.id);
      this.onSelectPeriodChange(currentPeriod, "timePeriod");
    }

    const isReportType = fieldName === "reportType";
    if (isReportType) {
      this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
    }

    this.setState({
      reportForm: isReportType ? this.defineReportForm(reportType) : reportForm
    }, () => {
      if (isReportType) {
        this.preselectReportFields(reportType);
      }
    });

    if (fieldName === "sortByDate") {
      this.setState({
        isSortSettingsOpen: false
      });
    }
  };

  getProviders = () => {
    const { psps } = this.state;
    return psps;
  };

  getAccounts = () => {
    const { accounts, reportForm } = this.state;
    if (reportForm.pspIds) {
      const filteredAccounts = accounts.filter(acc => reportForm.pspIds.includes(String(acc.pspId)));
      return filteredAccounts.map(item => ({
        value: String(item.id),
        label: item.label
      }));
    }
    
    return [];
  }

  getMidsByProvidersAndCurrencies = (filterByCurrency, filterByAccount) => {
    const {
      clientsMids,
      clientsPayoutMids,
      reportForm
    } = this.state;

    let mids = clientsMids;
    if (!filterByCurrency) {
      mids = clientsPayoutMids;
    }
    const filteredMids = [];
    if (mids && reportForm.pspIds && reportForm.currency) {
      for (const [key, value] of Object.entries(mids)) {
        if (reportForm.clientIds.includes(key)) {
          if (value.length) {
            value.forEach(item => {
              let condition = reportForm.pspIds.includes(item.pspId.toString());
              if (filterByCurrency) {
                condition = reportForm.pspIds.includes(item.pspId.toString()) && reportForm.currency.includes(item.currency);
              }
              if (filterByAccount && reportForm.accountIds) {
                condition = condition && reportForm.accountIds.includes(item.accountId.toString());
              }
              if (condition) {
                if (!filteredMids.some(e => e.value === item.value)) {
                  filteredMids.push(item);
                }
              }
            });
          }
        }
      }
      return filteredMids;
    }
    return [];
  };

  getRunningBalanceCurrencies = () => {
    const { reportForm, clientsMids } = this.state;

    if (!Object.keys(clientsMids).length || !reportForm.clientIds) {
      return [];
    }

    let mids = clientsMids[reportForm.clientIds];
    if (reportForm.clientIds.length > 1) {
      mids = [];
      reportForm.clientIds.forEach(clientId => {
        if (clientsMids[clientId]) {
          mids.push(...clientsMids[clientId]);
        }
      });
    }
    if (!mids) {
      return [];
    }
    const findCurrency = mids.map(mid => mid.currency);

    const setData = new Set(findCurrency);
    const uniqueCurrencyArray = Array.from(setData);
    uniqueCurrencyArray.sort((elemA, elemB) => elemA.localeCompare(elemB));
    const uniqueCurrencyObj = uniqueCurrencyArray.map(currency => {
      const obj = {};
      obj.value = currency;
      obj.label = currency;
      return obj;
    });

    return uniqueCurrencyObj;
  };

  mapSelectedItems = (type, lookupsName, useReportType) => {
    const { reportForm, merchants } = this.state;
    const field = reportForm[type];
    const { reportType } = reportForm;
    let lookup = null;
    if (lookupsName === "clientIds") {
      lookup = merchants;
    } else if (lookupsName === "pspIds") {
      lookup = this.getProviders();
    } else if (lookupsName === "midIds") {
      const shouldFilterByCurrency = reportType === "TRANSACTIONS" || reportType === "RUNNING_BALANCE" ||
        reportType === "SETTLEMENTS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS";
      lookup = this.getMidsByProvidersAndCurrencies(shouldFilterByCurrency, reportType === "RUNNING_BALANCE");
    } else if (lookupsName === "accountIds") {
      lookup = this.getAccounts();
    } else if (useReportType) {
      lookup = this.state[lookupsName][reportType];
    } else {
      if (type === "depositStatus" && reportType === "DEPOSITS_PAYOUTS") {
        lookup = this.state.depositPayoutStatus;
      } else {
        lookup = this.state[lookupsName];
      }
    }
    if (!field || !lookup || !Array.isArray(field) || field.some(elem => !lookup.find(lookupElem => elem === lookupElem.value))) {
      return [];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapExtendWithFilter = (type) => {
    const { reportForm } = this.state;
    const field = reportForm["extendWithFilter"];
    const lookup = this.state[type];

    if (!field || !lookup || field.some(elem => !lookup.find(lookupElem => elem === lookupElem.value))) {
      return [];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  filterDisplayMode = () => {
    const { displayMode, reportForm } = this.state;

    if (reportForm.reportType !== "GROSS_PROFIT") {
      return displayMode.filter(elem => elem.value === "LIST");
    }

    return displayMode;
  };

  filterExtendWith = () => {
    const { reportForm, extendWith } = this.state;
    const { reportType, sortBy } = reportForm;

    if (!extendWith[reportType]) {
      return [];
    }

    return extendWith[reportType].filter(elem => elem.value !== sortBy);
  };

  onSortByOpen = () => {
    this.setState({
      isSortSettingsOpen: !this.state.isSortSettingsOpen
    });
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  onExportClick = () => {
    const { reportForm, timeStamp, timeZones, endpointXlsxMap } = this.state;

    const reportFormData = reportForm.data();
    const reportType = reportFormData.reportType;
    const displayMode = reportFormData.displayMode;
    const isReportFormValid = reportForm.isFormValid(reportForm);
    this.setState({
      reportForm: reportForm,
      isExportSettingsOpen: false
    });

    let dateFormat = "DD.MM.YYYY HH:mm";
    if (reportType === "RUNNING_BALANCE" || reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
      dateFormat = "DD.MM.YYYY";
    }

    let timeZone = "00:00";
    if (reportForm.timeZone) {
      timeZone = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }

    const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().zone(timeZone).format(dateFormat);
    const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().zone(timeZone).format(dateFormat);

    if (isReportFormValid) {
      crudActions.post(
        endpointXlsxMap[reportType][displayMode],
        Object.assign(reportFormData, {
          fromDate: fromDateTimeStamp,
          toDate: toDateTimeStamp,
          pageNumber: 1,
          limit: INTEGER_MAX,
          currency: reportFormData.currency,
        })
      ).then(
        response => {
          if (response && response.ok) {
            response.blob().then(
              result => {
                saveAs(result, `${reportType}.xlsx`);
              }
            );
          } else {
            return Promise.reject({
              message: "Request can not be processed."
            });
          }
        }
      ).catch(
        err => {
          this.setState({
            showError: true,
            errorMessage: err.message,
            alertType: "error"
          });
        }
      );
    } else {
      this.setState({
        showError: true,
        errorMessage: "Some fields have errors. Please, fix them in order to continue.",
        alertType: "error"
      });
    }
  };

  isRightAligned = (index) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers[index].headerType === "NUMBER";
  };

  filterHeaders = (headers) => {
    const { access } = this.state;
    let filterHeader = headers.filter(elem => {
      if (!elem.permission) {
        return true;
      }

      const foundPermission = access.find(accessElem => accessElem.permission === elem.permission);
      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });

    const findButtonAccess = access.find(accessElem => accessElem.permission);
    
    const findButton = headers.find(elem => elem.permission === "CHECK_STATUS");

    if (findButtonAccess.permission === "CHECK_STATUS" && findButtonAccess.state === false) {
      filterHeader = filterHeader.concat(findButton);
    }

    return filterHeader;

  };

  filterData = (data) => {
    const { reportsGenerated, access } = this.state;
    const filteredData = data.filter((elem, index) => {
      const header = reportsGenerated.headers[index];

      const foundPermission = access.find(accessElem => accessElem.permission === header.permission);

      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });

    return filteredData;

  };

  checkAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }
    return foundPermission.state;
  };

  onOpenModalClick = (data, type) => {
    const { reportForm } = this.state;
    if (type === "json" && (data === "{}" || data === "[]" || !data)) {
      return;
    }

    try {
      const modalData = type === "json" && !Array.isArray(data) ? JSON.parse(data) : data;

      this.setState({
        showModal: true,
        modalType: type,
        modalTitle: reportForm.reportType === "SETTLEMENTS" ? "Settlement Notes" : "Notes",
        modalData
      });
    } catch {
      this.setState({
        errorMessage: type === "json" ? "Parsing logs failed." : "Getting notes failed.",
        showError: true,
        alertType: "error"
      });
    }
  };

  findMerchantIdIndex = (headers) => {
    return headers.filter(elem => "merchant_id" === elem.headerId).map(
      elem => headers.findIndex(header => header.headerId === elem.headerId)
    );
  }

  triggerButtonEvent = (headers, headerIndex, row, tableData, tableDataType) => {
    const { reportsGenerated, savedGeneratedReport } = this.state;
    const filterHeders = this.filterHeaders(headers);

    const buttonSettings = filterHeders.at(headerIndex);

    const headersIndexes = headers.filter(elem => buttonSettings.fields.includes(elem.headerId)).map(
      elem => headers.findIndex(header => header.headerId === elem.headerId)
    );
    const mercantIdIndex = this.findMerchantIdIndex(headers);

    const data = {};

    headersIndexes.forEach(
      elem => {
        const foundHeader = headers[elem];
        data[foundHeader.headerId] = row[elem];
      }
    );
    const merchantId = row[mercantIdIndex[0]];

    if (buttonSettings.endpoint.includes("/v1/report-buttons/performance/")) {
      const { blockLoader } = this.state;
      const type = buttonSettings.endpoint === "/v1/report-buttons/performance/email/block" ? "Email" : "IP Address";
      const valueIndex = headers.findIndex(header => header.headerName === type);
      const buttonIndex = headers.findIndex(header => header.headerType === "BUTTON");
      
      if (row[valueIndex]) {
        blockLoader.push(row[valueIndex]);
        this.setState({
          blockLoader
        });

        const blockData = {
          block: !row[buttonIndex],
          value: row[valueIndex]
        };

        crudActions.post(buttonSettings.endpoint, blockData).then(
          (response) => {
            const rowIndex = reportsGenerated.data.findIndex(item => item[valueIndex] === row[valueIndex]);
            row[buttonIndex] = !row[buttonIndex];
            reportsGenerated.data[rowIndex] = row;

            this.setState({
              showSuccess: true,
              successMessage: "Operation successful.",
              blockLoader: this.state.blockLoader.filter(i => i !== row[valueIndex]),
              reportsGenerated
            });
          }
        ).catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showError: true,
                errorMessage: err.message,
                alertType: "error",
                blockLoader: this.state.blockLoader.filter(i => i !== row[valueIndex])
              });
            }
          }
        );
      } 
    } else if (buttonSettings.endpoint === "/v1/report-buttons/deposits/check-status" || (savedGeneratedReport.reportType === "DEPOSITS_PAYOUTS" && !buttonSettings.endpoint.includes("v1/report-buttons/transactions"))) {
      this.setState({
        isButtonLoading: row[this.getReportColumnIndex('id')],
        loadingButtonType: buttonSettings.headerId
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Client: merchantId
      }).then(
        (response) => {
          this.setState({
            isButtonLoading: "",
            loadingButtonType: ""
          });
          const status = response.messageType;
          if (status === "SUCCESS") {
            this.setState({
              showSuccess: true,
              successMessage: response.message
            });
          } else if (status === "ERROR") {
            this.setState({
              showError: true,
              errorMessage: response.message,
              alertType: "error"
            });
          } else if (status === "NEUTRAL" || !status) {
            this.setState({
              showInfo: true,
              infoMessage: response.message
            });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              alertType: "error",
              isButtonLoading: "",
              loadingButtonType: ""
            });
          }
        }
      );
    } else if (buttonSettings.endpoint.includes("v1/report-buttons/transactions")) {
      this.setState({
        isModalLoading: true,
        showModal: true,
        modalType: tableDataType,
        modalTitle: "Logs",
        modalData: ""
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Client: merchantId
      }).then(
        (response) => {
          this.setState({
            isModalLoading: false,
            modalData: JSON.parse(JSON.stringify(response))
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              showModal: false,
              modalTitle: "",
              errorMessage: err.message,
              alertType: "error"
            });
          }
        }
      );
    } else {
      this.setState({
        isButtonLoading: row[this.getReportColumnIndex('id')],
        loadingButtonType: buttonSettings.headerId
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Client: merchantId
      }).then(
        (response) => {
          this.setState({
            isButtonLoading: "",
            loadingButtonType: "",
            showSuccess: true,
            successMessage: response && response.message ? response.message : "Operation successful."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              alertType: "error",
              isButtonLoading: "",
              loadingButtonType: ""
            });
          }
        }
      );
    }
  };

  sendBulkPostback = () => {
    const { reportsGenerated, reportForm, savedGeneratedReport} = this.state;
    const headers = reportsGenerated.headers;
    const data = reportsGenerated.data;
    const transactionIds = [];
    let index = null;
    if (data && headers) {
      if (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "RISK_AND_SECURITY" || reportForm.reportType  === "FRAUD_CHECK") {
        index = headers.map(e => e.headerName).indexOf("ID");
      }
      if (reportForm.reportType === "PAYOUT") {
        index = headers.map(e => e.headerName).indexOf("Payout ID");
      }
      data.forEach(d => {
        transactionIds.push(d[index]);
      });
    }
    const objectTransactionsIds = {};
    objectTransactionsIds.ids = transactionIds;
    if (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "RISK_AND_SECURITY") {
      (crudActions.post('v1/report-buttons/deposit/bulk', objectTransactionsIds,{
        Client: savedGeneratedReport.clientIds
      }
      )).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showSuccess: true,
            successMessage: "Operation successful."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              alertType: "error"
            });
          }
        }
      );

    }
    if (reportForm.reportType === "PAYOUT") {
      (crudActions.post('/v1/report-buttons/payout/bulk', objectTransactionsIds, {
        Client: savedGeneratedReport.clientIds
      })).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showSuccess: true,
            successMessage: "Operation successful."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message,
              alertType: "error"
            });
          }
        }
      );
    }
  };

  onGenerateReport = () => {
    const { reportForm, timeStamp, timeZones, endpointGenerateMap, currentPage, rowsAmount, periodId } = this.state;
    const isReportFormValid = reportForm.isFormValid(reportForm);
    this.setState({
      reportForm: reportForm,
      isReportErrorFields: true,
      clickButton: true
    });
    if (reportForm.transactionId) {
      this.setState({
        enableButton: true
      });
    }

    if (isReportFormValid) {
      this.setState({
        isLoading: true
      });

      const reportFormData = reportForm.data();

      const reportType = reportFormData.reportType;
      const displayMode = reportFormData.displayMode;

      if(reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" || reportType === "PAYOUT" ||
        reportType === "RISK_AND_SECURITY" || reportType === "FRAUD_CHECK" || reportType === "DEPOSITS_PAYOUTS"){
        reportFormData.transactionId = reportFormData.transactionId.trim();
      }
      if(reportType === "RUNNING_BALANCE"){
        delete reportFormData.currency;
      }
      let dateFormat = "DD.MM.YYYY HH:mm:ss";
      if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
        dateFormat = "DD.MM.YYYY";
      }
      delete reportFormData.pspIds;
      if (reportType !== "SERVER_LOGS") {
        delete reportFormData.currency;
      }

      const period = this.getPeriod(periodId);

      if (periodId !== 8) {
        //we do not override dates for custom date picker
        timeStamp.fromDateTimeStamp = period.from;
        timeStamp.toDateTimeStamp = period.to;
      }

      let timeZone = "00:00";
      if (reportForm.timeZone) {
        timeZone = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
      }
      
      const fromDate = Datetime.moment(timeStamp.fromDateTimeStamp).utc().zone(timeZone).format(dateFormat);
      const toDate = Datetime.moment(timeStamp.toDateTimeStamp).utc().zone(timeZone).format(dateFormat);
      crudActions.post(
        endpointGenerateMap[reportType][displayMode],
        Object.assign(reportFormData, {
          fromDate,
          toDate,
          pageNumber: currentPage,
          limit: rowsAmount
        })
      ).then(
        (reportsGenerated) => {
          if (reportsGenerated) {
            if (displayMode === "LIST_DETAILED") {
              reportsGenerated.isListDetailed = true;
              reportsGenerated.data = reportsGenerated.data.reduce(
                (finalArray, currentElem) => {
                  finalArray.push({
                    mainRow: true,
                    content: currentElem.mainRow.concat(Array(reportsGenerated.headers.length - currentElem.mainRow.length).fill(""))
                  });
                  finalArray = finalArray.concat(currentElem.subRows.map(elem => {
                    return {
                      mainRow: false,
                      content: Array(reportsGenerated.headers.length - elem.length).fill("").concat(elem)
                    };
                  }));

                  return finalArray;
                }, []
              );
            } else if (
              reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" ||
              reportType === "PAYOUT" || reportType === "RISK_AND_SECURITY" ||
              reportType === "FRAUD_CHECK" || reportType === "PERFORMANCE" ||
              reportType === "DEPOSITS_PAYOUTS"
            ) {
              reportsGenerated.isHistory = true;
            } else if (displayMode === "LIST") {
              reportsGenerated.isList = true;
            }
            if (reportsGenerated.warningMessage) {
              this.setState({
                showAlert: true,
                messageAlert: reportsGenerated.warningMessage,
              });
            }
            this.setState({
              reportsGenerated: reportsGenerated,
              savedGeneratedReport: reportFormData,
              isLoading: false,
              isReportErrorFields: false,
              cnt: reportsGenerated.cnt,
              lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),
              editableTransactionId: "",
              editableTransactionField: ""
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true,
              alertType: "error"
            });
          }
        }
      );
    } else {
      this.setState({
        showError: true,
        errorMessage: "Some fields have errors. Please, fix them in order to continue.",
        alertType: "error"
      });
    }
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon"
      || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
      || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else
    if(event.target.textContent){
      pageNum = event.target.textContent;
    }
   
    this.setState({currentPage: pageNum}, () => {
      this.onGenerateReport();
    }); 
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      modalData: "{}",
      modalType: "json",
      modalTitle: ""
    });
  };

  getReportColumnIndex = (headerId) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers.map(e => e.headerId).indexOf(headerId);
  }

  getStatusSelectOptions = (currStatus) => {
    if (currStatus === "PENDING") {
      return [{
        label: "PENDING",
        value:"PENDING"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }, {
        label: "FAIL",
        value:"FAIL"
      }];
    } else if (currStatus === "FAIL") {
      return [{
        label: "FAIL",
        value:"FAIL"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }];
    } else if (currStatus === "REJECTED") {
      return [{
        label: "REJECTED",
        value:"REJECTED"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }];
    } else if (currStatus === "CANCELLED") {
      return [{
        label: "CANCELLED",
        value:"CANCELLED"
      }, {
        label: "SUCCESS",
        value:"SUCCESS"
      }];
    } else if (currStatus === "FRAUD_CHECK") {
      return [{
        label: "SUCCESS",
        value:"SUCCESS"
      }, {
        label: "FAIL",
        value:"FAIL"
      }, {
        label: "REVERSED",
        value:"REVERSED"
      }];
    } else {
      return [{
        label: "SUCCESS",
        value:"SUCCESS"
      }, {
        label: "REVERSED",
        value:"REVERSED"
      }];
    }
  };

  onDoubleClick = (fieldName, currValue, transId, transactionStatus, transactionType) => {
    if (fieldName === "status" && currValue !== "PENDING" && currValue !== "SUCCESS" && currValue !== "FAIL" && currValue !== "CANCELLED" && currValue !== "REJECTED" && currValue !== "FRAUD_CHECK" ) return;
    if (fieldName === "amount" && transactionStatus !== "PENDING" && transactionStatus !== "FAIL" && transactionStatus !== "REJECTED" && transactionStatus !== "CANCELLED") return;
    if (transactionType === "PAYOUT") return;
    this.setState({
      editableTransactionId: transId,
      editableTransactionField: fieldName,
      editableTransactionValue: currValue
    });
  };

  handleEnterPress = (event) => {
    const { editableTransactionField } = this.state;
    if (event.key === "Enter" && parseFloat(this.state.editableTransactionValue) > 0) {
      this.setState({
        showPromptModal: true,
        changeValueMessage: `Do you want to change the ${editableTransactionField}?`
      });
    }
  };

  onChangeTxValue = (fieldName, transId, prevValue, newValue) => {
    const { editableTransactionField } = this.state;
    if (fieldName === "status") {
      if (newValue !== prevValue) {
        this.setState({
          showPromptModal: true,
          changeValueMessage: `Do you want to change the ${editableTransactionField}?`
        });
      }
    }
    this.setState({
      editableTransactionValue: newValue,
      editableTransactionField: fieldName
    });
  };

  handleConfirmPromptModal = () => {
    const {
      currentPage,
      reportForm,
      reportsGenerated,
      rowsAmount,
      editableTransactionValue,
      editableTransactionId,
      editableTransactionField,
      timeStamp
    } = this.state;

    const transIdIndex = reportsGenerated.headers.findIndex(header => header.headerName === "ID");

    const row = reportsGenerated.data.find(item => item[transIdIndex] === editableTransactionId);
    const reportFormData = reportForm.data();

    if (row) {
      this.setState({
        changeTXPropertyLoading: true
      });
      const data = {
        "id": editableTransactionId,
        ...(editableTransactionField === "status" && { "status": editableTransactionValue }),
        ...(editableTransactionField === "amount" && { "amount": editableTransactionValue }),
        "reportFilter": Object.assign(reportFormData, {
          fromDate: getFormattedDate(timeStamp.fromDateTimeStamp._d ? timeStamp.fromDateTimeStamp._d : timeStamp.fromDateTimeStamp),
          toDate: getFormattedDate(timeStamp.toDateTimeStamp._d ? timeStamp.toDateTimeStamp._d : timeStamp.toDateTimeStamp),
          pageNumber: currentPage,
          limit: rowsAmount
        })
      };
      crudActions.put(
        "v1/report-buttons/deposits/update",
        data
      ).then( (response) => {
        if (reportForm.reportType  === "FRAUD_CHECK") {
          //for Fraud report we just remove transaction from report because it will not be there anymore
          reportsGenerated.data.splice(transIdIndex);
          this.setState({
            showPromptModal: false,
            changeValueMessage: "",
            editableTransactionId: "",
            editableTransactionField: "",
            changeTXPropertyLoading: false,
            showSuccess: true,
            successMessage: "Transaction status has been updated!",
            reportsGenerated
          });
        } else {
          if (response.data && response.data.length) {
            const transaction = response.data[0];
            const transId = transaction[transIdIndex];
            const isExistingTransaction = reportsGenerated.data.find(item => item[transIdIndex] === transId);

            if (isExistingTransaction) {
              const rowIndex = reportsGenerated.data.findIndex(item => item[transIdIndex] === transId);
              reportsGenerated.data[rowIndex] = transaction;
            } else {
              reportsGenerated.data.unshift(transaction);
            }

            this.setState({
              showPromptModal: false,
              changeValueMessage: "",
              editableTransactionId: "",
              editableTransactionField: "",
              changeTXPropertyLoading: false,
              showSuccess: true,
              successMessage: `Transaction ${editableTransactionField} has been updated!`,
              reportsGenerated
            });
          }
        }
      }).catch(
        err => {
          if (err && err.message) {
            const condition = err.message.includes("Deposit Postback was not consumed by the merchant");
            if (condition) {
              const rowIndex = reportsGenerated.data.findIndex(item => item[transIdIndex] === editableTransactionId);
              reportsGenerated.data[rowIndex][this.getReportColumnIndex("status")] = editableTransactionValue;
              this.setState({
                editableTransactionId: "",
                editableTransactionField: "",
                reportsGenerated
              });
            }
            this.setState({
              showError: true,
              errorMessage: err.message,
              alertType: condition ? "warning" : "error",
              showPromptModal: false,
              changeTXPropertyLoading: false,
              changeValueMessage: "",
            });
          } else {
            this.setState({
              showError: true,
              showPromptModal: false,
              alertType: "error",
              changeTXPropertyLoading: false,
              changeValueMessage: "",
              editableTransactionId: "",
              editableTransactionField: "",
              errorMessage: `Couldn't update transaction ${editableTransactionField}.`
            });
          }
        }
      );
    }
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      changeValueMessage: ""
    });
  };

  onAlertConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      alertType: "error",
      errorMessage: "",
      successMessage: "",
      showAlert: false,
      messageAlert: "",
      showInfo: false,
      infoMessage: ""
    });
  };

  render() {
    const controls = { ...this.state.controls };

    const {
      reportForm,
      reportsGenerated,
      isLoading,
      sortByDate,
      modalData,
      modalType,
      modalTitle,
      editableTransactionId,
      editableTransactionField,
      editableTransactionValue,
      dateFieldDisabled,
      isSortSettingsOpen,
      isExportSettingsOpen,
      reportType,
      showModal,
      isModalLoading,
      showPromptModal,
      merchants,
      timeStamp,
      baseCurrencies,
      timeZones,
      timeStampList,
      enableButton,
      clickButton,
      cnt,
      rowsAmount,
      currentPage,
      blockLoader,
      pageType,
      isButtonLoading,
      loadingButtonType,
      isLoadingLookups,
    } = this.state;

    if (!isSortSettingsOpen) {
      this.sortNode = null;
    }

    const totalPageCount = Math.ceil(cnt / rowsAmount);

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    const rows = this.getFields();

    const isMultiMerchant = reportForm.reportType === "TRANSACTIONS" ||
      reportForm.reportType === "GROSS_PROFIT" ||
      reportForm.reportType === "PAYOUT" ||
      reportForm.reportType === "SETTLEMENTS" ||
      reportForm.reportType === "FRAUD_CHECK" ||
      reportForm.reportType === "PERFORMANCE" ||
      reportForm.reportType === "DEPOSITS_PAYOUTS";

    const isDateTime = !(reportForm.reportType === "RUNNING_BALANCE" &&
      !(controls.timePeriod.value === "today" || controls.timePeriod.value === "custom")) &&
      reportForm.reportType !== "SETTLEMENTS" &&
      reportForm.reportType !== "GROSS_PROFIT";

    if (!reportForm.originalData) {
      return <Row flexGrow={ 1 } className='module generateReports' vertical='start'>
        <span />
      </Row>;
    }
    return (
      <Row flexGrow={ 1 } className="t365 module generateReports" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title={ pageType === 'transactions' ? "Transactions" : "Generate Report" }
            lastUpdate={ this.state.lastUpdate }
            img={ ReportIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    GENERATE REPORT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ { overflow: 'unset' } }>
                    {isLoadingLookups ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Report Type </label>
                              <Select
                                id="reportType"
                                name="reportType"
                                value={ reportForm.reportType || '' }
                                required={ true }
                                clearable={ false }
                                disabled={ false }
                                onChange={ (e) => this.onValueChange(e, 'reportType') }
                                options={ reportType }
                              />
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Merchants </label>
                              {isMultiMerchant ? (
                                <Multiselect
                                  id="clientIds"
                                  name="clientIds"
                                  isError={ reportForm.errors.has('clientIds') }
                                  selectedItems={ this.mapSelectedItems("clientIds", "clientIds") }
                                  disabled={ false }
                                  items={ merchants }
                                  type="clientIds"
                                  onChange={ this.onValueChange }
                                />
                              ) : (
                                <Select
                                  id="clientIds"
                                  className={ reportForm.errors.has('clientIds') ? 'error-field' : "" }
                                  value={ reportForm.clientIds.toString() }
                                  disabled={ false }
                                  required={ true }
                                  clearable={ false }
                                  options={ merchants }
                                  onChange={ (e) => this.onValueChange(e, 'clientIds') }
                                />
                              )}
                            </Column>
                            {(reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PAYOUT" ||
                              reportForm.reportType === "PERFORMANCE" || reportForm.reportType === "DEPOSITS_PAYOUTS") ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Stamp </label>
                                  <Select
                                    id="timeStampList"
                                    name="timeStampList"
                                    className={ reportForm.errors.has('timeStampList') ? 'error-field' : "" }
                                    value={ reportForm.timeStampList }
                                    disabled={ false }
                                    required={ true }
                                    clearable={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeStampList') }
                                    options={ timeStampList }
                                  />
                                </Column>
                              ) : reportForm.reportType === "RUNNING_BALANCE" ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Zone </label>
                                  <Select
                                    id="timeZone"
                                    name="timeZone"
                                    className={ reportForm.errors.has('timeZone') ? 'error-field' : "" }
                                    value={ reportForm.timeZone }
                                    disabled={ false }
                                    required={ true }
                                    clearable={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeZone') }
                                    options={ timeZones }
                                  />
                                </Column>
                              ) : (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <span/>
                                </Column>
                              )}
                            {(reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PAYOUT" ||
                              reportForm.reportType === "PERFORMANCE" || reportForm.reportType === "DEPOSITS_PAYOUTS") ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Zone </label>
                                  <Select
                                    id="timeZone"
                                    name="timeZone"
                                    className={ reportForm.errors.has('timeZone') ? 'error-field' : "" }
                                    value={ reportForm.timeZone }
                                    disabled={ false }
                                    required={ true }
                                    clearable={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeZone') }
                                    options={ timeZones }
                                  />
                                </Column>
                              ) : (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <span/>
                                </Column>
                              )}
                          </Row>
                        </Row>

                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Time Period </label>
                              <Select
                                id="timePeriod"
                                name="timePeriod"
                                value={ controls.timePeriod.value || '' }
                                disabled={ false }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                                options={ this.state.dropdowns.periods }
                              />
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label>From Date</label>
                              <img src={ Calendar } className="calendar-svg" alt="" />
                              <DatePicker
                                selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}` }
                                timeFormat="HH:mm"
                                showTimeSelect={ isDateTime }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                                disabled={ dateFieldDisabled }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                              />
                            </Column>
                          </Row>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label>To Date</label>
                              <img src={ Calendar } className="calendar-svg" alt="" />
                              <DatePicker
                                selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                                className="form-control"
                                timeInputLabel="Time:"
                                dateFormat={ `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}` }
                                timeFormat="HH:mm"
                                showTimeSelect={ isDateTime }
                                timeIntervals={ 15 }
                                filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                                disabled={ dateFieldDisabled }
                                onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                              />
                            </Column>
                            {reportForm.reportType === "GROSS_PROFIT" ? (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <label> Currency </label>
                                <Select
                                  id="grossCurrency"
                                  name="grossCurrency"
                                  className={ reportForm.errors.has('grossCurrency') ? 'error-field' : "" }
                                  value={ reportForm.grossCurrency }
                                  disabled={ false }
                                  required={ true }
                                  clearable={ false }
                                  onChange={ (value) => this.onValueChange(value, 'grossCurrency') }
                                  options={ baseCurrencies }
                                />
                              </Column>
                            ) : (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                <span/>
                              </Column>
                            )}
                          </Row>
                        </Row>
                        {rows.map((row, index) => {
                          return <Row key={ index } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            {[2, 4].map(count => {
                              return <Row key={ index + " " + count } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                                {row.slice(count - 2, count).map((field, fieldIndex) => {
                                  return (
                                    <Column
                                      key={ field.id + " " + index + " " + fieldIndex }
                                      flexGrow={ field.flexGrow !== undefined ? parseFloat(field.flexGrow) : 1 }
                                      vertical='end'
                                      alignSelf='start'
                                      className={ `input-column ${!field.type && "empty-column"}` }
                                    >
                                      {field.type !== "checkbox" && !field.notShowOnCondition && (
                                        <label style={ { minHeight: '20px' } }> {field.label} </label>
                                      )}

                                      {field.type === "select" && !field.notShowOnCondition ?
                                        <Select
                                          className={ reportForm.errors.has(field.id) ? 'error-field' : "" }
                                          value={ field.id === "pspIds" || field.id === "currency" ? reportForm[field.id].toString() : reportForm[field.id] }
                                          disabled={ field.disabled }
                                          required={ true }
                                          clearable={ false }
                                          options={ field.options }
                                          onChange={ (e) => this.onValueChange(e, field.id) }
                                        />
                                        : field.type === "multiselect" && !field.notShowOnCondition ?
                                          <Multiselect
                                            isError={ reportForm.errors.has(field.id) }
                                            selectedItems={ field.selectedItems }
                                            disabled={ field.disabled }
                                            items={ field.items }
                                            type={ field.id }
                                            withId={ field.withId }
                                            onChange={ this.onValueChange }
                                          />
                                          : field.type === "input" && !field.notShowOnCondition ?
                                            <input
                                              className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                              value={ reportForm[field.id] }
                                              disabled={ field.disabled }
                                              onChange={ (e) => this.onValueChange(e, field.id) }
                                            />
                                            : field.type === "textarea" && !field.notShowOnCondition ?
                                              <TextareaAutosize
                                                cacheMeasurements
                                                value={ reportForm[field.id] }
                                                onChange={ (e) => this.onValueChange(e, field.id) }
                                                className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                                disabled={ field.disabled }
                                              />
                                              : field.type === "bulkButton" && enableButton === true ?
                                                <Button
                                                  type="submit"
                                                  className="btn defaultBtn"
                                                  style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                                  onClick={ this.sendBulkPostback }
                                                >
                                                  SEND BULK POSTBACKS
                                                </Button>
                                                : field.type === "checkbox" && !field.notShowOnCondition ?
                                                  <Row className="styled-input--square" wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                                    <div className="styled-input-single">
                                                      <input type="checkbox" id={ field.id } checked={ reportForm[field.id] }
                                                        onChange={ () => this.onValueChange(reportForm[field.id], [field.id]) } />
                                                      <label style={ { fontWeight: "normal" } } htmlFor={ field.id }> {field.label} </label>
                                                    </div>
                                                  </Row>
                                                  : field.type === "button" && !field.notShowOnCondition ?
                                                    <Button
                                                      type="submit"
                                                      className="btn defaultBtn"
                                                      style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                                      onClick={ this.onGenerateReport }
                                                    >
                                                      GENERATE
                                                    </Button>
                                                    : <span />
                                      }
                                    </Column>);
                                })}
                              </Row>;
                            })}
                          </Row>;
                        })}

                      </>
                    )}
                  </div>
                </Panel.Body>
              </Panel>
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> REPORT GENERATED </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : reportsGenerated.headers ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      REPORT GENERATED
                    </Panel.Title>
                    <div className="settings-panel">
                      <span className="setting" onClick={ () => this.onSortByOpen() }>
                        <svg className="setting-icon" viewBox="0 0 1792 1792">
                          <path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z" />
                        </svg>
                        <span className="text"> {sortByDate.find(elem => elem.value === reportForm.sortByDate).label} </span>
                      </span>
                      <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                        <svg className="setting-icon" viewBox="0 0 32 32">
                          <g>
                            <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd" />
                            <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd" />
                          </g>
                        </svg>
                        <span className="text"> Export </span>
                      </span>
                    </div>
                    {isSortSettingsOpen && (
                      <div ref={ node => this.sortNode = node } className="sort-settings">
                        {sortByDate.map(elem => {
                          return <p key={ elem.value } className="sort-setting-item" onClick={ () => this.onValueChange(elem, 'sortByDate') }> {elem.label} </p>;
                        })}
                      </div>
                    )}
                    {isExportSettingsOpen && (
                      <div
                        ref={ node => this.exportNode = node }
                        className="export-settings"
                      >
                        <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                      </div>
                    )}
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content report-content">
                      <table className="table table-striped">
                        <thead>
                          <tr style={ { backgroundColor: '#25282a', color: "#FFFFFF" } }>
                            {this.filterHeaders(reportsGenerated.headers).map((column, i) => {
                              return column.headerId !== "ip_country" && (
                                <th
                                  key={ i }
                                  className={ column.headerId === "success_rate" || this.isRightAligned(i) ? "text-right " : (column.headerType === "BUTTON" || column.headerType === "JSON" || column.headerType === "TEXT") ? "text-center" : "" }
                                >
                                  {column.headerName}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {reportsGenerated.data.map((element, i) => {
                            return [reportsGenerated.isListDetailed && (
                              <tr key={ i } className={ element.mainRow ? "main-row" : "" }>
                                {element.content.map((tableData, j) => {
                                  return (
                                    <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }>
                                      {tableData}
                                    </td>);
                                })
                                }
                              </tr>
                            ), reportsGenerated.isHistory && (
                              <tr key={ i }>
                                {this.filterData(element).map((tableData, j) => {
                                  return (reportsGenerated.headers[j].headerType === "TEXT" || reportsGenerated.headers[j].headerId === "ccAdditionalData") ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ `text-center ${!tableData || tableData === "{}" || tableData === "[]" ? "no-history" : "history"}` }
                                      onClick={ () => this.onOpenModalClick(tableData, reportsGenerated.headers[j].headerType === "JSON" ? "json" : "text") }
                                    >
                                      View
                                    </td>
                                  ) : reportsGenerated.headers[j].headerType === "JSON" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="text-center history"
                                      onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element, tableData, reportsGenerated.headers[j].headerType === "JSON" ? "json" : "text") }
                                    >
                                      View
                                    </td>
                                  ) : reportsGenerated.headers[j].headerType === "BUTTON" ? (
                                    <td key={ i.toString() + j.toString() } className={ blockLoader.length && blockLoader.indexOf(reportsGenerated.data[i][this.getReportColumnIndex('label')]) > -1 ? "loading" : "" }>
                                      {blockLoader.length && blockLoader.indexOf(reportsGenerated.data[i][this.getReportColumnIndex('label')]) > -1 ?
                                        <img src={ loadingIcon } alt="loading" />
                                        : (
                                          <Button 
                                            className={ `btn ${reportsGenerated.headers[j].headerName === 'Reject' ? 'red-button' : 'action-button'}`
                                            // eslint-disable-next-line eqeqeq
                                            && `btn ${this.filterHeaders(reportsGenerated.headers)[j].permission == "CHECK_STATUS" && element[element.length - 1] === "false" ? 'grey-button' : 'action-button'} ${reportForm.reportType === "PERFORMANCE" ? (tableData ? "green" : "orange") : "green"}` }
                                            disabled={ (reportForm.reportType === "PERFORMANCE" && (!this.checkAccess("BLOCK_REQUESTS") || (reportsGenerated.headers[j].headerId === "blockIp"))) || isButtonLoading }
                                            onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element) }
                                          >
                                            {reportForm.reportType === "PERFORMANCE" ?
                                              (tableData ? "Unblock" : "Block")
                                              : isButtonLoading === reportsGenerated.data[i][this.getReportColumnIndex('id')] && loadingButtonType === reportsGenerated.headers[j].headerId ? (
                                                <img src={ loadingIcon } alt="loading" width="20px" />
                                              ) : this.filterHeaders(reportsGenerated.headers)[j].headerName}
                                          </Button>
                                        )}
                                        
                                    </td>
                                  ) : (reportsGenerated.headers[j].headerName === "Status" && this.checkAccess("UPDATE_STATUS") &&
                                        (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "FRAUD_CHECK" || reportForm.reportType === "DEPOSITS_PAYOUTS")) ? (
                                      <td key={ i.toString() + j.toString() } className="select">
                                        {editableTransactionField === "status" && editableTransactionId === reportsGenerated.data[i][this.getReportColumnIndex('id')] ? (
                                          <Select
                                            value={ tableData }
                                            clearable={ false }
                                            options={ this.getStatusSelectOptions(tableData) }
                                            onChange={ (e) => this.onChangeTxValue("status", reportsGenerated.data[i][this.getReportColumnIndex('id')], tableData, e.value) }
                                          />
                                        ) : (
                                          <span
                                            style={ { cursor: "pointer", padding: "0 10px", lineHeight: "43px" } }
                                            onDoubleClick={ () => this.onDoubleClick("status", tableData, reportsGenerated.data[i][this.getReportColumnIndex('id')], "", reportsGenerated.data[i][this.getReportColumnIndex('type')]) }
                                          >
                                            {tableData}
                                          </span>
                                        )}
                                      </td>
                                    ) : (reportsGenerated.headers[j].headerName === "Amount" &&
                                        this.checkAccess("UPDATE_STATUS") &&
                                        (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "DEPOSITS_PAYOUTS")) ? (
                                        <td key={ i.toString() + j.toString() } className="text-right input">
                                          {editableTransactionField === "amount" && editableTransactionId === reportsGenerated.data[i][this.getReportColumnIndex('id')] ? (
                                            <input
                                              type="number"
                                              value={ editableTransactionValue }
                                              onKeyDown={ this.handleEnterPress }
                                              onChange={ (e) => this.onChangeTxValue("amount", reportsGenerated.data[i][this.getReportColumnIndex('id')], tableData, e.target.value) }
                                            />
                                          ) : (
                                            <span
                                              style={ { cursor: "pointer", padding: "0 10px", lineHeight: "43px" } }
                                              onDoubleClick={ () => this.onDoubleClick("amount", tableData, reportsGenerated.data[i][this.getReportColumnIndex('id')], reportsGenerated.data[i][this.getReportColumnIndex('status')], reportsGenerated.data[i][this.getReportColumnIndex('type')]) }
                                            >
                                              {tableData}
                                            </span>
                                          )}
                                        </td>
                                      ) : (
                                        reportsGenerated.headers[j].headerId !== "ip_country" && (
                                          <td
                                            key={ i.toString() + j.toString() }
                                            className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }
                                            style={ { minWidth: reportsGenerated.headers[j].headerId === "ip" ? "170px" : "100px" } }
                                          >
                                            { reportsGenerated.headers[j].headerId === "ip" && reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] && (
                                              <img
                                                alt={ reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] }
                                                style={ { width: "32px", marginRight: "3px" } }
                                                src={ `http://purecatamphetamine.github.io/country-flag-icons/3x2/${reportsGenerated.data[i][this.getReportColumnIndex('ip_country')]}.svg` }/>
                                            )}
                                            { tableData }
                                          </td>
                                        )
                                      );
                                })
                                }
                              </tr>), reportsGenerated.isList && (
                              <tr key={ i } style={ { backgroundColor: '#ffffff' } }>
                                {element.map((tableData, j) => {
                                  return (reportsGenerated.headers[j].headerType === "TEXT" && tableData) ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="history"
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : (
                                    <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }>
                                      {tableData}
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            )];
                          })
                          }

                          {reportsGenerated.total && (
                            <tr className="main-row">
                              {reportsGenerated.total.map((elem, i) => {
                                return <td key={ i } className={ i === 0 ? "sticky-col" : "" }>
                                  {elem}
                                </td>;
                              })
                              }
                            </tr>
                          )}
                        </tbody>
                      </table> 
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <span/>
              )} 
              {clickButton && totalPageCount > 0 && (
                <Panel style={ {marginTop : "-13px", borderColor : "white", maxHeight: "105px", minHeight: "100px"} }>
                  <Panel.Body>
                    <div className="pagination">
                      <Pagination 
                        count={ totalPageCount } 
                        variant="outlined" 
                        shape="rounded" 
                        page={ Number(currentPage) }
                        onClick= { this.handleChangePage } 
                      />
                    </div>
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ this.state.showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ this.state.successMessage || "Report successfully generated." }
          onConfirm={ this.onAlertConfirm }
        />
        <SweetAlert
          show={ this.state.showError }
          title={ this.state.alertType === "error" ? "Error" : "Warning" }
          type={ this.state.alertType === "error" ? "error" : "warning" }
          confirmButtonColor={ this.state.alertType === "error" ? "#DD6B55" : "#F8BB86" }
          text={ this.state.errorMessage }
          onConfirm={ this.onAlertConfirm }
        />
        <Modal
          title={ modalTitle }
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType={ modalType }
          data={ modalData }
          isLoading={ isModalLoading }
        />
        <PromptModal
          title="Change status"
          message={ this.state.changeValueMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ this.state.changeTXPropertyLoading }
          showModal={ showPromptModal }
        />
        <SweetAlert
          show={ this.state.showAlert }
          title="Alert"
          confirmButtonColor="#25282a"
          text={ this.state.messageAlert }
          onConfirm={ this.onAlertConfirm }
        />
        <SweetAlert
          show={ this.state.showInfo }
          confirmButtonColor="#25282a"
          text={ this.state.infoMessage }
          onConfirm={ this.onAlertConfirm }
        />
      </Row>
    );
  }
}

export default GenerateReport;